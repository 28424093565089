import { authApi } from './AxiosInstance';

export function mediumSiteUrl(mediumToken) {
  return authApi.post(`/users/site/auth`, mediumToken);
}

export function getUserSites(siteType) {
  return authApi.get(`/users/get/user/sites?siteType=${siteType}`);
}

export function createPost(content) {
  return authApi.post(`/users/create/posts`, content);
}

export function deleteSite(siteId) {
  return authApi.delete(`/users/user/site/${siteId}`);
}

export function getWordPressCategories(siteId) {
  return authApi.get(`/users/get/categories?siteId=${siteId}`);
}
