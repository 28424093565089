import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// import { useLocation } from 'react-router-dom';
function TableWrapper({ children }) {
  // const location = useLocation();
  // const { pathname } = location;
  return (
    <>
      <Box className="globalShadow mainTables" sx={{ p: 6 }}>
        {children}
      </Box>
    </>
  );
}
TableWrapper.propTypes = {
  children: PropTypes.func.isRequired
};
export default TableWrapper;
