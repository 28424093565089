import React from 'react';
import { useRoutes } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import ProjectsRoute from './ProjectsRoute';
import CommonRoutes from './CommonRoutes';
import Pagenotfound from 'pages/Error/Pagenotfound';
import ThankyouRoutes from './ThankyouRoutes';
import SeoRoute from './SeoRoute';
import ShareUrlPage from 'components/ShareUrlPage/ShareUrlPage';
import BrandAmbassadorRoute from './BrandAmbassadorRoute';
// ==============================|| ROUTING RENDER ||============================== //

const ThemeRoutes = () => {
  const routing = useRoutes([
    AuthRoutes,
    MainRoutes,
    ProjectsRoute,
    CommonRoutes,
    ThankyouRoutes,
    BrandAmbassadorRoute,
    SeoRoute,
    {
      path: '*',
      element: <Pagenotfound />
    },
    {
      path: 'ambassador/*',
      element: <ShareUrlPage />
    }
  ]);
  return routing;
};

export default ThemeRoutes;
