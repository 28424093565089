/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Box, DialogContent, Dialog, Grid, Typography, Button } from '@mui/material';
import './BlackFridayModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCoupon } from 'store/actions/Coupons/CouponActions';
import EastIcon from '@mui/icons-material/East';
import { IconButton } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { blackFirdayModal } from 'store/actions/Authentication/AuthActions';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { mySubsplan } from 'store/actions/Subscription/SubscriptionActions';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
function BlackFridayModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  let user = JSON.parse(localStorage.getItem('token'));
  const currentDate = moment();

  const { getCouponsList } = useSelector((state) => state.CouponsReducer);
  const { blackFridayModal } = useSelector((state) => state.auth);
  return (
    <Box>
      {getCouponsList.length > 0 && (
        <Dialog
          sx={{ borderRadius: '20px !important' }}
          maxWidth="md"
          fullWidth={true}
          aria-labelledby="customized-dialog-title"
          open={blackFridayModal}
          className="blackFriday"
        >
          <DialogContent
            sx={{
              mt: -1,
              backgroundSize: 'cover',
              height: '500px',
              display: 'flex',
              alignItems: 'center',
              backgroundImage: `url(${getCouponsList.length > 0 && getCouponsList[0]?.bg_image}) !important`
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                const currentClosedTime = moment();
                localStorage.setItem('modalClosedTime', currentClosedTime.toISOString());
                dispatch(blackFirdayModal(false));
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CgClose />
            </IconButton>

            <Grid
              container
              sx={{
                justifyContent: 'space-evenly',
                alignItems: 'center',
                width: '80%',
                margin: '0 auto'
              }}
            >
              <Grid item lg={6} sm={6}>
                <Typography variant="h1" color="#fff">
                  {getCouponsList[0]?.title}
                </Typography>
                <Box mt={4} mb={4} className="paraText">
                  {getCouponsList[0]?.description}
                </Box>
                <Button
                  variant="primary"
                  className="getBtn menuButtons"
                  onClick={() => {
                    const currentClosedTime = moment();
                    localStorage.setItem('modalClosedTime', currentClosedTime.toISOString());
                    localStorage.removeItem('register_type');
                    let body = {
                      duration: 'annually',
                      redirectURL: location.pathname,
                      planType: 'pro'
                    };
                    dispatch(mySubsplan(body, navigate));
                  }}
                >
                  {getCouponsList[0]?.button_name}
                  <EastIcon sx={{ color: '#fff', ml: 1 }} />
                </Button>
              </Grid>
              <Grid item lg={6} md={6} align="center">
                <img
                  src={getCouponsList[0]?.image}
                  style={{
                    width: '70%'
                  }}
                  className="logo"
                  alt="cross"
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
export default BlackFridayModal;
