import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
const isAuthenticated = () => {
  const user = localStorage.getItem('token');
  return !!user;
};

const AuthGuard = ({ children, auth }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userIsAuthenticated = isAuthenticated();
    setLoading(false);

    // get plan param from url
    const urlParams = new URLSearchParams(window.location.search);
    const register_type = urlParams.get('plan');

    //
    // store to localstorage
    if (register_type) {
      localStorage.setItem('register_type', register_type);
    }

    if (auth === true && !userIsAuthenticated) {
      if (register_type) {
        navigate(`/auth/register`);
      } else {
        navigate('/auth/signin');
      }
    } else if (auth === false && userIsAuthenticated) {
      navigate('/');
    }
  }, [auth, navigate]);

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
  auth: PropTypes.bool.isRequired
};

export default AuthGuard;
