import { authApi } from './AxiosInstance';

export function becomeAmbassador(formData) {
  return authApi.post(`/users/become/ambassdor`, formData);
}

export function alreadyExistUser(username) {
  return authApi.get(`/users/userName/exists/${username}`);
}

export function ambassadorUrlCheck(username) {
  return authApi.get(`/users/checkURL/${username}`);
}

export function ambassadorUserList(limit, offset, search) {
  return authApi.get(`ambassador?limit=${limit}&offset=${offset}&search=${search}`);
}

export function ambassadorUserActivity(userId) {
  return authApi.get(`/ambassador/user/activity/${userId}`);
}

export function ambassadorSocialAssetsList() {
  return authApi.get(`/ambassador/social/assets`);
}

export function generateProfileLink() {
  return authApi.post(`/users/ambassador/profileLink`);
}

export function updateAmbassadorUser(userData) {
  return authApi.patch(`/ambassador/update`, userData);
}

export function ambassadorUsersGraph(monthYearly) {
  return authApi.get(`/ambassador/dashboard?filter=${monthYearly}`);
}
