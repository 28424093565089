import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthGuard from 'services/AuthGuard';

// render - login
const SocialLogin = Loadable(lazy(() => import('pages/authentication/SocialLogin/SocialLogin')));
const Login = Loadable(lazy(() => import('pages/authentication/Login/Login')));
const PreLogin = Loadable(lazy(() => import('pages/authentication/PreLogin/PreLogin')));
const Register = Loadable(lazy(() => import('pages/authentication/Register/Register')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword/ForgotPassword')));

const AuthRoutes = {
  path: 'auth',
  element: (
    <AuthGuard auth={false}>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'signin',
      element: <SocialLogin />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'prelogin',
      element: <PreLogin />
    },
    {
      path: 'register',
      element: <Register />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    }
  ]
};

export default AuthRoutes;
