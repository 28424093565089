import { addComments, getComments, hitReplyComment } from 'services/CommentsService';
import { genieLoaderAction, snackbarAction } from '../Authentication/AuthActions';
import { handleErrors } from '../Projects/ProjectsActions';

export const COMMENT_BOX = 'COMMENT_BOX';
export const COMMENT_BOX_DATA = 'COMMENT_BOX_DATA';
export const COMMENT_REPLY = 'COMMENT_REPLY';

export function addUserComment(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    addComments(id, content)
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(openCommentBox(false));
        dispatch(getUserComment(id, navigate));
        let message = {
          code: response?.status,
          message: 'Comment added successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function replyComment(id, content, navigate, _id) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    hitReplyComment(id, content)
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(getCommentBoxData(response?.data?.comments));
        dispatch(postReplyComment(false));
        let message = {
          code: response?.status,
          message: 'Reply added successfully'
        };
        dispatch(getUserComment(_id, navigate));
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getUserComment(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getComments(id)
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(getCommentBoxData(response?.data?.comments));
        // dispatch(openToolsModal(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function openCommentBox(data) {
  return {
    type: COMMENT_BOX,
    payload: data
  };
}

export function getCommentBoxData(data) {
  return {
    type: COMMENT_BOX_DATA,
    payload: data
  };
}

export function postReplyComment(data) {
  return {
    type: COMMENT_REPLY,
    payload: data
  };
}
