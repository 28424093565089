/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableContainer, TableHead, Menu, MenuItem, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import iconCopy from '../../assets/images/common/iconCopy.png';
import DateFormatter from '../../utils/DateFormatter';
import { sharedProjectList, sharedProjectOwnersList, setOwnersId } from 'store/actions/Projects/ProjectsActions';
import PaginationComp from 'components/Pagination/Pagination';
import PropTypes from 'prop-types';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import './ShareWithMe.css';
import emptyProjects from 'assets/designs/emptyProjects.svg';
import TableWrapper from 'components/TableWrapper/TableWrapper';
import { useLocation } from 'react-router-dom';
import { Radio } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SharedWithMe = ({ counts, recentButtons }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sharedProjects, sharedCount, sharedOwnersList, ownersId } = useSelector((state) => state.projects);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [owners, setOwners] = React.useState('All');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setListByOwners('All');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newSearch = '';
    dispatch(sharedProjectList(counts ? counts : limit, offset, newSearch, ownersId, navigate));
  }, [dispatch, offset, navigate, currentPage, ownersId, counts]);

  useEffect(() => {
    dispatch(sharedProjectOwnersList());
  }, [dispatch]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
  };

  const setListByOwners = (event) => {
    setOwners(event === 'All' ? 'All' : event.firstName + ' ' + event.lastName);
    dispatch(setOwnersId(event === 'All' ? '' : event.id));

    handleClose();
  };

  return (
    <>
      <TableWrapper>
        {sharedProjects && sharedProjects?.length === 0 ? (
          <Box align="center">
            <img src={emptyProjects} alt="empty-projects" />
            <Typography className="primaryHeading">No Projects</Typography>
            <Typography className="infoTextGlobal" sx={{ my: 2 }}>
              You don't have any projects shared with you right now.
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Box className="globalAlignment">
              <Typography className="primaryHeading">Shared With me</Typography>
              {pathname === '/shared-projects' && (
                <Typography className="tableCount" sx={{ mb: 2, fontWeight: 700 }}>
                  Total Projects ({sharedCount})
                </Typography>
              )}
            </Box>
            <Table>
              <TableHead>
                <TableRow sx={{ mb: 3, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                  <TableCell>
                    <Typography variant="subtitle11"> Project Name</Typography>
                  </TableCell>
                  <TableCell align="left" className="projectOwnerText">
                    <Typography
                      id="demo-positioned-button"
                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="subtitle11"
                      onClick={handleClick}
                      sx={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                    >
                      Owner ({owners}) <KeyboardArrowDownIcon />
                    </Typography>

                    <Menu
                      sx={{ mt: 1.5 }}
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className="info"
                    >
                      <MenuItem
                        mt={2}
                        onClick={() => {
                          setListByOwners('All');
                        }}
                      >
                        <Radio
                          sx={{
                            color: '#6631A5',
                            p: 0,
                            mr: 1,
                            '&.Mui-checked': {
                              color: '#6631A5'
                            }
                          }}
                          checked={owners === 'All'}
                          onChange={() => setListByOwners('All')}
                        />
                        All
                      </MenuItem>
                      {sharedOwnersList?.projectOwnerList?.length > 0 &&
                        sharedOwnersList?.projectOwnerList.map((data, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setListByOwners(data.userId);
                            }}
                          >
                            <Radio
                              sx={{
                                color: '#6631A5',
                                p: 0,
                                mr: 1,
                                '&.Mui-checked': {
                                  color: '#6631A5'
                                }
                              }}
                              checked={ownersId === data.userId.id}
                              onChange={() => setListByOwners(data.userId)}
                            />
                            {data.userId.firstName} {data.userId.lastName}
                          </MenuItem>
                        ))}
                    </Menu>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle11"> Last Updated</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ borderBottom: 'unset' }}>
                {sharedProjects &&
                  sharedProjects?.length > 0 &&
                  sharedProjects?.map((projectList) => (
                    <TableRow key={projectList._id} sx={{ borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                      <TableCell className="bottomborder" style={{ padding: '25px', width: '50%' }}>
                        {/* <Link className="projectListItems" to={`/project/${projectList._id}`}> */}
                        <Box className="imgBox" onClick={() => navigate(`/project/${projectList._id}`)}>
                          <img src={iconCopy} alt="logo" className="imgLogo" />
                          <EllipsisTooltip
                            variant="subtitle7"
                            text={projectList.name}
                            maxWidth={{ xs: '350px', md: '350px', sm: '350px', lg: '350px' }}
                            ml={4}
                          />
                        </Box>
                        {/* </Link> */}
                      </TableCell>
                      <TableCell className="bottomborder projectText" align="left">
                        <Typography variant="h12">
                          {projectList?.userId?.firstName} {projectList?.userId?.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell className="bottomborder projectText" align="right">
                        <DateFormatter variant="subtitle9" date={projectList.updatedAt} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              {recentButtons == 'Shared' && sharedCount > 0 && (
                <Button
                  className="globalButtonSecond"
                  onClick={() => navigate('/shared-projects')}
                  sx={{ borderRadius: '24px !important', display: 'flex', mt: 6, minWidth: { xs: '200px !important' } }}
                  type="button"
                >
                  View All Projects
                </Button>
              )}
            </Table>
            {pathname === '/shared-projects' && sharedCount > 10 && (
              <PaginationComp count={Math.ceil(sharedCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
            )}
          </TableContainer>
        )}
      </TableWrapper>
    </>
  );
};

SharedWithMe.propTypes = {
  counts: PropTypes.number,
  recentButtons: PropTypes.string
};
export default SharedWithMe;
