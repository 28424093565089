/* eslint-disable no-unused-vars */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import UserProfileImage from 'pages/Profileimage/UserProfileImage';
import { useNavigate } from 'react-router-dom';
import copyLink from '../../assets/images/copyLink.svg';
import { useTheme } from '@mui/material/styles';
import { ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import './ShareDocuments.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  openShareDocument,
  shareSingleDocument,
  sharedDocumentPermissionAction,
  transferOwnerAction
} from 'store/actions/ShareDocuments/ShareDocuments';
import { CgClose } from 'react-icons/cg';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import RemoveAccess from './RemoveAccess';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import { ThemeProvider } from '@mui/material/styles';
import crossButton from 'assets/designs/crossButton.svg';
import SendIcon from '@mui/icons-material/Send';

export default function ShareDocuments() {
  const iconStyle = {
    width: '35px',
    height: '35px'
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = React.useState({});
  const [selectOpen, setSelectOpen] = React.useState('');
  const [selectOpen1, setSelectOpen1] = React.useState('');
  const { projectDetails } = useSelector((state) => state.projects);
  const { shareDocument } = useSelector((state) => state.shareDocuments);
  const { user } = useSelector((state) => state.auth);
  const [mentioning, setMentioning] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [filteredTeammates, setFilteredTeammates] = React.useState([]);
  const [removeTeammateId, setRemoveTeammateID] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [mentionArray, setMentionArray] = React.useState([]);
  const [wennigInfo, setWarrnigInfo] = React.useState(false);
  const [selectedTeamMateRoles, setSelectedTeamMateRoles] = React.useState('viewer');
  const [removeAccessBox, setRemoveAccessBox] = React.useState(false);
  const [isNext, setIsNext] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [transferOwnerBox, setTransferOwnerBox] = React.useState(false);
  const [next, setNext] = React.useState({});
  const { teammatesList } = useSelector((state) => state.teammates);
  const id = projectDetails[0]?._id;
  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);

    if (text.trim() !== '') {
      setMentioning(true);
      const searchText = text.trim().toLowerCase();

      // Filter only teammates with status "accepted"
      const acceptedTeammates =
        teammatesList && teammatesList?.length > 0 && teammatesList?.filter((teammate) => teammate.status === 'accepted');

      const filtered =
        acceptedTeammates?.length > 0 &&
        acceptedTeammates?.filter(
          (teammate) =>
            teammate.teammateId.email.toLowerCase().includes(searchText) ||
            (teammate.teammateId.firstName + teammate.teammateId.lastName).toLowerCase().includes(searchText)
        );

      setFilteredTeammates(filtered);
    } else {
      setMentioning(false);
      setFilteredTeammates([]);
    }
  };

  const shareProject = () => {
    let body = {
      teammateId: [next?.teammateId?.id],
      notify: checked,
      message: message,
      inviteLink: window.location.href,
      documentPermission: selectedTeamMateRoles
    };

    dispatch(shareSingleDocument(id, body, navigate));
    setIsNext(true);
    setChecked(false);
    setInputValue('');
    setMessage('');
  };

  const handleRoleChangeSecond = (value) => {
    setSelectedTeamMateRoles(value);
    setSelectOpen1('');
  };

  const handleRoleChange = (teammateId, value) => {
    let body = {
      teammateId: teammateId,
      documentPermission: value
    };
    setSelectOpen(teammateId);
    dispatch(sharedDocumentPermissionAction(id, body, navigate));
    setSelectedRoles((prevSelectedRoles) => ({
      ...prevSelectedRoles,
      [teammateId]: value
    }));
    setSelectOpen('');
  };

  const handleMentionClick = (teammate) => {
    if (Array.isArray(filteredTeammates)) {
      const getEmail = filteredTeammates?.find((item) => item?.teammateId?.email === teammate);
      if (teammate?.teammateId?.id) {
        setMentionArray([...mentionArray, teammate?.teammateId?.id]);
        setInputValue(teammate?.teammateId.email);
        setMentioning(false);
        setFilteredTeammates([]);
        setNext(teammate);
        setIsNext(false);
      } else if (getEmail) {
        setMentionArray([...mentionArray, getEmail?.teammateId?.id]);
        setInputValue(getEmail?.teammateId.email);
        setMentioning(false);
        setFilteredTeammates([]);
        setNext(getEmail);
        setIsNext(false);
      }
    } else {
      let message = {
        code: 404,
        message: 'Teammate email not found'
      };
      dispatch(snackbarAction(true, message));
    }
  };

  // add function to copy link
  const copyToClipboard = () => {
    let message = {
      code: 200,
      message: 'Link copied'
    };
    dispatch(snackbarAction(true, message));
    navigator.clipboard.writeText(window.location.href);
  };

  React.useEffect(() => {
    const initialRoles = {};
    projectDetails[0]?.people_with_access?.length > 0 &&
      projectDetails[0]?.people_with_access?.forEach((teammate) => {
        initialRoles[teammate?.teammate?.id] = teammate?.documentPermission;
      });
    setSelectedRoles(initialRoles);
  }, [projectDetails]);

  const removeAccessHandle = () => {
    let body = {
      teammateId: removeTeammateId,
      isDeleted: true
    };
    dispatch(sharedDocumentPermissionAction(id, body, navigate));
    setRemoveAccessBox(false);
    setSelectOpen('');
  };

  const transferOwnerHandle = () => {
    let body = {
      teammateId: removeTeammateId
    };
    dispatch(transferOwnerAction(id, body, navigate));
    setRemoveAccessBox(false);
    setSelectOpen('');
    setTransferOwnerBox(false);
  };

  return (
    <Box>
      <RemoveAccess
        open={removeAccessBox}
        close={() => {
          setRemoveAccessBox(false);
        }}
        action={wennigInfo ? removeAccessHandle : transferOwnerHandle}
      />

      <Dialog
        onClose={() => {
          dispatch(openShareDocument(false));
          setInputValue('');
        }}
        maxWidth="xs"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={shareDocument}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(openShareDocument(false));
            setInputValue('');
          }}
          className="crossButton"
        >
          <img src={crossButton} alt="cross" />
        </IconButton>
        <DialogContent>
          <Dialog
            maxWidth="xs"
            aria-labelledby="customized-dialog-title"
            open={transferOwnerBox}
            onClose={() => {
              setTransferOwnerBox(true);
            }}
          >
            <Box p={3}>
              <DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setTransferOwnerBox(false);
                  }}
                  className="crossButton"
                >
                  <img src={crossButton} alt="cross" />
                </IconButton>
              </DialogTitle>
              <Box>
                <Box align="center">
                  <Box className="logo-container">
                    <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
                  </Box>
                  <Typography className="primaryHeading">Transfer ownership?</Typography>
                  <Box align="center">
                    <Typography sx={{ mb: 3, fontSize: '12px', display: 'flex', alignItems: 'center' }} align="center">
                      <InfoOutlinedIcon />
                      <span style={{ marginLeft: '10px' }}>You’ll be the owner until this person accepts ownership.</span>
                    </Typography>
                    <Box mt={3}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2, gap: '15px' }}>
                        <Button
                          onClick={() => {
                            setTransferOwnerBox(false);
                          }}
                          className="globalButtons"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setRemoveAccessBox(true);
                            setWarrnigInfo(false);
                          }}
                          className="globalButtonSecond"
                        >
                          Transfer
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Dialog>

          <Box p={2}>
            <Typography sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="h3">
              {!isNext && (
                <ArrowBackIcon
                  sx={{ mr: 1, cursor: 'pointer' }}
                  onClick={() => {
                    setIsNext(true);
                    setInputValue('');
                  }}
                />
              )}
              <Typography align="center" className={`primaryHeading`}>
                Invite your team
              </Typography>
            </Typography>
            <Typography align="center" className={`secondaryHeading`} mb={3}>
              Bring your team to collaborate and unlock the full power of Writer Genie together!
            </Typography>
            {isNext && (
              <Box>
                <Box
                  className="globalAlignment"
                  sx={{
                    borderRadius: '8px !important',
                    background: '#FFF',
                    border: '0.5px solid #70707080 !important',
                    padding: '8px 0px'
                  }}
                >
                  <OutlinedInput
                    fullWidth
                    name={`Add user email`}
                    autoComplete="off"
                    placeholder="Add User Email"
                    mt={3}
                    mb={3}
                    sx={{
                      textTransform: 'capitalize',
                      '&.Mui-focused': {
                        boxShadow: 'none',
                        border: 'none',
                        outline: 'none'
                      },

                      '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                      notchedOutline: {
                        border: 'none'
                      }
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    className="userEmailInput"
                  />
                  <SendIcon sx={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => handleMentionClick(inputValue)} />
                </Box>
                {/* </ThemeProvider> */}
                {mentioning && filteredTeammates?.length > 0 && (
                  <Box
                    className="mentionTeamMates"
                    sx={{ padding: '12px 14px!important', width: '90% !important', maxHeight: '150px', overflowY: 'auto' }}
                  >
                    {filteredTeammates?.length > 0 &&
                      filteredTeammates?.map((teammate, index) => (
                        <Box
                          key={index}
                          onClick={() => handleMentionClick(teammate)}
                          sx={{ display: 'flex', alignItems: 'center', mt: 1, cursor: 'pointer' }}
                        >
                          <UserProfileImage image={teammate?.teammateId?.profilePic} styles={iconStyle} />
                          <Box ml={3}>
                            {teammate?.teammateId?.firstName} {teammate?.teammateId?.lastName}
                            <Typography className="tableCount">{teammate?.teammateId?.email}</Typography>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                )}
                <Typography className="secondaryHeading" mt={2}>
                  People with Access
                </Typography>
                <Box mb={3}>
                  <Box className="accessBox" sx={{ mt: 1 }}>
                    <Box className="accessBox" sx={{ mt: 1 }}>
                      <UserProfileImage image={projectDetails[0]?.owner_detail?.profilePic} styles={iconStyle} />
                      <Box ml={2}>
                        <Typography className="secondaryHeading">
                          {' '}
                          {projectDetails[0]?.owner_detail?.firstName} {projectDetails[0]?.owner_detail?.lastName}
                        </Typography>
                        <Typography className="tableCount">{projectDetails[0]?.owner_detail?.email}</Typography>
                      </Box>
                    </Box>
                    <Typography variant="sunbtitle2" pr={3}>
                      Owner
                    </Typography>
                  </Box>
                </Box>
                <Box mb={3}>
                  {projectDetails[0] &&
                    projectDetails[0]?.people_with_access?.length > 0 &&
                    projectDetails[0]?.people_with_access?.map((teammate, index) => (
                      <Box key={index} className="accessBox" sx={{ mt: 1, cursor: 'pointer' }}>
                        <Box className="accessBox" sx={{ mt: 1, cursor: 'pointer' }}>
                          <UserProfileImage image={teammate?.teammate?.profilePic} styles={iconStyle} />
                          <Box ml={2}>
                            {teammate?.teammate?.firstName} {teammate?.teammate?.lastName}
                            <Typography className="tableCount">{teammate?.teammate?.email}</Typography>
                          </Box>
                        </Box>
                        <Select
                          key={teammate?.teammate?.id}
                          open={selectOpen === teammate?.teammate?.id ? true : false}
                          onOpen={() => setSelectOpen(teammate?.teammate?.id)}
                          onClose={() => setSelectOpen('')}
                          className="actionSelector"
                          value={selectedRoles[teammate?.teammate?.id] || 'viewer'}
                          onChange={(event) => {
                            handleRoleChange(teammate?.teammate?.id, event.target.value);
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                '& .MuiSelect-select': {
                                  paddingLeft: 2
                                }
                              }
                            }
                          }}
                          sx={{
                            outline: 'none',
                            fontSize: '12px',
                            boxShadow: 'none !important',
                            p: 0,
                            '.MuiOutlinedInput-notchedOutline': { border: 0 }
                          }}
                          renderValue={(value) => value.charAt(0).toUpperCase() + value.slice(1)}
                        >
                          <>
                            <MenuItem
                              sx={{
                                textTransform: 'capitalize',
                                borderRadius: '6px !important',
                                mx: '10px',
                                mt: 1,
                                border: '1px solid #00000000',
                                mb: 1,
                                backgroundColor: selectedRoles[teammate?.teammate?.id] === 'viewer' ? '#efeaf6 !important' : ''
                              }}
                              value="viewer"
                              onClick={() => {
                                handleRoleChange(teammate?.teammate?.id, 'viewer');
                              }}
                            >
                              <ListItemText sx={{ pl: 0 }} primary="viewer" />
                              {selectedRoles[teammate?.teammate?.id] === 'viewer' && (
                                <ListItemIcon style={{ marginLeft: 'auto' }}>
                                  <CheckIcon />
                                </ListItemIcon>
                              )}
                            </MenuItem>
                            <MenuItem
                              sx={{
                                textTransform: 'capitalize',
                                borderRadius: '6px !important',
                                mx: '10px',
                                mt: 1,
                                border: '1px solid #00000000',
                                mb: 1,
                                backgroundColor: selectedRoles[teammate?.teammate?.id] === 'editor' ? '#efeaf6 !important' : ''
                              }}
                              value="editor"
                              onClick={() => {
                                handleRoleChange(teammate?.teammate?.id, 'editor');
                              }}
                              p={2}
                            >
                              <ListItemText primary="editor" />
                              {selectedRoles[teammate?.teammate?.id] === 'editor' && (
                                <ListItemIcon style={{ marginLeft: 'auto' }}>
                                  <CheckIcon />
                                </ListItemIcon>
                              )}
                            </MenuItem>
                            <Box
                              sx={{
                                width: 200,
                                m: 1.9,
                                border: 1,
                                color: '#CED4DA'
                              }}
                            ></Box>
                            <>
                              <MenuItem
                                onClick={() => {
                                  setTransferOwnerBox(true);
                                  setRemoveTeammateID(teammate?.teammate?.id);
                                }}
                                sx={{
                                  textTransform: 'capitalize',
                                  borderRadius: '6px !important',
                                  mx: '10px',
                                  mt: 1,
                                  border: '1px solid #00000000',
                                  mb: 1
                                }}
                              >
                                Transfer ownership
                              </MenuItem>
                            </>
                            <MenuItem
                              onClick={() => {
                                setRemoveAccessBox(true);
                                setWarrnigInfo(true);
                                setRemoveTeammateID(teammate?.teammate?.id);
                              }}
                              sx={{
                                textTransform: 'capitalize',
                                borderRadius: '6px !important',
                                mx: '10px',
                                mt: 1,
                                border: '1px solid #00000000',
                                mb: 1
                              }}
                            >
                              Remove access
                            </MenuItem>
                          </>
                        </Select>
                      </Box>
                    ))}
                </Box>
                <Typography className={`secondaryHeading`} mb={2}>
                  Collaboration Link
                </Typography>
                <Box className="accessBox">
                  <ThemeProvider theme={TextFieldDesign}>
                    <OutlinedInput fullWidth readOnly sx={{ width: '60%' }} mt={3} mb={3} value={window.location.href} />
                  </ThemeProvider>
                  <Button
                    sx={{ minWidth: 'unset !important', height: '54px', ml: 1 }}
                    onClick={copyToClipboard}
                    className="globalButtonSecond"
                  >
                    <ContentCopyIcon sx={{ color: 'inherit', mr: 1 }} />
                    <span className="copyLinkText">Copy Link</span>
                  </Button>
                </Box>
              </Box>
            )}
            {!isNext && (
              <Box>
                <Box className="accessBox" sx={{ mt: 1 }}>
                  <Box className="sharedBox">
                    <Box sx={{ display: 'flex ', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                      <UserProfileImage image={next?.teammateId?.profilePic} styles={iconStyle} />
                      <Box ml={2} mb={1}>
                        {next?.teammateId?.firstName} {next?.teammateId?.lastName}
                      </Box>
                    </Box>
                  </Box>
                  <Select
                    key={next.teammateId.id}
                    open={selectOpen1 === next?.teammateId?.id ? true : false}
                    onOpen={() => setSelectOpen1(next?.teammateId?.id)}
                    onClose={() => setSelectOpen1('')}
                    className="actionSelector"
                    value={selectedTeamMateRoles}
                    onChange={(event) => {
                      handleRoleChangeSecond(event.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiSelect-select': {
                            paddingLeft: 2
                          }
                        }
                      }
                    }}
                    sx={{
                      width: '35%',
                      outline: 'none',
                      textTransform: 'capitalize',
                      boxShadow: 'none !important',
                      borderRadius: '8px',
                      border: '1px solid #D9D9D9',
                      p: '6px',
                      pl: 2,
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none !important' }
                    }}
                    renderValue={(value) => value.charAt(0).toUpperCase() + value.slice(1)}
                  >
                    <>
                      <MenuItem
                        sx={{
                          textTransform: 'capitalize',
                          borderRadius: '6px !important',
                          mx: '10px',
                          mt: 1,
                          border: '1px solid #00000000',
                          mb: 1,
                          backgroundColor: selectedTeamMateRoles === 'viewer' ? '#efeaf6 !important' : ''
                        }}
                        value="viewer"
                        onClick={() => {
                          handleRoleChangeSecond('viewer');
                        }}
                      >
                        <ListItemText sx={{ pl: 0 }} primary="viewer" />
                        {selectedTeamMateRoles === 'viewer' && (
                          <ListItemIcon style={{ marginLeft: 'auto' }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          textTransform: 'capitalize',
                          borderRadius: '6px !important',
                          mx: '10px',
                          mt: 1,
                          border: '1px solid #00000000',
                          mb: 1,
                          backgroundColor: selectedTeamMateRoles === 'editor' ? '#efeaf6 !important' : ''
                        }}
                        value="editor"
                        onClick={() => {
                          handleRoleChangeSecond('editor');
                        }}
                      >
                        <ListItemText primary="editor" />
                        {selectedTeamMateRoles === 'editor' && (
                          <ListItemIcon style={{ marginLeft: 'auto' }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </MenuItem>
                    </>
                  </Select>
                </Box>
                <FormControlLabel
                  sx={{ mt: 1, mb: 1, boxShadow: 'none !important' }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => {
                        setChecked(event.target.checked);
                      }}
                      sx={{ color: '#6631A5 !important' }}
                      name="checked"
                      size="small"
                    />
                  }
                  label={<Typography variant="h6">Notify</Typography>}
                />
                <ThemeProvider theme={TextFieldDesign}>
                  <TextField
                    sx={{
                      borderRadius: '8px'
                    }}
                    mt={3}
                    value={message}
                    onChange={(event) => {
                      setMessage(event.target.value);
                    }}
                    fullWidth
                    placeholder="Message (Optional)"
                    multiline
                    rows={4}
                    maxRows={4}
                  />
                </ThemeProvider>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    className="globalButtons"
                    sx={{ mt: 2, minWidth: 'unset !important', p: '0px 15px !important' }}
                    onClick={() => {
                      dispatch(openShareDocument(false));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="globalButtonSecond"
                    onClick={shareProject}
                    sx={{
                      mt: 2,
                      ml: 2,
                      p: '10px 25px !important',
                      minWidth: 'unset !important'
                    }}
                  >
                    Invite
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
