import {
  changeTeammateRole,
  getTeamMatesList,
  reSendInvitation,
  sendInvitationsToTeammates,
  teamMateDetails,
  updateTeammateStatus
} from 'services/TeammatesService';
import { genieLoaderAction, loaderAction, snackbarAction } from '../Authentication/AuthActions';
import { teammateSubscriptionPlanModal } from '../Subscription/SubscriptionActions';
import { handleErrors, handlelockState, subscriptionPlanModalOpen } from '../Projects/ProjectsActions';
export const TEAM_MATES_INVITATION = '[team mates invitation] user team mates invitation';
export const TEAM_MATES_MODAL = '[team mates modal] user team mates modal';
export const TEAM_MATES_DETAILS = '[team mates details] user team mates details';
export const TEAM_MATES_LIST = '[team mates list] user team mates list';

export function inviteTeammates(emails, navigate) {
  return (dispatch) => {
    dispatch(loaderAction('inviteteammate'));
    dispatch(genieLoaderAction(true));
    sendInvitationsToTeammates(emails)
      .then((response) => {
        let role = '';
        dispatch(teamMatesList(10, 0, role, navigate));
        dispatch(confirmTeammatesInvitation(response?.data));
        dispatch(handlelockState(true));
        dispatch(loaderAction(''));
        dispatch(teamMatesModal(false));
        dispatch(subscriptionPlanModalOpen(false));
        dispatch(genieLoaderAction(false));

        let message = {
          code: response?.status,
          message: 'Invitations have been sent'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
        dispatch(teamMatesModal(false));
        dispatch(teammateSubscriptionPlanModal(false));
        dispatch(loaderAction(''));
      });
  };
}

export function updateTeammateRole(teammateId, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    changeTeammateRole(teammateId, content)
      .then((response) => {
        dispatch(genieLoaderAction(false));
        let message = {
          code: response?.status,
          message: 'Role has been changed'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getTeamMateDetails(code, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    teamMateDetails(code)
      .then((response) => {
        dispatch(confirmTeammatesDetails(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function teamMatesList(limit, offset, role, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getTeamMatesList(limit, offset, role === 'All' ? '' : role)
      .then((response) => {
        dispatch(teamMatesData(response?.data));
        dispatch(handlelockState(false));
        dispatch(genieLoaderAction(false));
        dispatch(teammateSubscriptionPlanModal(false));

        dispatch(subscriptionPlanModalOpen(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function reSendUserInvitation(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    reSendInvitation(content)
      .then((response) => {
        let role = '';
        dispatch(confirmTeammatesDetails(response?.data));
        dispatch(genieLoaderAction(false));
        dispatch(teamMatesList(10, 0, role, navigate));
        dispatch(handlelockState(true));
        let message = {
          code: response?.status,
          message: response?.data.message
        };
        dispatch(snackbarAction(true, message));
        if (response?.data.status === 'accepted') {
          navigate('/shared-projects');
        }
        if (response?.data.status === 'rejected') {
          navigate('/');
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function setTeammateStatus(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    updateTeammateStatus(content)
      .then((response) => {
        let role = '';
        dispatch(confirmTeammatesDetails(response?.data));
        dispatch(handlelockState(true));
        dispatch(teamMatesList(10, 0, role, navigate));
        dispatch(genieLoaderAction(false));
        let message = {
          code: response?.status,
          message:
            response?.data.status === 'accepted'
              ? 'Invitation has been accepted'
              : response?.data.status === 'cancelled'
              ? 'Invitation has been cancelled'
              : response?.data.status === 'revoked'
              ? 'Teammate has been removed'
              : 'Invitation has been removed'
        };
        dispatch(snackbarAction(true, message));
        if (response?.data.status === 'accepted') {
          navigate('/shared-projects');
        }
        if (response?.data.status === 'rejected') {
          navigate('/');
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function confirmTeammatesInvitation(data) {
  return {
    type: TEAM_MATES_INVITATION,
    payload: data
  };
}
export function teamMatesData(data) {
  return {
    type: TEAM_MATES_LIST,
    payload: data.teammate,
    count: data.count
  };
}
export function confirmTeammatesDetails(data) {
  return {
    type: TEAM_MATES_DETAILS,
    payload: data
  };
}
export function teamMatesModal(data) {
  return {
    type: TEAM_MATES_MODAL,
    payload: data
  };
}
