/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, Tooltip } from '@mui/material';
import RecentSearchesTable from './RecentSearchesTable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Steps from './Steps';
import SeoSlider from './Slider';
import './seotools.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { seoToolsSearch, seoToolsSearchLocation } from 'store/actions/SeoTools/SeoToolActions';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import { subscriptionValidation } from 'utils/SubscriptionValidation';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const validationSchema = Yup.object().shape({
  keyword: Yup.string().required('Keyword is Required')
});

function SeoTools() {
  const [currentStep, setCurrentStep] = useState(1);
  const [showKeywordErr, setShowKeywordErr] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [suggestionLocation, setSuggestionLocation] = useState(false);
  const { locations } = useSelector((state) => state.seoTools?.seotoolsLocation);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const { seotoolsSearchLists } = useSelector((state) => state.seoTools);
  const [showLocationErr, setShowLocationErr] = useState('');
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeLocationDropdown = () => {
    setSuggestionLocation(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeLocationDropdown);

    return () => {
      document.removeEventListener('click', closeLocationDropdown);
    };
  }, []);

  const handleLocation = (e) => {
    setSearchLocation(e.target.value);
    setIsTyping(true);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (isTyping === true && searchLocation.length >= 1) {
        dispatch(seoToolsSearchLocation(searchLocation, navigate));
        setSuggestionLocation(true);
      } else {
        // Add this condition to reset suggestionLocation when searchLocation is empty
        setSuggestionLocation(false);
      }
    }, 0);

    return () => {
      clearTimeout(debounceTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping == true, searchLocation]);

  useEffect(() => {
    if (searchLocation.length >= 3 && locations && locations?.length === 0) {
      setShowLocationErr('Location not found');
    } else {
      setShowLocationErr(''); // or setShowLocationErr(null);
    }
  }, [dispatch, locations, searchLocation]);

  const handleNext = (values) => {
    if (!subscriptionValidation(currentPlanwordsTeammates, user)) {
      dispatch(subscriptionPlanModalOpen(true));
    } else {
      if (currentStep === 1) {
        if (values.keyword.trim() !== '') {
          setCurrentStep(2);
          setShowKeywordErr('');
        } else {
          setShowKeywordErr('Keyword is Required');
        }
      } else if (currentStep === 2) {
        const isLocation = locations?.findIndex((item) => item?.full_name === values.location);
        if (values.location.length === 1 || values.location.length === 2) {
          return false;
        } else if (isLocation == undefined && values.location !== '') {
          return false;
        } else {
          if (isLocation !== -1 || values.location === '') {
            setCurrentStep(3);
          } else if (isLocation === -1) {
            setShowLocationErr('Location not found');
            // let message = { message: 'Location not found' };
            // dispatch(snackbarAction(true, message));
          }
        }
      }
    }
  };
  return (
    <>
      <Box className="seoContainer" sx={{ flexGrow: 1, width: '100%', mb: 4 }}>
        <Formik
          initialValues={{
            keyword: '',
            language: '',
            location: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (!subscriptionValidation(currentPlanwordsTeammates, user)) {
              dispatch(subscriptionPlanModalOpen(true));
            } else {
              if (showLocationErr) {
                values.location = '';
                setShowLocationErr('');
              }
              dispatch(seoToolsSearch(values, navigate));
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid>
                <Box p={4} pt={4}>
                  <Grid container sx={{ mb: 4, mt: 2, justifyContent: 'center' }}>
                    <Grid item lg={6} md={6} sm={10} xs={12}>
                      <Typography className="infoTextGlobal fontSize24">Let's construct your search inquiry.</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="keywordMainGrid" sx={{ justifyContent: 'center' }}>
                    <Grid item lg={6} md={6} sm={8} xs={12} sx={{ display: 'flex' }}>
                      <Box className="keywordInputBox">
                        <Box sx={{ height: '100px' }}>
                          <Typography className="tableCount" sx={{ mb: 1 }}>
                            Keyword
                          </Typography>
                          <TextField
                            autoComplete="off"
                            type="text"
                            name="keyword"
                            value={values.keyword}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setShowKeywordErr('');
                            }}
                            error={Boolean((touched.keyword && errors.keyword) || showKeywordErr)}
                            fullWidth
                            variant="outlined"
                            placeholder="Enter a topic to search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon mt={0.5} />
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: 8,
                                background: '#FFF',
                                height: 54,
                                border: '0.5px solid #70707080 !important',
                                '&:focus': {
                                  outline: 'none'
                                }
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color: '#FFF'
                              }
                            }}
                            style={{
                              border: 'none'
                            }}
                            className="custom-input-field" // Add a custom class name
                          />
                          {(touched.keyword && errors.keyword) ||
                            (showKeywordErr && <Typography className="error">{errors.keyword || showKeywordErr}</Typography>)}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className="locationMainGrid" sx={{ justifyContent: 'center', mt: 2 }}>
                    <Grid item lg={6} md={6} sm={8} xs={12} sx={{ display: 'flex' }}>
                      <Box className="keywordInputBox">
                        <Box className="locationInputBox">
                          <Typography className="tableCount" sx={{ mb: 1 }}>
                            Location
                          </Typography>
                          <TextField
                            type="text"
                            name="location"
                            required
                            placeholder="Enter country, region, or geographic area"
                            value={values.location}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              handleLocation(e);
                            }}
                            autoComplete="off"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon mt={0.5} />
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: 8,
                                background: '#FFF',
                                height: 54,
                                border: '0.5px solid #70707080 !important',
                                '&:focus': {
                                  outline: 'none'
                                }
                              }
                            }}
                            InputLabelProps={{
                              style: {
                                color: '#FFF'
                              }
                            }}
                            style={{
                              border: 'none'
                            }}
                            className="custom-input-field" // Add a custom class name
                          />
                          {showLocationErr && (
                            <Box className="langSuggestion" sx={{ width: '100%' }}>
                              <Typography className="suggestionDropdown">{showLocationErr}</Typography>
                            </Box>
                          )}

                          {suggestionLocation && locations?.length > 0 && (
                            <Box className="langSuggestion" sx={{ width: '100%' }}>
                              {locations?.map((suggestion) => {
                                return (
                                  <Typography
                                    key={suggestion.id}
                                    onClick={() => {
                                      handleChange({
                                        target: { name: 'location', value: suggestion.full_name }
                                      });
                                      setSuggestionLocation(false);
                                    }}
                                    className="suggestionDropdown"
                                  >
                                    {suggestion.full_name}
                                  </Typography>
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box align="center">
                    <Button
                      className="globalButtonSecond"
                      sx={{ minWidth: '122px !important', height: '54px', mt: 3, fontSize: '16px' }}
                      type="submit"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      <RecentSearchesTable />
    </>
  );
}

export default SeoTools;
