import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { MenuReducer } from './reducers/Menu';
import { AuthReducer } from './reducers/AuthReducer';
import { ProjectsReducer } from './reducers/ProjectsReducer';
import { TeammateReducer } from './reducers/TeammateReducer';
import { SubscriptionReducer } from './reducers/SubscriptionReducer';
import { GenieToolsReducer } from './reducers/GenieTools';
import { commentBoxReducer } from './reducers/CommentReducer';
import { shareDocumentsReducer } from './reducers/ShareDocumentsReducer';
import { TemplatesReducer } from './reducers/TemplatesReducer';
import { SuggestionReducer } from './reducers/SuggestionReducer';
import { SeoToolsReducer } from './reducers/SeoToolsReducer';
import { CouponsReducer } from './reducers/CouponsReducer';
import { AmbassadorReducer } from './reducers/AmbassadorReducer';
import { MediumAndWordPressReducer } from './reducers/MediumAndWordPressReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  menu: MenuReducer,
  auth: AuthReducer,
  projects: ProjectsReducer,
  teammates: TeammateReducer,
  subscription: SubscriptionReducer,
  genieTools: GenieToolsReducer,
  commentBox: commentBoxReducer,
  suggestions: SuggestionReducer,
  seoTools: SeoToolsReducer,
  shareDocuments: shareDocumentsReducer,
  templates: TemplatesReducer,
  CouponsReducer: CouponsReducer,
  AmbassadorReducer: AmbassadorReducer,
  MediumAndWordPressReducer: MediumAndWordPressReducer
});
export const store = createStore(reducers, composeEnhancers(middleware));
