import React from 'react';
import { Grid, Typography } from '@mui/material';
import TransactionTable from './TransactionTable';
import './TransactionHistory.css';
function Transactionhistory() {
  return (
    <Grid container lg={12} md={12} sx={{ mt: 5 }}>
      <Grid item lg={12} sx={{ mb: 3 }}>
        <Typography className="transactionText">Transactions History</Typography>
      </Grid>
      <Grid item lg={10} md={10} sm={12} xs={12} sx={{ paddingLeft: '20px' }}>
        <TransactionTable />
      </Grid>
    </Grid>
  );
}

export default Transactionhistory;
