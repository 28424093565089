import { OPEN_ITEM, OPEN_COMPONENT, OPEN_COMPONENT_DRAWER, OPEN_DRAWER } from './Types';

export function activeItem(id) {
  return (dispatch) => {
    dispatch(confirmedOpenItemAction(id));
  };
}

export function activeComponent(id) {
  return (dispatch) => {
    dispatch(confirmedActiveComponent(id));
  };
}

export function openComponentDrawer(value) {
  return (dispatch) => {
    dispatch(confirmedOpenComponentDrawer(value));
  };
}

export function openDrawer(id) {
  return (dispatch) => {
    dispatch(confirmedOpenDrawer(id));
  };
}

export function confirmedOpenItemAction(id) {
  return {
    type: OPEN_ITEM,
    payload: id
  };
}

export function confirmedActiveComponent(id) {
  return {
    type: OPEN_COMPONENT,
    payload: id
  };
}

export function confirmedOpenComponentDrawer(value) {
  return {
    type: OPEN_COMPONENT_DRAWER,
    payload: value
  };
}

export function confirmedOpenDrawer(id) {
  return {
    type: OPEN_DRAWER,
    payload: id
  };
}
