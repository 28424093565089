import { genieLoaderAction } from '../Authentication/AuthActions';
import {
  seoToolSearch,
  seoToolsLists,
  seoToolsDetails,
  seoToolsUpdate,
  seoToolsDelete,
  seoToolsSave,
  seoToolCreateDocument,
  seoToolsLocation,
  seoToolResearch
} from '../../../services/SeoService';
import { getCreatedProject, handleErrors, handlelockState } from '../Projects/ProjectsActions';
export const SEOTOOLS_SEARCH = '[seotools search] editor seoToolsSearch';
export const SEOTOOLS_SEARCH_LISTS = '[seotools search lists] editor seoToolsSearch lists';
export const SEOTOOLS_SEARCH_DETAILS = '[seotools search details] editor seoToolsSearch details';
export const SEOTOOLS_SEARCH_UPDATE = '[seotools search update] editor seoToolsSearch update';
export const SEOTOOLS_SEARCH_DELETE = '[seotools search delete] editor seoToolsSearch delete';
export const SEOTOOLS_SEARCH_SAVE = '[seotools search save] editor seoToolsSearch save';
export const SEOTOOLS_SEARCH_CREATE_DOCUMENT = '[seotools search create document] editor seoToolsSearch create document';
export const SEOTOOLS_SEARCH_LOCATION = '[seotools search location] editor seoToolsSearch location';
export const SEOTOOLS_SEARCH_RESEARCH = '[seotools research] editor seoTools research';

export function seoToolsSearch(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolSearch(content)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getSeoToolsSearch(response?.data));
          navigate(`/seo-tools/${response?.data?._id}`);
          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchLists(limit, offset, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolsLists(limit, offset)
      .then((response) => {
        dispatch(getSeoToolsSearchLists(response?.data));
        dispatch(handlelockState(false));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchDetails(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolsDetails(id)
      .then((response) => {
        dispatch(getSeoToolsSearchDetails(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchUpdate(id, updateData, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolsUpdate(id, updateData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getSeoToolsSearchUpdate(response?.data));
          dispatch(seoToolsSearchDetails(id, navigate));
          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchDelete(id, isDeleted, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolsDelete(id, isDeleted)
      .then((response) => {
        dispatch(getSeoToolsSearchDelete(response?.data));
        dispatch(handlelockState(true));
        dispatch(seoToolsSearchLists(10, 0, navigate));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchSave(id, saveData, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolsSave(id, saveData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getSeoToolsSearchSave(response?.data));
          dispatch(seoToolsSearchDetails(id, navigate));
          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsCreateDocument(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolCreateDocument(content)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/project/${response?.data?.project[0]?._id}`);
          dispatch(getCreatedProject(response?.data?.project));

          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsSearchLocation(location, navigate) {
  return (dispatch) => {
    // dispatch(genieLoaderAction(true));
    seoToolsLocation(location)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getSeoToolsSearchLocation(response?.data));
          // dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function seoToolsResearch(searchContent, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    seoToolResearch(searchContent)
      .then((response) => {
        if (response) {
          dispatch(getSeoToolsSearchDetails(response?.data));
          // dispatch(getSeoToolsResearch(response?.data));
          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getSeoToolsSearch(data) {
  return {
    type: SEOTOOLS_SEARCH,
    payload: data
  };
}

export function getSeoToolsSearchLists(data) {
  return {
    type: SEOTOOLS_SEARCH_LISTS,
    payload: data.seoTools,
    count: data.count
  };
}

export function getSeoToolsSearchDetails(data) {
  return {
    type: SEOTOOLS_SEARCH_DETAILS,
    payload: data
  };
}

export function getSeoToolsSearchUpdate(data) {
  return {
    type: SEOTOOLS_SEARCH_UPDATE,
    payload: data
  };
}

export function getSeoToolsSearchDelete(data) {
  return {
    type: SEOTOOLS_SEARCH_DELETE,
    payload: data
  };
}

export function getSeoToolsSearchSave(data) {
  return {
    type: SEOTOOLS_SEARCH_SAVE,
    payload: data
  };
}

export function getSeoToolsCreateDocument(data) {
  return {
    type: SEOTOOLS_SEARCH_CREATE_DOCUMENT,
    payload: data
  };
}

export function getSeoToolsSearchLocation(data) {
  return {
    type: SEOTOOLS_SEARCH_LOCATION,
    payload: data
  };
}

export function getSeoToolsResearch(data) {
  return {
    type: SEOTOOLS_SEARCH_RESEARCH,
    payload: data
  };
}
