import { createGlobalStyle } from 'styled-components';
import logoGradient from './assets/images/common/genieOn1.svg';

export const GlobalStyle = createGlobalStyle`
.tone > .MuiMenu-paper {
    background-color: #52708F !important;
}
body{
  background: #F9F9F9 !important;
}
:root {
  --selection-color: #CED4DA; /* Default selection color */
}
.lampImage {
  margin-right: 10px;
}

/////////////////>>>>>>>>common<<<<<<<<<<////////////////////
.sharedBox{
  width: 60%;
  display: flex;
  border-radius: 8px;
  padding: 1px 12px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}
.ql-container{
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0px 3px 16px #0000001A;
  border:none !important;
  // background: #fff;
}
.commonFlex{
  display:flex;
  align-items: center;
  justify-content: center;
}
.accessRemove{
    color: #2A303B;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.my-scroll-container-class{
    overflow-y: scroll;
    max-height: 90vh;
    overflow-x: hidden;
    padding-right: 15px!important;
    padding-left: 8px !important;
}
.ql-editor{
    max-height: 80vh;
    overflow-y: scroll;
    min-height: 80vh;
    font-size: 20px;
    padding-bottom: 100px;
}
.ql-editor::-webkit-scrollbar, .my-scroll-container-class::-webkit-scrollbar
{
  background-color: transparent; /* Change this to the desired background color */
  width: 5px;
  border-radius: 8px;
}

/* Set the color of the scrollbar thumb */
.ql-editor::-webkit-scrollbar-thumb, .my-scroll-container-class::-webkit-scrollbar-thumb
{
  background-color: #6631A5; /* Change this to the desired thumb color */
  width: 8px;
  border-radius: 8px;
}

.containersuggestionBox::-webkit-scrollbar 
{
  background-color: transparent; /* Change this to the desired background color */
  width: 5px;
  border-radius: 8px;
}
.containersuggestionBox::-webkit-scrollbar-thumb {
  background-color: #fff; /* Change this to the desired thumb color */
  width: 8px;
  border-radius: 8px;
  padding-right:10px;
}
.radioBtn-label-custom .MuiFormControlLabel-label {
  background-color: #52708F !important;
    border-radius: 8px;
    border: none;
    box-shadow: unset;
    color: #fff !important;
     width: 100%;
   
}
.radioBtn-label .MuiFormControlLabel-label {
    background-color: #52708F !important;
    border-radius: 8px;
    border: none;
    box-shadow: unset;
    color: #fff !important;
    width: 100%;
    padding: 16.5px 14px;
}
.customLabel .MuiFormControlLabel-label {
    background-color: #52708F !important;
    border-radius: 8px;
    border: none;
    box-shadow: unset;
    color: #fff;
    width: 100%;
}

//common modal button

.cancelBtnWithBorder{
  color: #6631A5;
  border-color: #6631A5;
  border-radius:8px;
  padding: 8px 25px;
}

.commonCancelBtn {
  color: #6631A5;
  border-color: #6631A5;
 
}

.commonCancelBtn:hover,
.cancelBtnWithBorder:hover {
  background-color: #8f7dfd;
  color: #fff;
  border-color: #8f7dfd;
}

.commonRemoveBtn {
  background-color: #c00606;
}

.commonRemoveBtn:hover,
.commonSaveBtn:hover  {
  background-color: #8f7dfd;
  color: #fff;
}

.commonSaveBtn{
  background-color:#6631A5;
  color:#fff;
  border-radius: 8px;
  border-color: #6631A5;
  border: solid 1px;
  padding: 8px 25px;
}

@keyframes rotate360Genie {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotating-icon {
    display: inline-block; /* or block depending on your layout */
    animation: rotate360Genie 1s linear infinite; /* Change the duration and timing function as needed */
  }
.outlines{
    // background: #FFF;
    // border: 0.5px solid #70707080!important;
    // cursor: pointer;
    // padding: 16px;
    // // display: flex;
    // // position: relative;
    // // justify-content: space-between;
    // width: 100%;
     overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
// .outlinesText{
//    overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
// }
// .outlines{
//     background-color: #52708F !important;
//     border-radius: 8px;
//     border: none;
//     box-shadow: unset;
//     color: #fff;
//     width: 100%;
//     padding: 16.5px 14px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     border-radius: 8px!important;
// }

.showFullText{
  white-space: unset !important;
}

.pointerCursor{
  cursor: pointer !important;
}
// /* Define the CSS animation keyframes */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999; /* Ensure the loader is on top of other content */
}
.helpMeGenie{
    position: absolute;
    top: 5px;
    z-index: 111;
    right: 8px;
}
.MuiTooltip-tooltip{
    background-color:#E3DFDF !important;
    padding: 10px 15px !important;
    color: #000 !important;
    border-radius: 12px !important;
}
.logoAnimationStart {
    display: inline-block;
    animation: rotate3601 1s linear infinite;
  }

  @keyframes rotate3601 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.6);
    }
    100% {
      transform:scale(1);
    }
  }
  .logoAnimationStart1 {
    display: inline-block;
    animation: rotate360 1s linear infinite;
  }
  .loaderBottomText{
    position: absolute;
    bottom: 90px;
    font-size: 25px;
    font-weight: 600;
  }

.genieLoaderOuter{
  /* background: #fff; */
  padding: 60px;
  height: 256px;
  border-radius: 12px;
  position: relative;
  padding-top: 31px;
}
  @keyframes rotate360 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
      margin-top: 10px;
    }
    100% {
      transform:scale(1);
      margin-top: 0px;

    }
  }
  // .shortenSlider{
  //   margin-right:60px;
  // }

 @media (min-width: 320px) and (max-width: 1024px) {
  .headerName{
    text-overflow: ellipsis;
    }
    .globalButtonSecond .globalButtons{
      padding: 6px 25px !important;
    }
 }
  @media (min-width: 320px) and (max-width: 650px) {
 
    .MuiDialog-paper{
      width: calc(100% - 6px) !important;
      margin: 12px !important;
    }
 
    .recentButtonsWrapper{
      display: flex;
    }

    .infoTextGlobal{
      width: 100% !important;
      height: unset !important;
    }
  }

/* Define the CSS animation keyframes */


.noDataOuter{
    width: 100%;
    text-align: center;
    margin-top: -22%;
}
.MuiLinearProgress-colorPrimary{
    background-color: #707070 !important;
    height: 10px !important;
}
.side .MuiLinearProgress-barColorPrimary {
    background-color: #FFA810 !important;
}
.MuiTableCell-root {
    background-color: #fff !important;
    padding-left: 0px !important;
    border:none !important
}
.MuiPopover-root .MuiMenu-list {
  padding: 0;
}
.actionSelector > .Mui-selected{
    background-color: #52708F !important;
    background-color: #f0f0f0 !important;
    border: 1px solid #ccc !important;
}
.alignMenuItems{
  padding: 10.5px 14px 10.5px 12px !important;
}

.combineModal{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media (min-width: 320px) and (max-width: 1207px) {
 .combineModal{
  display: grid;
  grid-template-columns: 1fr;
 }
}

.actionSelector .MuiSelect-select{
  padding-left: 0px !important;
  border: none !important;
  outline: unset !important;
}

.Mui-focused{
  outline: unset !important;
  box-shadow: none !important;
}
.toneSelector > .MuiPopover-root .MuiMenuItem-root {
  background-color: #52708F !important;
    color: #fff !important;
  &:hover {
    background-color: #52708F !important;
    color: #fff !important;
  } 
}
.deleteIcon{
    width: 30px;
}
.datesColor{
    color: #2A303B;
}
.userAvatar{
  width: 110px;
  height: 109px;
  -webkit-border-radius: 60px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 60px;
  -moz-background-clip: padding;
  border-radius: 60px;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
  position: relative;
  border: 3px solid #6631A5 ;
}

.languageSelector::-webkit-scrollbar {
  display: none;
}

.languageSelector {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.languageSelector > .MuiSelect-select{
    background: #F1F1F1 !important;
    border-radius: 8px !important;
    border: none !important;
    outline: unset !important;
    padding: 16px 0px 16px 16px;
}
.languageSelector .MuiMenuItem-root .Mui-selected{
  background-color: #52708F !important;
}
.languageSelector > .MuiButtonBase-root-MuiChip-root {
    background: #545050 !important;
    border: 1px solid blue !important;
    padding: 19px 30px !important;
    border-radius: 5px !important;
}
.languageSelector > .MuiOutlinedInput-notchedOutline {
    border-radius: 16px !important;
    border: none !important;
    outline: unset !important;
}

.backArrow{
    display: flex;
    color: #000000;
}
.skipBtn{
   color: #6631A5;
   padding:0;
   background:none;
}
.skipBtn span{
  position:relative;
}

.skipBtn:hover{
  text-decoration: underline;
  background:none
}
.globalAlignment{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.globalButtons{
  border-radius: 8px;
  background:  #FFF;
  padding: 10px 25px;
  color: #6631A5;
  border: 1px solid #6631A5;
  min-width: 180px;
}
// .globalButtons:hover{
//   border-radius: 24px;
//   background:  #6631A5;
//   padding: 10px 25px;
//   margin-right: 15px;
//   color: #fff;
// }
.globalButtonSecond{
  border-radius: 8px;
  background:  #6631A5;
  padding: 10px 25px;
  color: #fff;
  border: 1px solid #6631A5;
  min-width: 180px;
}
.globalButtonSecond:hover{
  border-radius: 8px;
  background:  #FFF;
  padding: 10px 25px;
  color: #6631A5;
  border: 1px solid #6631A5;
}
.custom-upload-button {
  background-color: #007bff; /* Change to your desired background color */
  color: white; /* Change to your desired text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition */
}
// .carousel {
//   position: relative;
// }
// .carousel >div >div {
//   max-width: 1300px !important;
// }
// .carousel >div >div >div{
//   transform: unset !important;
// }
// .carousel >div >button {
//   background: unset;
//   border: unset;
//   position: absolute;
//   z-index: 9;
// }
.custom-upload-button:hover {
  background-color: #0056b3; /* Change to a darker color on hover */
}
.arrowDirection{
  transform: rotate(180deg);
}
.uploadcare--widget__button_type_open{
  color: #fff !important ;
  box-shadow: none !important ;
  width: 180px !important ;
  padding: 10px 16px !important ;
  font-weight: 400 !important ;
  font-size: 15px !important ;
  border-radius: 8px !important ;
  background-color: #6631A5 !important ;
  font-weight: 600 !important ;
  display: inline-block !important;
}
.uploadcare--widget__button_type_cancel{
  background-color: unset !important;
  display: none !important;
}
.uploadcare--widget__button_type_remove{
  display: none !important;
}
.uploadcare--widget__button uploadcare--widget__button_type_open{
  display: inline-block !important;

}
.uploadcare--widget__text{
  display: none !important;
}
.googlebtn > button{
   box-shadow:none !important;
}
.googlebtn span {
  padding: 0 !important;
}
.googleButton{
  display: flex;
  cursor:pointer;
  width:100%;
  padding-block: 13px;
  padding-inline:60px;
  gap:10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #ADB5BD;
  background: #FBFBFB;
  color: #6631A5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;  
}
.facebookbtn{
  display: flex;
  cursor:pointer;
  width:100%;
  padding-block: 10px;
  padding-inline:60px;
  gap:10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #ADB5BD;
  background: #FBFBFB;
  color: #6631A5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top:15px; 
}
.facebookbtn:hover,
.googleButton:hover,
.loginButton:hover {
background:#8F7DFD;
color:#fff;
}
.loginButton{
  background-color: #fbfbfb;
  color: #6631A5;
  border: 1px solid #ADB5BD;
  border-radius: 16px;
  margin-top: 16px;
  display: flex;  
  alignItems: center;
  font-weight:400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
  padding-block:10px;
  padding-left:20px

}
.containersuggestionBox{
  border-radius: 8px;
  text-align: center;
  color: #fff;
  width: 100%;
  max-height:150px;
  overflow:auto;
}
.replacebtn {
  border-radius: 8px;
  background: white;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-weight: 700;
  line-height: 140%;
  padding: 6px 12px;
  cursor:pointer;
}

.replacebtn:hover{
  color:#fff;
  background: rgb(255,168,16);
}
.ignorebtn {
  border-radius: 8px;
  border: 1px solid #FBFBFB;
  color: #FBFBFB;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 6px 12px;
  cursor:pointer;
}

.inputsuggtext{
  color: #000000;
  font-family: 'Inter';
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 5px;
}
.suggestionoutput{
  color: #000000;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.generateImg{
  width: 20px;
  height: 20px;
  cursor:pointer;
}
.suggestionTextBox{
  min-height: min-content; 
  max-height: max-content; 
  overflow-y: auto;
  overflow-x: hidden;
}
.btnbox {
  display: flex;
  gap: 20px;
  align-items: center;
}
.suggestionTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textTitle{
  color: #000;
  cursor: pointer;
}
.addCustomTitle input{
  font-size:0.9rem !important;
}
@media (min-width: 390px) and (max-width: 767px) {
  .facebookbtn .googleButton{
    padding-inline:14px;
  }
  .
}
@media (min-width: 320px) and (max-width: 767px) {
  .lampImage{
    margin-right: 0px !important;
  }
}


@media (min-width: 458px) and (max-width: 767px) {
  .accountInfoBox {
     justify-content: space-between !important;
  }
}
@media (min-width: 320px) and (max-width: 355px) {
 .responsiveLanguageSelector{
    width:235px !important;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
.languageSelector{
  width:390px;
 }
}

@media (min-width: 320px) and (max-width: 389px) {
.facebookbtn  {
  padding-inline: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.googlebtn span {
  width:100%;
}
.googlebtn button {
  width:100%;
}
.googleButton {
  padding-inline: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}
}



.css-typing p {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .css-typing p:nth-child(1) {
    width: 1.3em;
    animation: type 2s steps(40, end) infinite; /* Added 'infinite' here */
    animation-fill-mode: forwards;
  }

  @keyframes type {
    0% {
      width: 0;
    }
    100% {
      border: none;
    }
  }
  
  
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      border: none;
    }g
  }
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }


.nameEditor{
    border: unset;
    outline: unset;
    height: 30px;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    font-family: inter;
    margin-left: 12px;
    padding-left: 0px;
    background-color: inherit;
}
@media (max-width: 400px) {
  .nameEditor {
    width: 120px;
    margin-left: 0;
    font-size: 11px;
  }
  .languageSelector > .MuiSelect-select{
    padding: 16px 27px 16px 16px !important;
 }
}
@media (min-width: 401px) and (max-width: 600px) {
  .nameEditor {
    width: 150px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .nameEditor {
    width: 200px;
  }
}
.customUrlInput{
  outline: none;
  border: none;
  background: none;
  marginRight: 15px;
  width: 320px;
}
.emailEditor{
    width: 100%;
    padding-left: 15px;
    border: none;
    height: 60px;
    outline: unset;
    background: #F1F1F1;
    height: 56px;
    padding: 16px 0px 16px 16px;
    border-radius: 16px;
    font-size: 16px;
}
.linkText{
    text-decoration: none;
    color: #000;
}
.linkTextWhite{
    text-decoration: none;
    color: #FFF;
}
.projectListItems{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #2A303B;    
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}
.MuiCircularProgress-colorPrimary{
    color: white ;
}

.menuButtons:hover{
    background-color: #8F7DFD !important;
    color: white !important;
    border: unset !important;
}
.clearbtn:hover{
  background-color: #8F7DFD !important;
    color: white !important;
}

.createProjectText > .MuiCircularProgress-colorPrimary{
    color: black;
}
.header {
    color: #2A303B;
    font-size: 64px !important;
    font-family: "Inter";
}
.paragraph {
    font-size: 25px;
    font-family: "Inter";
    line-height: 30px;
    font-weight: 400px;
    color: "#2A303B" !important;
}
.emptyDataImg{
    width: 5%;
}
.db_p {
    font-size: 14px;
    font-family: "Inter";
    line-height: 21px;
    font-weight: 400px;
    color: "#2A303B" !important;
    letter-spacing: 0em;
}
.db_head{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em; 
}
.db_header{
    font-family: Inter;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
}

.btn_header {
font-family: Inter;
font-size: 25px;
font-weight: 400;
line-height: 38px;
letter-spacing: 0em;
text-align: left;
}
.emailVerificationText{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.emailIcon > .MuiSvgIcon-root {
    width: 150px !important;
    height: 150px !important;
}


 


///////////////////////////////////////////////////////////////////////////////////////

//creativity.js

.creativity .heading{
    display: flex;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
     
}
 
.creativeness .heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
   
}

.creativeness .paragraph {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    
}
.genieTools .MuiSlider-track{
  border-color: #6631A5 !important;
  border-radius: 8px;
  background: #6631A5 !important;
  
}
.commentBoxInner{
  width: 100%;
  // min-height: 230px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  z-index: 999;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
}

.highlight {
  background-color: yellow; 
  color: #333; 
}
.commentIco{
  background: #6631A5;
  border-radius: 50%;
  padding: 9px;
  display: none;
  z-index: 9999999;
  align-items: center;
  // right: 20px;
  position: absolute;
}
.mentionTeamMates{
  position: absolute;
  z-index: 999;
  width: 85%;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
  background: #E9ECEF;
  padding: 20px;
}
.commentsReply{
  display: none;
  position: absolute;
  z-index: 99999;
  width: 100%;
  overflow-y: scroll;
  max-height: 60vh;
  box-shadow:0px 8px 24px 0px rgba(0, 0, 0, 0.25)
}


.commentsReply::-webkit-scrollbar, .my-scroll-container-class::-webkit-scrollbar {
  background-color: transparent !important; /* Change this to the desired background color */
  width: 5px;
  border-radius: 8px;
}

/* Set the color of the scrollbar thumb */
.commentsReply::-webkit-scrollbar-thumb, .my-scroll-container-class::-webkit-scrollbar-thumb {
  background-color: #6631A5; /* Change this to the desired thumb color */
  width: 8px;
  border-radius: 8px;
}

.icontarget1{
  height: 51px;
  padding: 14px;
  border-radius: 50%;
  background: rgba(82, 112, 143, 1);
  display: none;
}
.readingLevel .MuiSlider-track{
  border-color: #8F7DFD !important;
  border-radius: 8px;
  background: #8F7DFD !important;
}
.readingLevel{
  color: #6631A5 !important;
  height: 20px !important;
}
.genieTools{
  color: #fff !important;
  height: 3px !important;
}
.genieToolsShorten{
  color: #fff !important;
  height: 3px !important;
}

.genieTools .MuiSlider-thumb::after{
  border: 5px solid !important;
  border-color: #6631A5 !important;
  width: 30px !important;
  height: 30px !important;
}

.genieToolsShorten .MuiSlider-thumb::after{
  border: 5px solid !important;
  border-color: #6631A5 !important;
  width: 30px !important;
  height: 30px !important;
}
.readingLevel .MuiSlider-thumb{
  border: 2px solid !important;
  border-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
}

.readingLevel .MuiSlider-thumb::after{
  border: 2px solid !important;
  border-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
  background-image: url(${logoGradient}) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.readingLevel .MuiSlider-thumb{
  border: 2px solid !important;
  border-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
}
.genieTools .MuiSlider-rail{
  background:  #B8C0CF  !important;
}
.genieToolsShorten .MuiSlider-track{
  border-color: #B8C0CF !important;
  border-radius: 8px;
  background: #B8C0CF !important;
}
.genieToolsShorten .MuiSlider-rail{
  background: #6631A5 !important;
}
.contentText{
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
}
.MuiDialogContent-root{
  padding: 20px 7px !important;
  border-radius: 8px !important;
  // background:  #FBFBFB !important;
}
///////////////////////////////////////////////////////////////////////

//features
.blackFriday .MuiDialog-paper{
  border-radius: 20px !important;
}
.features .heading {
    display: flex;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
  
}
.features .paragraph {

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
     
}
.features_cards .heading {
    display: flex;
    color: #000;
    font-size: 25px;
    font-weight: 500;
    line-height: 120%;
    
    
}

.features_cards .paragraph {
    color: #000;
    font-size: 14px;
   
}
 
///////////////////////////////////////////////////////
//feedback

.feedback .heading {
    color: #FBFBFB !important;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
     
}

.feedback .paragraph {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    font-family: Roboto;
     
}


///////////////////////////////////////////////////////////////////////


//footer

.footer .paragraph{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
   
}

.footer .rights{
    color:"#FBFBFB";
    font-size: 16px;
    font-weight: 300;
    font-family: "Inter",
}

/////////////////////////////////////////////////

//price


.pricing_plans .heading {
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    color: "#2A3038";
}

.basic_plan .heading {
    font-size: 25px;
    line-height: 30px;
    font-weight: 400px;
    color: "#2A303B" !important;
}

.basic_plan .paragraph {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400px;
    color: "#2A303B" !important;
}

//////////////////////////////////////////////////////
// login.modal

.react-multi-carousel-track > li {
width: unset !important;
}

.login .heading {
    font-size: 25px;
    line-height: 30px;
    font-weight: 400px;
    color: "#2A303B" !important;
}


.bottomborder {
  border-bottom: none !important;
}
.imgStyles {
  border-radius: 50%;
  cursor: pointer;
  width: 100px !important;
}
.uploadImgIcon{
  position: absolute;
  bottom: -10px;
  right: 0;
}
.profileImgOuter{
  width: 100px;
  position: relative;
}
@media (min-width: 0px) and (max-width: 600px) {
  .imgStyles {
    height: 100px !important;
    width: 100px !important;
  }
  .emptyDataImg{
    width: 15%;
}
  .customUrlInput{
    width: unset !important
  }
  .savingText{
    display: none !important;
  }

  .textOverflowSet{
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px 
  }
  .hideHelpMe{
    display: none !important;
  }
}
 
.custom-input-field fieldset {
  /* Your styles for the fieldset element go here */
  border: none !important;
  /* Additional styles as needed */
}


.iconStyles {
  height: 55px !important;
  width: 55px !important;
}
.templateImages{
  width: 100%;
  border-radius: 12px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .iconStyles {
    height: 100px !important;
    width: 100px !important;
  }

  .copyLinkText{
    display: none !important;
  }
}
.commonEllipsisClass{
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}
.imagestyle{
  marginTop: '12px !important',
    height: '24px !important',
    width: '24px !important'
}

.repharseInput input {
  width: 20% !important
}
.repharseInput div:first-child{
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 0px) and (max-width: 768px) {
  .imagestyle {
    margin-top: 5px !important;
    height: 15px !important;
    width: 15px !important;
  }
  .helpMeGenie{
    top: 54px !important;
  }
  .backHomeBtn{
    height: 55px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .imagestyle {
    margin-top: 12px !important;
    height: 24px !important;
    width: 24px !important;
  }
}
 
@media (min-width: 1200px)  {
  .imagestyle {
    margin-top: 12px !important;
    height: 24px !important;
    width: 24px !important;
  }
}

.custom-bg-color {
  background-color: yellow;
  color: black; 
}


.header{
  font-size: 32px !important;
}
@media (min-width: 0px) and (max-width: 386px) {
  .header {
    font-size: 15px !important;
  }
}
@media (min-width: 386px) and (max-width: 600px) {
  .header {
    font-size: 18px !important;
  }
}
@media (max-width: 767px) {
  .hideHelpMe{ 
    display: none !important;
  }
  .globalButtons{
    min-width: unset !important;
  }
  .globalButtonSecond{
    min-width: unset !important;
  }
  .primaryHeading{
    font-size: 12px !important;
  }
  .premiumHeadingText{
    font-size: 16px !important;

  }
  .recentButtonsSelected{
      min-width: 50px !important;
      // padding: 8px 15px !important;
      padding:0px !important;
      padding-block:5px !important;
  }
  .recentButtons{
      min-width: 50px !important;
      // padding: 8px 6px !important;
      padding:0px !important;
      padding-block:5px !important;

  }
  .recentButtonsWrapper{
   position: absolute;
   right: 20px;
   top: 43px !important;
  }
  .goDashboardbtn{
     min-width: 180px !important;
  }
}
@media (min-width: 768px) and (max-width:900px) {
  .exitHideHelpMe{
    display: none !important;
 }
 .iconExit, .teammatesIcon{
  min-width:0px !important
 }
 
}
@media (max-width:1026px){
  .primaryHeading{
    font-size: 16px !important;
  }
}
@media (min-width:1026px){
  .restoreAllIcon{
    display:none
  }
}
@media (min-width:768px){
  .sharedMyProjectsBtn{
    display:none
  }
}

@media (min-width: 768px) and (max-width: 1265px) {
.searchResultBtn{
 min-width:auto;
 }
}


////////////////////////// Editor ////////////////////////////

.suggestion{
   
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.grades{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.supStyle{
  font-size: 14px;
}

@media (max-width: 600px) {
  .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    padding-top: 5px;
  }
}
 
@media (max-width: 600px) {
  .css-r1da3b-MuiGrid-root{
    margin-top: 0px;
  }
}



.uploadcare--widget__button_type_open{
  width: '100% !important'
}

////////////////////////// Modal ////////////////////////////
.ql-editor > p::selection {
  // background: #CED4DA;
  border-radius: 8px;
  padding: 0px 5px;
}

.testing > p::selection {
  background: green;
  border-radius: 8px;
  padding: 0px 5px;
}
.ql-editor > p::selection {
  background-color: var(--selection-color);
}
.fontSize28{
  font-size: 28px !important;
}
.primaryHeading{
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.areYouSureText{
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.secondaryHeading{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.primaryColor{
  color: #6631A5 !important;
}
.globalShadow{
  border-radius: 16px;
  box-shadow: 0px 3px 16px #0000001A;
  background: #fff;
}
.premiumFeatures{
  width: 194px;
  height: 179.366px;
  flex-shrink: 0;
}
.tableCount{
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
.infoTextGlobal{
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 386px;
  height: 34px;
  flex-shrink: 0;
}
.fontSize24{
  font-size: 24px !important;
  width: 100% !important;
}
.info{
  margin-bottom: 20px;
}
.info .MuiMenuItem-root{
  border: 1px solid #00000000;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  padding:5px
}
.MuiMenuItem-root{
  border: 1px solid #00000000;
  border-radius: 6px;
  margin: 10px 10px;
}
.MuiMenuItem-root:hover{
    background-color: #efeaf6 !important;
    border: 1px solid #00000000;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
}
.languageSelector .Mui-checked{
  color: red !important;
}
.mainTables{
  min-height: 380px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.note{
  color: #6631A5;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MuiTabs-indicator{
  display: none !important;
}
.newBackArrow{
    width: 40px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 16px #00000029;
    opacity: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.secondaryColor{
  color: #707070 !important;
}
.MuiAccordion-root{
  box-shadow: 0px 3px 16px #0000001A !important;
  border-radius: 16px !important;
  padding: 10px !important; 
}
.badegs{
  background: #efeaf6;
  padding: 5px 10px;
  margin-right: 10px;
  color: #6631A5;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
}
.bottomBorder{
  border-bottom: '0.5px solid #70707080 !important',
}
.crossButton{
  position: absolute;
  right: 15px;
  top: 15px;
}
.MuiTabs-flexContainer{
  justify-content: space-between !important;
}
.MuiDialog-paper{
  border-radius: 16px !important;

}
.MuiAppBar-root{
  background:#F9F9F9!important
}

.MuiDialog-paper::-webkit-scrollbar {
  display: none !important;
}
.priceText{
  color: #6631A5;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  position: relative;
}
.dollarSign{
  font-size: 24px;
  font-weight: 100;
  color: #6631A5;
}
.backHomeBtn {
  border-radius: 16px;
  background: #F2F2F2;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 27px;
  width: 100%;
  // cursor:pointer;
}
.ql-snow.ql-toolbar .ql-picker-label:hover{
  color: #000 !important;
}
.ql-snow .ql-picker.ql-header {
  width: 130px;
  height: 40px;
}
.ql-toolbar.ql-snow .ql-picker-label{
  background: #fff !important;
  padding: 11px 4px;
  // padding: 14px 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.ql-toolbar.ql-snow{
  border-radius: 16px 16px 0px 0px;
  background:  #6631A5;
  min-height: 60px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ql-saved-status{
  width: 93px !important;
  color: #fff;
  height: 35px !important;
  font-size: 15px !important;
  border:none !important;
}
.ql-saved-status:hover{
  color: #fff !important;
}
.ql-formats{
  margin-top: 5px;
}

.ql-snow.ql-toolbar button{
  border-radius: 8px;
  border:1px solid #FFF !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{
  filter: invert(0%) sepia(1%) saturate(7466%) hue-rotate(276deg) brightness(84%) contrast(101%) !important;
}
.ql-snow .ql-toolbar button svg, .ql-snow.ql-toolbar button svg{
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(182deg) brightness(107%) contrast(101%);
}

.documentStats{
  border-radius: 16px 16px 0px 0px;
  background: #6631A5;
  padding: 12px;
}
.smallInfo{
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.titles{
  position: relative;
  margin-top: 16px;
  display: flex;
}
.mainBoxes{
  border-radius: 8px!important;
  background: #FFF;
  border: 0.5px solid #70707080!important;
  cursor: pointer;
  padding: 16px;
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.mainBoxesSelected{
  border-radius: 8px!important;
  background: #FFA810;
  border: 0.5px solid #FFA810 !important;
  cursor: pointer;
  padding: 16px;
  position: relative;
  display: flex;
  color: #fff;
  justify-content: space-between;
}
.selectedCustomTitle{
    color: #fff;
    background: #FFA810;
    border: 0.5px solid #FFA810 !important;
}

.deleteElement{
  padding: 2px;
  display: flex;
  background: #6631A5;
  border-radius: 50%;
  top: -9px;
  right: -10px;
  position: absolute;
  margin-left: 0.1px;
}
#SpeedDialbasicexample-actions{
  background: #FFF; 
  z-index: 99;
  padding-left: 4px !important;
  border-radius: 16px;
  box-shadow: 0px 3px 16px #0000001A;
}
.testS > button{
  display: none !important;
}
.toneSelector .Mui-selected {
  background: #efeaf6 !important;
}
// .MuiTabs-flexContainer {
//     justify-content: flex-start !important;
// }
.mediums{
  width: 100%;
}
.recentButtons{
  background: #FFF;
  padding: 8px 25px;
  color: #6631A5;
  border: 1px solid #6631A5;
  min-width: 180px;
  border-end-end-radius: 26px !important;
  border-start-end-radius: 26px !important;
  border-left: none;
  border-radius: 0px;
}
.recentButtonsSelected{
    border-end-start-radius: 26px !important;
    border-right: none;
    border-start-start-radius: 26px !important;
    background: #FFF;
    padding: 8px 25px;
    color: #6631A5;
    border: 1px solid #6631A5;
    min-width: 180px;
    border-radius: 0px;
}
.recentButtonsWrapper{
  position: absolute;
  right: 20px;
  top: 30px;
}
@media (max-width: 600px) {
 .iconExit{
    padding:8px 8px !important;
    margin-top:4px
  }
  .exitIcon{
    margin-right:0px !important;
    width:15px !important
  }
  .faUserCircleIcon{
    width:35px !important;
    height:35px !important
  }
  .adjustHomeBtn{
   padding: 0px 15px;
   margin-right:15px;
   margin-left: 10px;

  }
  .teammatesIcon{
    padding:3px 3px !important;
    margin-top:5px
  }
}


`;
