import React from 'react';
import { Box, Typography, Dialog, Button, IconButton, DialogTitle } from '@mui/material';
import { lockProjectAction, openConfirmationLock } from 'store/actions/Projects/ProjectsActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import crossButton from 'assets/designs/crossButton.svg';
import { useLocation } from 'react-router-dom';

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { lock, lockId, lockState } = useSelector((state) => state.projects);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={lock}
      onClose={() => {
        dispatch(openConfirmationLock(false, '', null));
      }}
    >
      <Box p={3}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(openConfirmationLock(false, '', null));
            }}
            className="crossButton"
          >
            <img src={crossButton} alt="cross" />
          </IconButton>
        </DialogTitle>
        <Box>
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography align="center" variant="h3">
              Are you sure ?
            </Typography>
            <Typography sx={{ my: 3 }} align="center" className="accessRemove">
              If you {lockState ? 'Lock' : 'Unlock'} this project, your teammates access will change.
            </Typography>
            <Box mt={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly', my: 2, gap: '20px' }}>
                <Button
                  className="globalButtons"
                  onClick={() => {
                    dispatch(openConfirmationLock(false, '', null));
                  }}
                  sx={{ width: { xs: '100%' } }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    dispatch(lockProjectAction(lockId, { projectLock: lockState }, pathname, navigate));
                  }}
                  className="globalButtonSecond"
                  sx={{ width: { xs: '100%' } }}
                >
                  Yes I'm sure
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
