import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = () => (
  <>
    <Outlet />
  </>
);

export default CommonLayout;
