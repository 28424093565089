// import { act } from 'react-dom/test-utils/index';
import {
  MY_PROJECTS_LIST,
  RECENT_PROJECTS_LIST,
  NEW_PROJECTS,
  PROJECT_STATUS,
  SAVE_PROJECT_CONTENT,
  PROGRESS_BAR_STATE,
  DELETE_PROJECTS,
  MY_PROJECT_STATUS,
  PRT_DELETE_PROJECTS_List,
  PRT_DELETE_PROJECTS,
  RESTORE_DELETE_PROJECTS,
  SHARED_PROJECTS_LIST,
  CONFIRMATION_LOCK,
  HANLDELOCKPAGINATION,
  HANDLESERVICEUNAVAILABLE,
  SHARED_PROJECTS_OWNERS_LIST,
  SET_OWNERS_ID
} from '../actions/Projects/ProjectsActions';

const initialState = {
  recentProject: [],
  projectDetails: [],
  genieStatus: {},
  projectContent: {},
  progressBar: 0,
  myProjectList: [],
  deltedList: [],
  sharedProjects: [],
  status: '',
  count: 0,
  lock: false,
  lockId: '',
  lockState: false,
  lockPaginationState: false,
  serviceUnavailable: null,
  sharedOwnersList: [],
  ownersId: '',
  deletedCount: 0,
  projectCount: 0,
  sharedCount: 0
};

export function ProjectsReducer(state = initialState, action) {
  if (action.type === RECENT_PROJECTS_LIST) {
    return {
      ...state,
      recentProject: action.payload
    };
  }
  if (action.type === PRT_DELETE_PROJECTS_List) {
    return {
      ...state,
      deltedList: action.payload,
      deletedCount: action.count
    };
  }
  if (action.type === PRT_DELETE_PROJECTS) {
    return {
      ...state,
      deltedList: action.payload
    };
  }
  if (action.type === SHARED_PROJECTS_OWNERS_LIST) {
    return {
      ...state,
      sharedOwnersList: action.payload
    };
  }
  if (action.type === SET_OWNERS_ID) {
    return {
      ...state,
      ownersId: action.payload
    };
  }

  if (action.type === CONFIRMATION_LOCK) {
    return {
      ...state,
      lock: action.payload,
      lockId: action.id,
      lockState: action.lockState
    };
  }

  if (action.type === HANLDELOCKPAGINATION) {
    return {
      ...state,
      lockPaginationState: action.payload
    };
  }

  if (action.type === RESTORE_DELETE_PROJECTS) {
    return {
      ...state,
      deltedList: action.payload
    };
  }
  if (action.type === SHARED_PROJECTS_LIST) {
    return {
      ...state,
      sharedProjects: action.payload,
      sharedCount: action.count
    };
  }
  if (action.type === MY_PROJECTS_LIST) {
    return {
      ...state,
      myProjectList: action.payload,
      projectCount: action.count
    };
  }
  if (action.type === MY_PROJECT_STATUS) {
    return {
      ...state,
      status: action.payload
    };
  }
  if (action.type === DELETE_PROJECTS) {
    return {
      ...state,
      myProjectList: projects
    };
  }
  if (action.type === NEW_PROJECTS) {
    return {
      ...state,
      projectDetails: action.payload
    };
  }
  if (action.type === SAVE_PROJECT_CONTENT) {
    return {
      ...state,
      projectContent: action.payload
    };
  }
  if (action.type === PROGRESS_BAR_STATE) {
    return {
      ...state,
      progressBar: action.payload
    };
  }
  if (action.type === PROJECT_STATUS) {
    return {
      ...state,
      projectDetails: action.payload
    };
  }
  if (action.type === HANDLESERVICEUNAVAILABLE) {
    return {
      ...state,
      serviceUnavailable: action.payload
    };
  }
  return state;
}
