import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  InputLabel,
  FormHelperText
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './MediumPortal.css';
import { deleteWebsite, getUserSite, mediumWebsiteUrl } from 'store/actions/MediumAndWordPress/MediumAndWordPressAction';
// import connectWebsite from '../../assets/images/mediumAndWordpress/connect website.svg';
import DeleteIcon from '../../assets/images/common/delete.svg';
import MediumTokenGeneration from './TokengenerateSteps/MediumTokenGeneration';
import WordpressAppPassName from './TokengenerateSteps/WordpressAppPassName';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import backIcon from '../../assets/icons/backIcon.svg';
import { activeItem } from 'store/actions/Menu/Menu';

const MediumPortal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [siteName, setSiteName] = useState('medium');
  const { userSites } = useSelector((state) => state.MediumAndWordPressReducer);
  const [open, setOpen] = useState(false);
  const [mediumSteps, setMediumSteps] = useState('1');
  const [getSiteLink, setGetSiteLink] = useState(null);
  const [wordPressSteps, setWordPressSteps] = useState('1');
  const [getWordPressSiteLink, setGetWordPressSiteLink] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [wPressForm, setWPressForm] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('medium');
  const { pathname } = useLocation();
  console.log(wPressForm, 'wPressForm', wordPressSteps);
  useEffect(() => {
    if (pathname === '/medium') {
      dispatch(activeItem('profile'));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    dispatch(getUserSite(siteName, navigate));
  }, [dispatch, navigate, siteName]);

  useEffect(() => {
    const getWord = userSites?.filter((site) => site.website === 'wordpress');
    if (getWord.length > 0) {
      setWordPressSteps('3');
    }
    if (getWord.length == 0) {
      setWPressForm(true);
    }
  }, [userSites]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <>
      <Grid sx={{ pl: { xs: 0, md: 5, sm: 5, lg: 5 } }} container>
        <Grid item lg={11} sm={11} xs={12} md={11}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, cursor: 'pointer' }} onClick={() => navigate('/profile')}>
            <Box
              sx={{
                display: 'inline-flex',
                marginRight: '5px'
              }}
            >
              <img width="100%" src={backIcon} alt="" />
            </Box>
            <Typography sx={{ fontSize: '14px', color: '#6631A5' }}>Account Info</Typography>
          </Box>
          <Box
            sx={{
              // mt: { xs: 2, md: 5, sm: 5, lg: 5 },
              mb: 3
            }}
          >
            <Typography variant="h3" sx={{ color: '#000000 !important', fontSize: '28px', fontWeight: '500' }}>
              Connect Platform
            </Typography>
          </Box>
          <Box>
            <Accordion
              onClick={() => {
                const getWord = userSites?.filter((site) => site.website === 'wordpress');

                setSiteName('medium');
                if (getWord.length > 0) {
                  setWordPressSteps('3');
                }
                if (getWord.length == 0) {
                  setWPressForm(true);
                }
              }}
              expanded={expandedAccordion === 'medium'}
              onChange={handleAccordionChange('medium')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ fontSize: '16px' }} aria-controls="panel1-content" id="panel1-header">
                Medium
              </AccordionSummary>
              <AccordionDetails>
                {userSites && userSites?.length > 0 ? (
                  userSites
                    ?.filter((site) => site.website === 'medium')
                    ?.map((med, ind) => (
                      <Grid item lg={12} key={ind}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            paddingInline: '15px',
                            mb: 2,
                            borderRadius: '8px'
                          }}
                        >
                          <Typography className="mediumlistText">{med.siteLabel}</Typography>
                          <Box
                            onClick={() => {
                              dispatch(deleteWebsite(med?._id, 'medium'));
                            }}
                            sx={{ display: 'flex' }}
                          >
                            <img src={DeleteIcon} alt="" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
                          </Box>
                        </Box>
                      </Grid>
                    ))
                ) : (
                  <>
                    {mediumSteps === '1' && (
                      <Formik
                        initialValues={{
                          siteLink: ''
                        }}
                        // validationSchema={Yup.object().shape({
                        //   siteLink: Yup.string().trim().required('Site Url is required.')
                        // })}
                        validationSchema={Yup.object().shape({
                          // siteLink: Yup.string()
                          //   .url('Invalid URL format')
                          // .test('is-medium-url', 'Please provide a valid Medium URL (e.g., https://medium.com/)', (value) => {
                          //   if (value && value.startsWith('https://medium.com')) {
                          //     return true;
                          //   }
                          //   return false;
                          // })
                          // .required('Site URL is required.')
                          siteLink: Yup.string()
                            // .url('Invalid URL format')
                            .test('is-valid-domain', 'Please provide a valid URL with supported domain extensions', (value) => {
                              if (value) {
                                // Regular expression to match URLs ending with supported domain extensions
                                const domainRegex = /^((?:https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/i;
                                return domainRegex.test(value);
                              }
                              return false;
                            })
                            .required('Site URL is required.')
                        })}
                        onSubmit={async (values) => {
                          setGetSiteLink(values.siteLink);
                          setMediumSteps('2');
                          setShowForm(true);
                        }}
                      >
                        {({ handleBlur, handleChange, handleSubmit, values, touched, errors }) => (
                          <>
                            <form noValidate onSubmit={handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid
                                  // className="searchGrid"
                                  sx={{
                                    pt: 1,
                                    pb: 1,
                                    borderRadius: 4
                                  }}
                                  lg={10}
                                  sm={9.5}
                                  xs={12}
                                  md={10}
                                  item
                                >
                                  <TextField
                                    className="searchInput"
                                    sx={{ paddingLeft: '15px', backgroundColor: '#70707020' }}
                                    variant="standard"
                                    fullWidth
                                    // placeholder="Add Access Token"
                                    autoComplete="off"
                                    value={values.siteLink}
                                    name="siteLink"
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (/^\s/.test(e.target.value)) {
                                        return;
                                      } else {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                  {touched.siteLink && errors.siteLink && (
                                    <FormHelperText error id="helper-text-siteLink-signup">
                                      {errors.siteLink}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid item md={1} sm={1} lg={1} xs={12}>
                                  <Button
                                    disableElevation
                                    fullWidth
                                    disabled={!values.siteLink}
                                    size="large"
                                    type="submit"
                                    className="globalButtonSecond"
                                    sx={{
                                      borderRadius: '24px !important',
                                      backgroundColor: !values.siteLink ? '#707070 !important' : '',
                                      borderColor: !values.siteLink ? '#707070 !important' : '',
                                      fontSize: '14px !important',
                                      minWidth: '123px !important',
                                      padding: '10px 18px !important'
                                    }}
                                  >
                                    Add Website
                                  </Button>
                                </Grid>
                              </Grid>
                            </form>
                          </>
                        )}
                      </Formik>
                    )}
                    {mediumSteps === '2' && (
                      <>
                        <Formik
                          initialValues={{
                            accessToken: '',
                            siteLabel: ''
                          }}
                          validationSchema={Yup.object().shape({
                            accessToken: Yup.string().trim().required('Access token is required.'),
                            siteLabel: Yup.string()
                              .trim()
                              .required('Site label is required.')
                              .min(3, 'Site label must be at least 3 characters')
                              .max(20, 'Site label must be at most 20 characters')
                          })}
                          onSubmit={async (values) => {
                            const mediumObj = {
                              access_token: values.accessToken,
                              siteType: 'medium',
                              siteLabel: values.siteLabel,
                              siteUrl: getSiteLink
                            };
                            dispatch(mediumWebsiteUrl(mediumObj, 'medium', navigate));
                          }}
                        >
                          {({ handleBlur, handleChange, handleSubmit, values }) => (
                            <>
                              <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={1.5}>
                                  <Grid item xs={12} md={7}>
                                    <Typography className="siteLinkText" sx={{ color: '#6631A5', fontSize: '16px', fontWeight: '500' }}>
                                      {getSiteLink}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} md={7}>
                                    <Stack spacing={1}>
                                      <InputLabel htmlFor="Access-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                        Add Access Token
                                      </InputLabel>
                                      <TextField
                                        className="searchInput"
                                        sx={{ paddingLeft: '15px', backgroundColor: '#70707020' }}
                                        variant="standard"
                                        fullWidth
                                        // placeholder="Add Access Token"
                                        autoComplete="off"
                                        value={values.accessToken}
                                        name="accessToken"
                                        InputProps={{
                                          disableUnderline: true
                                        }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (/^\s/.test(e.target.value)) {
                                            return;
                                          } else {
                                            handleChange(e);
                                          }
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} md={7}>
                                    <Stack spacing={1}>
                                      <InputLabel htmlFor="Label-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                        Add Site Label
                                      </InputLabel>
                                      <TextField
                                        className="searchInput"
                                        sx={{ paddingLeft: '15px', backgroundColor: '#70707020' }}
                                        variant="standard"
                                        fullWidth
                                        // placeholder="Add Site Label"
                                        autoComplete="off"
                                        value={values.siteLabel}
                                        name="siteLabel"
                                        InputProps={{
                                          disableUnderline: true
                                        }}
                                        type="text"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (/^\s/.test(e.target.value)) {
                                            return;
                                          } else {
                                            handleChange(e);
                                          }
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sx={{ paddingTop: '8px !important', my: 1 }}>
                                    <Typography variant="p" className="tokenText" onClick={() => setOpen(true)}>
                                      How to generate access token of medium?
                                    </Typography>
                                  </Grid>

                                  <Grid item md={1} sm={4} lg={1} xs={12} my={1}>
                                    <Button
                                      disableElevation
                                      disabled={!values.accessToken || !values.siteLabel}
                                      fullWidth
                                      size="large"
                                      type="submit"
                                      className="globalButtonSecond"
                                      sx={{
                                        borderRadius: '24px !important',
                                        backgroundColor: !values.accessToken || !values.siteLabel ? '#707070 !important' : '',
                                        borderColor: !values.accessToken || !values.siteLabel ? '#707070 !important' : '',
                                        minWidth: '123px !important'
                                      }}
                                    >
                                      Connect
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </>
                          )}
                        </Formik>
                        {open && <MediumTokenGeneration open={open} setOpen={setOpen} handleClose={() => setOpen(false)} />}
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              expanded={expandedAccordion === 'wordpress'}
              onChange={handleAccordionChange('wordpress')}
              onClick={() => setSiteName('wordpress')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ fontSize: '16px' }} aria-controls="panel2-content" id="panel2-header">
                WordPress
              </AccordionSummary>
              <AccordionDetails>
                <>
                  {wordPressSteps === '1' && wPressForm && (
                    <Formik
                      initialValues={{
                        wordPressSiteLink: ''
                      }}
                      validationSchema={Yup.object().shape({
                        wordPressSiteLink: Yup.string()
                          .trim()
                          .test('is-valid-domain', 'Please provide a valid URL with supported domain extensions', (value) => {
                            if (value) {
                              // Regular expression to match URLs ending with supported domain extensions
                              // const domainRegex = /\.(com|in|info|org|net|edu|gov)\/?$/i;
                              // return domainRegex.test(value);
                              const domainRegex = /^((?:https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/i;
                              return domainRegex.test(value);
                            }
                            return false;
                          })
                          .required('Site Url is required.')
                      })}
                      onSubmit={async (values) => {
                        setGetWordPressSiteLink(values.wordPressSiteLink);
                        setWordPressSteps('2');
                        setWPressForm(false);
                      }}
                    >
                      {({ handleBlur, handleChange, handleSubmit, touched, errors, values }) => (
                        <>
                          <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid
                                // className="searchGrid"

                                sx={{
                                  pt: 1,
                                  pb: 1,
                                  borderRadius: 4
                                }}
                                lg={10}
                                sm={9.5}
                                xs={12}
                                md={10}
                                item
                              >
                                <TextField
                                  className="searchInput"
                                  sx={{ paddingLeft: '15px', backgroundColor: '#70707020' }}
                                  variant="standard"
                                  fullWidth
                                  // placeholder="Add Access Token"
                                  autoComplete="off"
                                  value={values.wordPressSiteLink}
                                  name="wordPressSiteLink"
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  type="text"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    if (/^\s/.test(e.target.value)) {
                                      return;
                                    } else {
                                      handleChange(e);
                                    }
                                  }}
                                />
                                {touched.wordPressSiteLink && errors.wordPressSiteLink && (
                                  <FormHelperText error id="helper-text-wordPressSiteLink-signup">
                                    {errors.wordPressSiteLink}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item md={1} sm={1} lg={1} xs={12}>
                                <Button
                                  disableElevation
                                  fullWidth
                                  disabled={!values.wordPressSiteLink}
                                  size="large"
                                  type="submit"
                                  className="globalButtonSecond"
                                  sx={{
                                    borderRadius: '24px !important',
                                    backgroundColor: !values.wordPressSiteLink ? '#707070 !important' : '',
                                    borderColor: !values.wordPressSiteLink ? '#707070 !important' : '',
                                    fontSize: '14px !important',
                                    minWidth: '123px !important',
                                    padding: '10px 18px !important'
                                  }}
                                >
                                  Add Website
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        </>
                      )}
                    </Formik>
                  )}
                  {(wordPressSteps === '2' || showForm) && !wPressForm ? (
                    <>
                      <Formik
                        initialValues={{
                          username: '',
                          siteURL: '',
                          wordpressAccessToken: '',
                          siteLabel: ''
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().trim().required('Username is required.'),
                          siteURL: Yup.string()
                            .trim()
                            .test('is-valid-domain', 'Please provide a valid URL with supported domain extensions', (value) => {
                              if (value) {
                                // Regular expression to match URLs ending with supported domain extensions
                                const domainRegex = /^((?:https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/i;
                                return domainRegex.test(value);
                              }
                              return false;
                            })
                            .required('Website Url is required.'),
                          wordpressAccessToken: Yup.string().trim().required('Access Token is required.'),
                          siteLabel: Yup.string()
                            .trim()
                            .required('Site label is required.')
                            .min(3, 'Site label must be at least 3 characters')
                            .max(20, 'Site label must be at most 20 characters')
                        })}
                        onSubmit={async (values) => {
                          const wordpressObj = {
                            access_token: values.wordpressAccessToken,
                            siteType: 'wordpress',
                            siteURL: values.siteURL,
                            username: values.username,
                            siteLabel: values.siteLabel,
                            mainUrl: getWordPressSiteLink
                          };
                          dispatch(mediumWebsiteUrl(wordpressObj, 'wordpress', setWPressForm, setWordPressSteps, navigate));
                          // dispatch(mediumWebsiteUrl(values.username, navigate));
                        }}
                      >
                        {({ handleBlur, handleChange, handleSubmit, values, touched, errors }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1.5}>
                              <Grid item xs={12} md={7}>
                                <Typography className="siteLinkText" sx={{ color: '#6631A5', fontSize: '16px', fontWeight: '500' }}>
                                  {getWordPressSiteLink}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} md={7}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="Username-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                    Add Username
                                  </InputLabel>
                                  <TextField
                                    className="searchInput"
                                    sx={{ paddingLeft: '15px' }}
                                    variant="standard"
                                    fullWidth
                                    // placeholder="Add Username"
                                    autoComplete="off"
                                    value={values.username}
                                    name="username"
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (/^\s/.test(e.target.value)) {
                                        return;
                                      } else {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="Username-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                    Add Website URL
                                  </InputLabel>
                                  <TextField
                                    className="searchInput"
                                    sx={{ paddingLeft: '15px' }}
                                    variant="standard"
                                    fullWidth
                                    // placeholder="Add Website URL"
                                    autoComplete="off"
                                    value={values.siteURL}
                                    name="siteURL"
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (/^\s/.test(e.target.value)) {
                                        return;
                                      } else {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                  {touched.siteURL && errors.siteURL && (
                                    <FormHelperText error id="helper-text-siteURL-signup">
                                      {errors.siteURL}
                                    </FormHelperText>
                                  )}
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="Username-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                    Add Access Token Website
                                  </InputLabel>
                                  <TextField
                                    className="searchInput"
                                    sx={{ paddingLeft: '15px' }}
                                    variant="standard"
                                    fullWidth
                                    // placeholder="Add Access Token Website"
                                    autoComplete="off"
                                    value={values.wordpressAccessToken}
                                    name="wordpressAccessToken"
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (/^\s/.test(e.target.value)) {
                                        return;
                                      } else {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="Username-signup" sx={{ fontSize: '16px', color: '#000000' }}>
                                    Add Site Label
                                  </InputLabel>
                                  <TextField
                                    className="searchInput"
                                    sx={{ paddingLeft: '15px' }}
                                    variant="standard"
                                    fullWidth
                                    // placeholder="Add Site Label"
                                    autoComplete="off"
                                    value={values.siteLabel}
                                    name="siteLabel"
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (/^\s/.test(e.target.value)) {
                                        return;
                                      } else {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12} sx={{ paddingTop: '8px !important', my: 1 }}>
                                <Typography
                                  variant="p"
                                  className="tokenText"
                                  // onClick={() => navigate('/wordpressTokenGenerate')}
                                  onClick={() => setOpen(true)}
                                >
                                  How to generate access token of WordPress?
                                </Typography>
                              </Grid>

                              <Grid item md={1} sm={4} lg={1} xs={12} my={1}>
                                <Button
                                  disableElevation
                                  disabled={!values.username || !values.siteURL || !values.wordpressAccessToken || !values.siteLabel}
                                  className="globalButtonSecond"
                                  sx={{
                                    borderRadius: '24px !important',
                                    backgroundColor:
                                      !values.username || !values.siteURL || !values.wordpressAccessToken || !values.siteLabel
                                        ? '#707070 !important'
                                        : '',
                                    borderColor:
                                      !values.username || !values.siteURL || !values.wordpressAccessToken || !values.siteLabel
                                        ? '#707070 !important'
                                        : '',
                                    minWidth: '123px !important'
                                  }}
                                  fullWidth
                                  size="large"
                                  type="submit"
                                  variant="outlined"
                                >
                                  Connect
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>

                      {open && <WordpressAppPassName open={open} setOpen={setOpen} handleClose={() => setOpen(false)} />}
                    </>
                  ) : (
                    <>
                      {userSites &&
                        userSites.length > 0 &&
                        wordPressSteps === '3' &&
                        userSites
                          ?.filter((site) => site.website === 'wordpress')
                          ?.map((word, ind) => (
                            <>
                              <Grid item lg={12} key={ind}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    paddingInline: '15px',
                                    mb: 2,
                                    borderRadius: '8px'
                                  }}
                                >
                                  <Typography className="mediumlistText">{word.siteLabel}</Typography>
                                  <Box
                                    onClick={() => {
                                      dispatch(deleteWebsite(word?._id, 'wordpress'));
                                      if (ind == '0') {
                                        setWordPressSteps('1');
                                        setWPressForm(true);
                                      }
                                    }}
                                    sx={{ display: 'flex' }}
                                  >
                                    <img src={DeleteIcon} alt="" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
                                  </Box>
                                </Box>
                              </Grid>
                            </>
                          ))}
                      {userSites && userSites.length > 0 && wordPressSteps === '3' && (
                        <Grid mt={3} md={3} sm={3} lg={1} xs={12}>
                          <Button
                            fullWidth
                            size="large"
                            type="button"
                            variant="outlined"
                            className="globalButtonSecond"
                            sx={{
                              borderRadius: '24px !important',
                              fontSize: '14px !important',
                              minWidth: '170px !important',
                              padding: '10px 18px !important'
                            }}
                            onClick={() => {
                              setWordPressSteps('1');
                              setWPressForm(true);
                            }}
                          >
                            Add New Website
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MediumPortal;
