import {
  CLARITY_CHECK,
  PLAGIARISM_CHECK,
  TONE_CHECK,
  FACT_CHECKER,
  SPELLING_AND_GRAMMAR_CHECK,
  ACCEPT_SUGGESTION,
  LOADING,
  GET_READING_LEVEL_SCORE
} from '../actions/Suggestions/SuggestionActions';
const initialState = {
  plagiarism_list: [],
  get_tone: [],
  get_clarity: [],
  get_fact: [],
  getSpellingAndGrammar: [],
  getAcceptSuggestion: {},
  showLoading: '',
  getReadingLevelScore: {}
};

export function SuggestionReducer(state = initialState, action) {
  if (action.type === SPELLING_AND_GRAMMAR_CHECK) {
    return {
      ...state,
      getSpellingAndGrammar: action.payload,
      showLoading: false
    };
  }
  if (action.type === PLAGIARISM_CHECK) {
    return {
      ...state,
      plagiarism_list: action.payload
    };
  }
  if (action.type === TONE_CHECK) {
    return {
      ...state,
      get_tone: action.payload
    };
  }
  if (action.type === GET_READING_LEVEL_SCORE) {
    return {
      ...state,
      getReadingLevelScore: action.payload
    };
  }
  if (action.type === CLARITY_CHECK) {
    return {
      ...state,
      get_clarity: action.payload
    };
  }
  if (action.type === FACT_CHECKER) {
    return {
      ...state,
      get_fact: action.payload
    };
  }
  if (action.type === ACCEPT_SUGGESTION) {
    return {
      ...state,
      getAcceptSuggestion: action.payload
    };
  }
  if (action.type === LOADING) {
    return {
      ...state,
      showLoading: action.payload
    };
  }
  return state;
}
