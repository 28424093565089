import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

function RepharseKeywords({ setKeyWords }) {
  const [inputText, setInputText] = useState('');
  const [chips, setChips] = useState([]);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const wordCount = event.target.value.length;
    if (/^\s/.test(event.target.value)) {
      return;
    }
    if (wordCount <= 20) {
      setInputText(event.target.value);
    } else {
      let message = {
        code: 200,
        message: 'Please enter between 3 to 20 words'
      };
      dispatch(snackbarAction(true, message));
    }
  };

  const handleKeyDown = (event) => {
    if (chips.length <= 4) {
      if (event.key === 'Enter' && inputText.trim() !== '') {
        const wordCount = inputText.length;

        if (wordCount >= 3) {
          setChips([...chips, inputText]);
          setKeyWords([...chips, inputText]);
          setInputText('');
        } else {
          let message = {
            code: 200,
            message: 'Please enter at least 3 words'
          };
          dispatch(snackbarAction(true, message));
        }
      }
    } else {
      let message = {
        code: 200,
        message: 'Only 5 keywords are allowed'
      };
      dispatch(snackbarAction(true, message));
      // Handle the message as needed, e.g., show a snackbar
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
    setKeyWords((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
  };
  const handleBlur = () => {
    let e = { key: 'Enter' };
    handleKeyDown(e);
  };
  return (
    <div>
      <TextField
        fullWidth
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur} // Add onBlur event listener
        className="repharseInput"
        InputProps={{
          startAdornment: chips.map((chip, index) => (
            <Chip
              key={index}
              sx={{
                mr: 2,
                mt: 1,
                mb: 1
              }}
              label={chip}
              onDelete={handleDeleteChip(chip)}
            />
          ))
        }}
      />
    </div>
  );
}
RepharseKeywords.propTypes = {
  setKeyWords: PropTypes.func
};
export default RepharseKeywords;
