import { SHARED_DOCUMENTS, INVITATION_DETAILS } from 'store/actions/ShareDocuments/ShareDocuments';

const initialState = {
  shareDocument: false,
  invitationDetails: {}
};
export function shareDocumentsReducer(state = initialState, action) {
  if (action.type === SHARED_DOCUMENTS) {
    return {
      ...state,
      shareDocument: action.payload
    };
  }

  if (action.type === INVITATION_DETAILS) {
    return {
      ...state,
      invitationDetails: action.payload
    };
  }

  return state;
}
