import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMyProjectList, sharedProjectList } from 'store/actions/Projects/ProjectsActions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SearchBar = ({ pathname }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { ownersId } = useSelector((state) => state.projects);

  useEffect(() => {
    if (pathname !== '/') {
      const fetchData = (searchQuery) => {
        const action = pathname === '/shared-projects' ? sharedProjectList : getMyProjectList;
        const additionalParams = pathname === '/shared-projects' ? ownersId : null;
        dispatch(action(10, 0, searchQuery, additionalParams, navigate));
      };

      if (search && search.length >= 3) {
        fetchData(search);
      } else if (search.length === 0) {
        fetchData('');
      }
    }
  }, [dispatch, navigate, pathname, search, ownersId]);

  return (
    <TextField
      onChange={(e) => setSearch(e.target.value)} // Fix the typo here
      fullWidth
      variant="outlined"
      placeholder={
        pathname === '/shared-projects' ? 'Search shared Projects' : pathname === '/' ? 'Search templates' : 'Search my Projects'
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon mt={0.5} />
          </InputAdornment>
        ),
        style: {
          borderRadius: 24,
          background: 'var(--rgb-255255255, #FFF)',
          '&:focus': {
            outline: 'none'
          }
        }
      }}
      autoComplete="off"
      InputLabelProps={{
        style: {
          color: 'var(--rgb-255255255, #FFF)'
        }
      }}
      style={{
        border: 'none',
        width: 340
      }}
      className="custom-input-field" // Add a custom class name
    />
  );
};

SearchBar.propTypes = {
  pathname: PropTypes.string
};
export default SearchBar;
