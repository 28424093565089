import { createTheme } from '@mui/material/styles';

const TextFieldDesign = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          background: '#FFF',
          border: '0.5px solid #70707080 !important',
          '&.Mui-focused': {
            boxShadow: 'none',
            border: '0.5px solid #70707080 !important'
          }
        },
        notchedOutline: {
          border: 'none'
        }
      }
    }
  }
});

export default TextFieldDesign;
