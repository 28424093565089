// project import

import Box from '@mui/material/Box';
import vertor from '../assets/images/Vector.svg';
import cloud from '../assets/images/Cloud Genie.svg';
const GenieLoader = () => {
  return (
    <>
      <Box className="loader">
        <Box className="genieLoaderOuter">
          <Box mb={-4} align="center">
            <img src={vertor} style={{ width: '60px' }} className="logoAnimationStart1" alt="cross" />
          </Box>
          <Box mb={3}>
            <img src={cloud} style={{ width: '100px' }} className="logoAnimationStart" alt="cross" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GenieLoader;
