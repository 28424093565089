import React from 'react';
import { Box, Typography, Button, Dialog, IconButton } from '@mui/material';
import deleteIcon from 'assets/designs/deleteIcon.svg';
import crossButton from 'assets/designs/crossButton.svg';
import './CommonDeleteModal.css';
import PropTypes from 'prop-types';
const CommonDeleteModal = ({ handleDelete, open, setOpen, title, description, cancelText, deleteText, icon }) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', maxWidth: '520px !important' } }}
    >
      <Box p={4}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          className="crossButton"
        >
          <img src={crossButton} alt="cross" />
        </IconButton>
        <Box>
          <Box align="center">
            <Box className="logo-container">
              <img src={icon ? icon : deleteIcon} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography my={2} align="center" className="primaryHeading">
              {title}
            </Typography>
            <Typography sx={{ my: 3 }} align="center" className="infoTextGlobal">
              {description}
            </Typography>
            <Box mt={3}>
              <Box className="btnBox" sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  sx={{ px: 5, borderRadius: '8px !important' }}
                  className="globalButtons"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  {cancelText}
                </Button>
                <Button fullWidth sx={{ px: 5 }} onClick={handleDelete} className="globalButtonSecond">
                  {deleteText}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
CommonDeleteModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  icon: PropTypes.string,
  deleteText: PropTypes.string
};
export default CommonDeleteModal;
