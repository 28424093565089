import { Box, Typography, Dialog } from '@mui/material';
import logoGradient from '../assets/images/common/genieOn1.svg';
import lampBorder from '../assets/images/common/lampBorder.svg';
import newLamp from '../assets/images/common/newLamp.svg';
import { useSelector } from 'react-redux';
const LampLoader = () => {
  const { lambLoader, contentValue } = useSelector((state) => state.auth);
  return (
    <>
      <Dialog open={lambLoader} maxWidth="xs" fullWidth={true}>
        <Box mb={5} pb={5}>
          <Box className="logo-container" mt={5} align="center">
            <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
          </Box>
          <Box mt={4} align="center">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h4" sx={{ display: 'flex' }}>
                Generating {contentValue}...
              </Typography>
              {/* <Box className="css-typing">
                <Typography>.....</Typography>
              </Box> */}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${newLamp})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: '60px'
            }}
            className=""
            mt={5}
            align="center"
          >
            <img src={lampBorder} style={{ width: '160px', marginRight: '10px' }} className="rotating-icon" alt="cross" />
          </Box>
          <Box mt={4} align="center">
            Estimated time left: 1 minute
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default LampLoader;
