import React from 'react';
import { Box, Typography } from '@mui/material';
import referal from 'assets/designs/referal.svg';
import './ComingSoon.css';
const ComingSoon = () => {
  return (
    <Box className="globalShadow mainTables">
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          <img src={referal} alt="genie" />
        </Typography>
        <Typography sx={{ fontWeight: '700 !important' }} className="primaryHeading">
          We are Coming Soon!
        </Typography>
        <Typography sx={{ mt: 5 }} className="tableCount">
          <b>Stay Tuned!</b> We're working hard to bring you the best AI-powered documents.
        </Typography>
        <Typography className="tableCount">you the best AI-powered documents.</Typography>
      </Box>
    </Box>
  );
};

export default ComingSoon;
