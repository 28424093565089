import { COMMENT_BOX, COMMENT_BOX_DATA, COMMENT_REPLY } from 'store/actions/CommentBox/CommentBox';

const initialState = {
  commentBox: false,
  commentBoxData: [],
  replyCommentBox: false
};
export function commentBoxReducer(state = initialState, action) {
  if (action.type === COMMENT_BOX) {
    return {
      ...state,
      commentBox: action.payload
    };
  }

  if (action.type === COMMENT_BOX_DATA) {
    return {
      ...state,
      commentBoxData: action.payload
    };
  }

  if (action.type === COMMENT_REPLY) {
    return {
      ...state,
      replyCommentBox: action.payload
    };
  }

  return state;
}
