import React, {
  useState,
  //  useRef,
  useEffect
} from 'react';
import { Box, Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import './AmbassadorPortal.css';
import { becomeAnAmbassador, generateProfileUrl } from 'store/actions/Ambassador/AmbassadorAction';
import { ThemeProvider } from '@mui/material/styles';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import emptyTick from '../../assets/images/ambassadorpage/emptyTick.svg';
import filledTick from '../../assets/images/ambassadorpage/fillTick.svg';
import girlImg from '../../assets/images/ambassadorpage/Rectangle.svg';

const ProfileLinkForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, user } = useSelector((state) => state.auth);
  const [checkbox, setCheckBox] = useState(false);

  useEffect(() => {
    dispatch(generateProfileUrl(navigate));
  }, [dispatch, navigate]);

  const { profileLink } = useSelector((state) => state.AmbassadorReducer?.getProfileLink);

  return (
    <>
      <Box
        sx={{
          //  pl: { xs: 0, md: 5, sm: 5, lg: 5 },
          height: '100%',
          mt: 2
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            fullname: '',
            email: '',
            instagram: user?.ambassador?.instagramLink || '',
            facebook: user?.ambassador?.facebookLink || '',
            linkedin: user?.ambassador?.linkedinLink || '',
            // message: user?.ambassador?.message || '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            instagram: Yup.string(),
            facebook: Yup.string(),
            linkedin: Yup.string()
            // message: Yup.string()
          })}
          onSubmit={async (values) => {
            const userAmbassadorData = {
              id: user?.id,
              userName: profileLink.username,
              instagramLink: values.instagram,
              facebookLink: values.facebook,
              linkedinLink: values.linkedin,
              profileLink: `${profileLink?.profileLink}${profileLink.username}`
              // imageUrl: file
            };
            dispatch(becomeAnAmbassador(userAmbassadorData, navigate));
            // const userAmbassadorData = {
            //   instagramLink: values.instagram,
            //   facebookLink: values.facebook,
            //   linkedinLink: values.linkedin
            //   // message: values.message
            // };
            // dispatch(updateAmbassadorData(userAmbassadorData, navigate));
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3} md={12} sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' } }}>
                <Grid item sx={12} sm={12} md={7}>
                  <Grid item xs={12} sm={12} md={9} mb={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="instagram-login" className="labelBold">
                        Instagram Link
                      </InputLabel>
                      <ThemeProvider theme={TextFieldDesign}>
                        <OutlinedInput
                          id="instagram-login"
                          type="text"
                          value={values.instagram}
                          name="instagram"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            bgcolor: 'rgba(112,112,112,0.1)',
                            border: 'none',
                            outline: 'none',
                            '&.MuiOutlinedInput-root': {
                              border: 'none !important'
                            }
                          }}
                          className="profileLinkInput"
                          // placeholder="Enter Instagram Link"
                          fullWidth
                          error={Boolean(touched.instagram && errors.instagram)}
                        />
                      </ThemeProvider>
                      {touched.instagram && errors.instagram && (
                        <FormHelperText error id="helper-text-instagram-signup">
                          {errors.instagram}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} mb={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="facebook-login" className="labelBold">
                        Facebook Link
                      </InputLabel>
                      <ThemeProvider theme={TextFieldDesign}>
                        <OutlinedInput
                          id="facebook-login"
                          type="text"
                          value={values.facebook}
                          name="facebook"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            bgcolor: 'rgba(112,112,112,0.1)',
                            border: 'none',
                            outline: 'none',
                            '&.MuiOutlinedInput-root': {
                              border: 'none !important'
                            }
                          }}
                          className="profileLinkInput"
                          // placeholder="Enter Facebook Link"
                          fullWidth
                          error={Boolean(touched.facebook && errors.facebook)}
                        />
                      </ThemeProvider>
                      {touched.facebook && errors.facebook && (
                        <FormHelperText error id="helper-text-facebook-signup">
                          {errors.facebook}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="linkedin-login" className="labelBold">
                        Linkedin Link
                      </InputLabel>
                      <ThemeProvider theme={TextFieldDesign}>
                        <OutlinedInput
                          id="linkedin-login"
                          type="text"
                          value={values.linkedin}
                          name="linkedin"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            bgcolor: 'rgba(112,112,112,0.1)',
                            border: 'none',
                            outline: 'none',
                            '&.MuiOutlinedInput-root': {
                              border: 'none !important'
                            }
                          }}
                          className="profileLinkInput"
                          // placeholder="Enter Linkedin Link"
                          fullWidth
                          error={Boolean(touched.linkedin && errors.linkedin)}
                        />
                      </ThemeProvider>
                      {touched.linkedin && errors.linkedin && (
                        <FormHelperText error id="helper-text-linkedin-signup">
                          {errors.linkedin}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                <Grid item md={4} xs={12} sm={12} mt={4}>
                  <img src={girlImg} alt="" style={{ width: '100%' }} />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setCheckBox((prev) => !prev)}>
                      <img src={checkbox ? filledTick : emptyTick} alt="" style={{ paddingRight: '8px' }} />
                    </Box>
                    <Box>
                      <Typography
                        variant="p"
                        className="secondaryHeading"
                        sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'center', flexWrap: 'wrap', color: '#000000' }}
                      >
                        I am 18 years of age or older and agree to the terms of the
                        <Box as="a" href="https://writergenie.ai/ambassador-agreement/" target="_blank" rel="noopener noreferrer">
                          <span style={{ textDecoration: 'underline', paddingLeft: '5px', cursor: 'pointer', color: '#000000' }}>
                            WriterGenie Ambassador Agreement.
                          </span>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: { xs: 2, sm: 0, md: 4 }, textAlign: 'center' }}>
                  <Button
                    disableElevation
                    sx={{
                      background: !checkbox ? 'rgb(112,112,112) !important' : '#6631A5',
                      border: !checkbox ? '1px solid rgb(112,112,112) !important' : '1px solid #6631A5'
                    }}
                    disabled={!checkbox}
                    size="large"
                    type="submit"
                    className="globalButtonSecond"
                  >
                    {showLoading !== 'becomeAmbassador' ? 'Submit Application' : <CircularProgress size={25} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ProfileLinkForm;
