import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import Drawer from './Drawer';
import Header from './Header';
import { openDrawer } from 'store/actions/Menu/Menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer(!open));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer(!matchDownLG));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  const { pathname } = useLocation();
  const routeName = pathname.split('/')[1];
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { lg: 2, md: 2, sm: 1, xs: 0 } }}>
        {routeName !== 'project' && <Header open={open} handleDrawerToggle={handleDrawerToggle} />}
        <Box
          sx={{
            mt:
              pathname === '/seo-tools' ||
              pathname === '/profile' ||
              pathname === '/ambassador-portal' ||
              pathname === '/medium' ||
              pathname === '/seo-tools/searchhistory'
                ? '85px'
                : '200px',
            mx: 1.5
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
