import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
// import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from 'config';
import { useSelector } from 'react-redux';
import backArrow from 'assets/designs/rightBack.svg';
import leftBack from 'assets/designs/leftBack.svg';
// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const { drawerOpen } = useSelector((state) => state.menu);

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent open={drawerOpen} />, [drawerOpen]);
  // const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        zIndex: 1300,
        ml: { lg: 1.5, md: 1.5, sm: 1, xs: 0 },
        mt: 2,
        borderRadius: '12px'
      }}
      aria-label="mailbox folders"
      position="relative"
    >
      {!matchDownMD && (
        <Box onClick={handleDrawerToggle}>
          <img
            src={!drawerOpen ? backArrow : leftBack}
            alt="backArrow"
            style={{
              position: 'fixed',
              top: 35,
              zIndex: 999999999,
              left: !drawerOpen ? 90 : 285,
              cursor: 'pointer',
              transition: 'left 250ms ease-in-out 0ms' // Smooth leftward slide
            }}
          />
        </Box>
      )}
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          {/* {drawerHeader} */}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
              flexShrink: { md: 0 },
              zIndex: 1300
            }
          }}
        >
          {/* {open && drawerHeader} */}
          {open && drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default MainDrawer;
