import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { adjustContentTone, changeTextTone, changedToneData, replaceText, saveModifiedData } from 'store/actions/GenieTools/GenieTools';
import { useDispatch, useSelector } from 'react-redux';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import { generateMenuOptions } from 'utils/ToneOptions';
import { useNavigate } from 'react-router-dom';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import './GenieToolsModal.css';

export default function AdjustTone() {
  const dispatch = useDispatch();
  const [newTone, setNewTone] = React.useState('');
  const { projectDetails } = useSelector((state) => state.projects);
  const navigate = useNavigate();

  const { adjustTone, selectedText, modifiedData, rangeSelection, quillSelection } = useSelector((state) => state.genieTools);

  const handleClose = () => {
    setNewTone('');
    dispatch(adjustContentTone(!adjustTone));
    dispatch(changedToneData({}));
    dispatch(saveModifiedData({}));
  };
  const toneChange = (e) => {
    dispatch(saveModifiedData({}));
    setNewTone(e.target.value);
  };

  const generateText = () => {
    if (newTone === '') {
      let message = {
        code: 400,
        message: 'Please select tone'
      };
      dispatch(snackbarAction(true, message));
      return;
    }
    if (modifiedData?.modifiedText) {
      dispatch(replaceText(quillSelection, rangeSelection, modifiedData.modifiedText));
      dispatch(adjustContentTone(!adjustTone));
      dispatch(saveModifiedData({}));
    } else {
      dispatch(changeTextTone(projectDetails[0]?._id, { selectedText: selectedText, tone: newTone }, navigate));
    }
  };

  return (
    <Box>
      <Dialog
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={adjustTone}
        sx={{ p: { xs: 1, sm: 1, md: 2, lg: 2 } }}
      >
        <DialogContent>
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography sx={{ mb: 3 }} align="center" variant="h3">
              Adjust Tone
            </Typography>
          </Box>
          <Grid container className="readingLevelOuter" sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <FormControl sx={{ ml: 5 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={newTone}
                  onChange={toneChange}
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {generateMenuOptions().map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            color: '#6631A5',
                            pl: 0,
                            '&.Mui-checked': {
                              color: '#6631A5'
                            }
                          }}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Box className="contentToneBox" sx={{ overflowY: 'auto' }}>
                {selectedText}
              </Box>
              <Box sx={{ background: '#fff !important', mt: 2, overflowY: 'auto' }} className="contentToneBox">
                {modifiedData?.modifiedText}
              </Box>
              <Box mt={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                  <Button onClick={handleClose} variant="outlined" className="globalButtons">
                    Cancel
                  </Button>
                  <Button onClick={generateText} className="globalButtonSecond">
                    {modifiedData?.modifiedText ? 'Rewrite Content' : 'Generate'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
