export function subscriptionValidation(currentPlanwordsTeammates, user, isTeammateComponent) {
  const specialAccess = currentPlanwordsTeammates?.special_access;
  const userSpecialAccess = user?.ambassador?.specialAccess;
  if (isTeammateComponent && Object.keys(currentPlanwordsTeammates).length > 0 && currentPlanwordsTeammates?.planType === 'business') {
    return true;
  }

  if (isTeammateComponent && (currentPlanwordsTeammates?.planType === 'pro' || userSpecialAccess === true)) {
    return false;
  }
  if (specialAccess === true) {
    return true;
  } else if (userSpecialAccess === true) {
    return true;
  } else if (Object.keys(currentPlanwordsTeammates).length > 0) {
    return true;
  } else {
    return false;
  }
}
