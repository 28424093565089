import { authApi } from './AxiosInstance';

export function recentProjects() {
  return authApi.get('/project/recent');
}
export function myProjects(limit, offset, search) {
  return authApi.get(`project?limit=${limit}&offset=${offset}&search=${search}`);
}
export function myTransactionLists() {
  return authApi.get('/subscriptionPlans/transaction');
}
export function getSharedProjectOwnersList() {
  return authApi.get('/project/owner/list');
}
export function getSharedProjects(limit, offset, search, owner) {
  return authApi.get(`/project/shared?limit=${limit}&offset=${offset}&ownerId=${owner}&search=${search}`);
}
export function projectDetails() {
  return authApi.post('/project');
}
export function DeleteProject(id) {
  return authApi.delete(`/project/${id}`);
}
export function getDeleteProjectsList(limit, offset) {
  return authApi.get(`/project/deleted?limit=${limit}&offset=${offset}`);
}
export function deletePermanentProject(id) {
  return authApi.delete(`/project/${id}/delete`);
}
export function getRestoreProject(id) {
  return authApi.get(`/project/${id}/recover`);
}
export function projectStatus(status, id) {
  return authApi.post(`/project/${id}/status`, status);
}
export function getProjectDetails(id) {
  return authApi.get(`/project/${id}`);
}
export function saveProject(content, id) {
  return authApi.post(`/project/${id}/title`, content);
}
export function getNewTitle(id) {
  return authApi.get(`/project/${id}/title`);
}
export function saveProjectOutline(content, id) {
  return authApi.post(`/project/${id}/outline`, content);
}
export function saveProjectSubOutline(outlines, id) {
  return authApi.post(`/project/${id}/suboutline`, outlines);
}
export function changeDocTitle(name, id) {
  return authApi.put(`/project/${id}`, name);
}
export function postSaveFinalContent(content, id) {
  return authApi.post(`/project/${id}/save`, content);
}
export function saveProjectAllData(id, content) {
  return authApi.post(`/project/${id}/content`, content);
}
export function getNewOneTitle(id, titleId) {
  return authApi.get(`/project/${id}/title/${titleId}`);
}
export function getNewOneOutline(id, outlined) {
  return authApi.get(`/project/${id}/outline/${outlined}`);
}
export function getNewAllOutlines(id) {
  return authApi.get(`/project/${id}/outline`);
}
export function getNewAllSubOutlines(id, outlined) {
  return authApi.get(`/project/${id}/outline/${outlined}/subOutline`);
}
export function getNewOneSubOutline(id, subOutlined) {
  return authApi.get(`/project/${id}/subOutline/${subOutlined}`);
}
export function deleteTitle(id, titleId) {
  return authApi.delete(`/project/${id}/title/${titleId}`);
}
export function deleteOutline(id, outlined) {
  return authApi.delete(`/project/${id}/outline/${outlined}`);
}
export function deleteSubOutline(id, subOutlined) {
  return authApi.delete(`/project/${id}/subOutline/${subOutlined}`);
}
export function generateContent(id, subOutlines) {
  return authApi.post(`/project/${id}/content`, subOutlines);
}
export function lockProject(id, content) {
  return authApi.post(`/project/${id}/lock`, content);
}

export function restoreAllProjects() {
  return authApi.patch(`/project/restore`);
}
