import {
  shareDocuments,
  sharedDocumentPermission,
  transferOwner,
  transferOwnerDetails,
  transferOwnerEmail
} from 'services/ShareDocumentService';
import { Logout, genieLoaderAction, loaderAction, snackbarAction } from '../Authentication/AuthActions';
import { getProject, handleErrors } from '../Projects/ProjectsActions';
export const SHARED_DOCUMENTS = '[shared documents] user shared documents';
export const INVITATION_DETAILS = '[invitation details] user invitation details';

export function shareSingleDocument(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    shareDocuments(id, content)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(getProject(id, navigate));
        let message = {
          code: 200,
          message: 'Access updated'
        };
        dispatch(snackbarAction(true, message));
        dispatch(openShareDocument(false));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
        console.log(error, 'error');
        dispatch(genieLoaderAction(false));
      });
  };
}

export function transferOwnerEmailAction(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    transferOwnerEmail(id, content)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(genieLoaderAction(false));
        let message = {
          code: 200,
          message: content.status === 'rejected' ? 'Invitation declined successfully' : 'You are now the owner of this project'
        };
        dispatch(snackbarAction(true, message));
        dispatch(openShareDocument(false));
        navigate('/');
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
        console.log(error, 'error');
        dispatch(genieLoaderAction(false));
      });
  };
}

export function sharedDocumentPermissionAction(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    sharedDocumentPermission(id, content)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(getProject(id, navigate));
        let message = {
          code: 200,
          message: 'Access updated'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
        console.log(error, 'error');
        dispatch(genieLoaderAction(false));
      });
  };
}

export function transferOwnerDetailsAction(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    transferOwnerDetails(id)
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(invitationDetails(response?.data));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
        console.log(error, 'error');
        dispatch(genieLoaderAction(false));
      });
  };
}

export function transferOwnerAction(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    transferOwner(id, content)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(genieLoaderAction(false));
        dispatch(getProject(id, navigate));
        let message = {
          code: 200,
          message: 'Ownership request sent successfully.'
        };
        // navigate('/');
        dispatch(snackbarAction(true, message));
        dispatch(openShareDocument(false));
      })
      .catch((error) => {
        dispatch(loaderAction(''));
        dispatch(openShareDocument(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function openShareDocument(data) {
  return {
    type: SHARED_DOCUMENTS,
    payload: data
  };
}

export function invitationDetails(data) {
  return {
    type: INVITATION_DETAILS,
    payload: data
  };
}
