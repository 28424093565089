/* eslint-disable no-unused-vars */
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// eslint-disable-next-line react/prop-types
const CustomizedSnackbars = ({ open, message, type }) => {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    let message = {
      code: 400,
      message: ''
    };
    if (reason === 'clickaway') {
      return;
    }
    dispatch(snackbarAction(false, message));
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      sx={{ borderRadius: '30px' }}
    >
      <Alert
        sx={{
          width: '100%',
          color: '#FFF',
          backgroundColor: type, // Replace with your preferred background color
          fontSize: '14px',
          borderRadius: '30px !important'
        }}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
export default CustomizedSnackbars;
