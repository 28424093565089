import React, { useEffect, useState } from 'react';
import { Box, Typography, TableContainer, Table, Grid, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { myTransactionList } from 'store/actions/Subscription/SubscriptionActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DateFormatter from '../../utils/DateFormatter';
import PaginationComp from 'components/Pagination/Pagination';
import './TransactionHistory.css';
const TransactionTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const { myTransactionsLists, transactionsListCount } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(myTransactionList(limit, offset, navigate));
  }, [dispatch, navigate, offset, currentPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
  };

  return (
    <TableContainer component={Box} className="customTable">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid #CED4DA' }}>
                <TableCell className="headersText">Plan</TableCell>
                <TableCell className="headersText">Amount</TableCell>
                {/* <TableCell className="headerText">Teammates</TableCell> */}
                <TableCell className="headersText">Last Updated</TableCell>
                <TableCell className="headersText" align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myTransactionsLists && myTransactionsLists?.length === 0 ? (
                <TableRow sx={{ borderBottom: 'none !important', borderColor: 'none !important' }}>
                  <TableCell colSpan={12} align="center">
                    <Typography variant="h3" mt={10}>
                      No Records Found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                myTransactionsLists?.map((transactionList) => {
                  const planType = transactionList?.planId?.name;
                  let output;
                  if (planType) {
                    const type = planType.split('-');
                    output = type[0];
                  }
                  return (
                    <TableRow key={transactionList._id} sx={{ borderBottom: '1px solid #CED4DA', borderColor: 'none' }}>
                      <TableCell sx={{ textTransform: 'capitalize', paddingBlock: '29px' }}>{output}</TableCell>
                      <TableCell className="bottomborder">${transactionList?.amount}</TableCell>

                      {/* <TableCell className="bottomborder">{transactionList.teammate}</TableCell> */}
                      <TableCell className="headersRow">
                        <DateFormatter date={transactionList.updatedAt} />
                      </TableCell>
                      <TableCell className="bottomborder" align="center" sx={{ textTransform: 'capitalize' }}>
                        {transactionList.status}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          {transactionsListCount > 10 && (
            <PaginationComp count={Math.ceil(transactionsListCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
          )}
        </Grid>
      </Grid>
    </TableContainer>
  );
};

export default TransactionTable;
