import { lazy } from 'react';
import Loadable from 'components/Loadable';
import AmbassadorLayout from 'layout/AmbassadorLayout/index';

// render - login
const BrandAmbassador = Loadable(lazy(() => import('pages/AmbassadorBrand/AmbassadorPage')));

const BrandAmbassadorRoute = {
  path: 'brand',
  element: <AmbassadorLayout />,
  children: [
    {
      path: 'ambassador',
      element: <BrandAmbassador />
    }
  ]
};

export default BrandAmbassadorRoute;
