import { authApi } from './AxiosInstance';

export function createTemplate(title, topic, audience) {
  const template = {
    title,
    topic,
    audience
  };
  return authApi.post(`/templates`, template);
}

export function getTemplates() {
  return authApi.get(`/templates`);
}
