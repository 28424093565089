import {
  All_SUBSCRIPTION_PLANS,
  USER_SUBSCRIPTION_LIST,
  SINGEL_USER_ACTIVE_PLAN,
  MY_TRANSACTION_LIST,
  UPGRADE_SUBSCRIPTION_MODAL,
  All_SUBSCRIPTION_PLANS_PRO
} from '../actions/Subscription/SubscriptionActions';

const initialState = {
  subscriptionPlans: [],
  subscriptionPlansPro: [],
  userSubscriptionList: [],
  myTransactionsLists: [],
  currentPlanwordsTeammates: [],
  upGradeSubscirptionModal: false,
  status: '',
  userSubscriptionListCount: 0,
  transactionsListCount: 0
};

export function SubscriptionReducer(state = initialState, action) {
  if (action.type === SINGEL_USER_ACTIVE_PLAN) {
    return {
      ...state,
      currentPlanwordsTeammates: action.payload
    };
  }
  if (action.type === UPGRADE_SUBSCRIPTION_MODAL) {
    return {
      ...state,
      upGradeSubscirptionModal: action.payload
    };
  }
  if (action.type === MY_TRANSACTION_LIST) {
    return {
      ...state,
      myTransactionsLists: action.payload,
      transactionsListCount: action.count
    };
  }
  if (action.type === All_SUBSCRIPTION_PLANS) {
    return {
      ...state,
      subscriptionPlans: action.payload
    };
  }
  if (action.type === All_SUBSCRIPTION_PLANS_PRO) {
    return {
      ...state,
      subscriptionPlansPro: action.payload
    };
  }
  if (action.type === USER_SUBSCRIPTION_LIST) {
    return {
      ...state,
      userSubscriptionList: action.payload,
      userSubscriptionListCount: action.count
    };
  }
  return state;
}
