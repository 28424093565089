/* eslint-disable no-unused-vars */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  genieToolDetails,
  openToolsModal,
  shortenText,
  expandText,
  rephraseText,
  saveModifiedData,
  replaceText
} from 'store/actions/GenieTools/GenieTools';
import { useDispatch, useSelector } from 'react-redux';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import RepharseKeywords from './RepharseKeywords';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import './GenieToolsModal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    // paddingTop: theme.spacing(5)
  },
  '& .MuiDialogActions-root': {
    // padding: theme.spacing(1)
  }
}));

export default function ToolsGenie() {
  const {
    toolsModal,
    getGenieToolDetails,
    selectedText,
    modifiedData,
    selectedTextWordCount,
    getStatusCode,
    quillSelection,
    rangeSelection
  } = useSelector((state) => state.genieTools);
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = React.useState(0);
  const [keyword, setKeyword] = React.useState('');
  const { projectDetails } = useSelector((state) => state.projects);
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(openToolsModal(!toolsModal));
    // setSliderValue(100);
    dispatch(saveModifiedData({}));
    setKeyword('');
  };
  const slider = (event, newValue) => {
    setSliderValue(newValue);
    dispatch(saveModifiedData({}));
  };
  const generateText = () => {
    if (modifiedData?.modifiedText) {
      dispatch(replaceText(quillSelection, rangeSelection, modifiedData.modifiedText));
      dispatch(openToolsModal(!toolsModal));
      setKeyword('');
    } else {
      if (getGenieToolDetails?.title === 'Shorten') {
        dispatch(shortenText(projectDetails[0]?._id, { selectedText: selectedText, percentage: sliderValue.toString() }, navigate));
        if (getStatusCode === 200) {
          // setSliderValue(100);
        }
      }
      if (getGenieToolDetails?.title === 'Expand') {
        dispatch(expandText(projectDetails[0]?._id, { selectedText: selectedText, words: sliderValue.toString() }, navigate));
        if (getStatusCode === 200) {
          // setSliderValue(100);
        }
      }

      if (getGenieToolDetails?.title === 'Rephrase') {
        if (keyword.length === 0) {
          let message = {
            code: 400,
            message: 'Please add at least one keyword'
          };
          dispatch(snackbarAction(true, message));
        } else {
          dispatch(rephraseText(projectDetails[0]?._id, { selectedText: selectedText, keywords: keyword }, navigate));
        }
      }
    }
  };

  //create user effect and set slider value to 100 if title is shorten set 0 if tilte is expand and call it every render
  React.useEffect(() => {
    if (getGenieToolDetails?.title === 'Shorten') {
      setSliderValue(100);
    }
    if (getGenieToolDetails?.title === 'Expand') {
      setSliderValue(0);
    }
  }, [getGenieToolDetails]);

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={toolsModal}
        sx={{ p: { xs: 1, sm: 1, md: 2, lg: 2 } }}
      >
        <DialogContent>
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography sx={{ mb: 3 }} align="center" variant="h3">
              {getGenieToolDetails?.title}
            </Typography>
          </Box>
          <Grid container className="readingLevelOuter" sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography ml={2} mb={1} variant="subtitle2" className="contentText">
                {modifiedData?.modifiedText ? 'Preview Text *' : 'Content *'}
              </Typography>
              <Box className="contentBox" sx={{ overflow: 'auto' }}>
                {modifiedData?.modifiedText ? modifiedData?.modifiedText : selectedText}
              </Box>
              <Typography mb={1} mt={2} variant="subtitle2" align="right" className="contentText">
                Word count:{' '}
                {modifiedData?.modifiedText ? modifiedData?.modifiedText.trim().split(/\s+/).filter(Boolean).length : selectedTextWordCount}
              </Typography>
              {getGenieToolDetails?.title !== 'Rephrase' && (
                <Typography ml={2} variant="subtitle2" className="contentText">
                  Choose number of words
                </Typography>
              )}

              <Box mt={3}>
                {getGenieToolDetails?.title === 'Rephrase' && (
                  <Box className="globalAlignment">
                    <Typography ml={2} mb={2} variant="subtitle2" className="contentText">
                      Keywords
                    </Typography>
                    <Typography mr={2} mb={2} variant="subtitle2" className="contentText">
                      {keyword.length}
                      /5
                    </Typography>
                  </Box>
                )}
                {getGenieToolDetails?.title !== 'Rephrase' && (
                  <Slider
                    value={sliderValue}
                    onChange={slider}
                    className={getGenieToolDetails?.title === 'Shorten' ? 'genieToolsShorten' : 'genieTools'}
                    track="inverted"
                    min={
                      getGenieToolDetails?.title === 'Shorten'
                        ? parseInt(Math.floor(selectedTextWordCount / 10), 10)
                        : selectedTextWordCount
                    }
                    max={
                      getGenieToolDetails?.title === 'Shorten'
                        ? parseInt(Math.floor(selectedTextWordCount), 10)
                        : parseInt(Math.floor(selectedTextWordCount * 2), 10)
                    }
                    valueLabelDisplay="auto"
                  />
                )}
                {getGenieToolDetails?.title === 'Shorten' ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2" className="contentText">
                      {`${Math.floor(selectedTextWordCount / 10)}`}
                    </Typography>
                    <Typography variant="subtitle2" align="right" className="contentText shortenSlider">
                      {`${
                        Math.floor((selectedTextWordCount - Math.floor(selectedTextWordCount / 10)) / 2) +
                        Math.floor(selectedTextWordCount / 10)
                      }`}
                    </Typography>
                    <Typography variant="subtitle2" className="contentText">
                      {selectedTextWordCount}
                    </Typography>
                  </Box>
                ) : (
                  getGenieToolDetails?.title === 'Expand' && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle2" className="contentText">
                        {selectedTextWordCount}
                      </Typography>
                      <Typography variant="subtitle2" align="right" className="contentText shortenSlider">
                        {`${Math.floor(selectedTextWordCount * 1.5)}`}
                      </Typography>
                      <Typography variant="subtitle2" className="contentText">
                        {`${Math.floor(selectedTextWordCount * 2)}`}
                      </Typography>
                    </Box>
                  )
                )}
                {getGenieToolDetails?.title === 'Rephrase' && (
                  <RepharseKeywords
                    setKeyWords={(data) => {
                      setKeyword(data);
                      dispatch(saveModifiedData({}));
                    }}
                  />
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                  <Button onClick={handleClose} className="globalButtons">
                    Cancel
                  </Button>
                  <Button onClick={generateText} className="globalButtonSecond">
                    {modifiedData?.modifiedText ? 'Rewrite Content' : 'Generate'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
