import { lazy } from 'react';
import Loadable from 'components/Loadable';
import AuthGuard from 'services/AuthGuard';
import SeoLayout from 'layout/SeoLayout/index';
const SearchResults = Loadable(lazy(() => import('pages/SeoTools/SearchResults')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard auth={true}>
      <SeoLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'seo-tools/:id',
      element: <SearchResults />
    }
  ]
};

export default MainRoutes;
