import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAmbassadorUrl } from 'store/actions/Ambassador/AmbassadorAction';

const ShareUrlPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const url = location.pathname;
    const match = url.match(/\/ambassador\/(.+)/);
    const value = match ? match[1] : null;

    dispatch(getAmbassadorUrl(value, navigate));
  }, [dispatch, location.pathname, navigate]);

  return <></>;
};

export default ShareUrlPage;
