/* eslint-disable no-unused-vars */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { readingLevelModal, readingLevelText, replaceText, saveModifiedData } from 'store/actions/GenieTools/GenieTools';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import Slider from '@mui/material/Slider';

export default function ReadingLevel() {
  const dispatch = useDispatch();
  const { getReadingLevelScore } = useSelector((state) => state.suggestions);
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = React.useState(null);
  const [loader, setLoader] = React.useState(null);
  const { projectDetails } = useSelector((state) => state.projects);
  const { readingLevel, selectedText, getStatusCode, modifiedData, quillSelection, rangeSelection } = useSelector(
    (state) => state.genieTools
  );
  const handleClose = () => {
    dispatch(readingLevelModal(!readingLevel));
    // setSliderValue(100);
    dispatch(saveModifiedData({}));
  };
  const slider = (event, newValue) => {
    setSliderValue(newValue);
    dispatch(saveModifiedData({}));
  };
  const generateText = () => {
    if (modifiedData?.modifiedText) {
      dispatch(replaceText(quillSelection, rangeSelection, modifiedData.modifiedText));
      dispatch(readingLevelModal(!readingLevel));
    } else {
      dispatch(readingLevelText(projectDetails[0]?._id, { selectedText: selectedText, level: sliderValue.toString() }, navigate));
      if (getStatusCode === 200) {
        // setSliderValue(100);
      }
    }
  };
  const getReadability = (score) => {
    if (score >= 90) {
      return 'VERY EASILY UNDERSTOOD';
    } else if (score >= 80) {
      return 'EASILY UNDERSTOOD';
    } else if (score >= 70) {
      return 'FAIRLY EASILY UNDERSTOOD';
    } else if (score >= 60) {
      return 'MODERATELY UNDERSTOOD';
    } else if (score >= 50) {
      return 'FAIRLY DIFFICULT TO READ';
    } else if (score >= 30) {
      return 'DIFFICULT TO READ';
    } else {
      return 'VERY DIFFICULT TO READ';
    }
  };
  React.useEffect(() => {
    if (getReadingLevelScore) {
      setSliderValue(getReadingLevelScore.readability);
      setLoader(false);
    }
  }, [getReadingLevelScore]);
  // React.useEffect(() => {
  //   dispatch(readabilityCheck(projectDetails[0]?._id, selectedText, navigate));
  // }, [dispatch, navigate, projectDetails, selectedText]);
  return (
    <Box>
      <Dialog
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={readingLevel}
        sx={{ p: { xs: 1, sm: 1, md: 2, lg: 2 } }}
      >
        <DialogContent>
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography sx={{ mb: 3 }} align="center" variant="h3">
              Adjust Reading Level
            </Typography>
            <Grid container className="readingLevelOuter" sx={{ justifyContent: 'center' }}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Typography sx={{ fontSize: { xs: '12px', sm: '20px', md: '20px', lg: '20px' } }} variant="subtitle2">
                  Move the slider to adjust to the Flesch Reading Ease Score you wish to have.
                </Typography>
                <Box mt={3}>
                  <Slider
                    value={sliderValue === null ? 0 : sliderValue}
                    onChange={slider}
                    track="inverted"
                    aria-label="Default"
                    className="readingLevel"
                    valueLabelDisplay="auto"
                  />
                  <Box className="contentToneBox" sx={{ overflowY: 'auto', mt: 3 }}>
                    {selectedText}
                  </Box>
                  {modifiedData?.modifiedText && (
                    <Box sx={{ background: '#fff !important', mt: 2, overflowY: 'auto' }} className="contentToneBox">
                      {modifiedData?.modifiedText}
                    </Box>
                  )}
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', sm: '18px', md: '18px', lg: '18px' },
                      mt: { xs: 2, sm: 5, md: 5, lg: 5 },
                      mb: { xs: 2, sm: 5, md: 5, lg: 5 }
                    }}
                    variant="subtitle2"
                  >
                    Flesch Reading Easing Score: {getReadability(sliderValue)}
                  </Typography>
                  <Box sx={{ display: { xs: 'inline-grid', sm: 'flex', lg: 'flex' }, justifyContent: 'space-evenly', mt: 2, mb: 3 }}>
                    <Button onClick={handleClose} className="globalButtons">
                      Cancel
                    </Button>
                    <Button
                      disabled={getReadingLevelScore?.readability === sliderValue ? true : false}
                      onClick={generateText}
                      className="globalButtonSecond"
                    >
                      {modifiedData?.modifiedText ? 'Rewrite Content' : 'Generate'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
