import React, { useEffect } from 'react';
import { Box, Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import './ambassador.css';
// const apiUrl = process.env.REACT_APP_API_URL;.

import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import { ThemeProvider } from '@mui/material/styles';
import { becomeAnAmbassador, generateProfileUrl } from 'store/actions/Ambassador/AmbassadorAction';
// const apiUrl = process.env.REACT_APP_API_URL;
const Ambassador = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const domain = window.location.origin;

  useEffect(() => {
    dispatch(generateProfileUrl(navigate));
  }, [dispatch, navigate]);

  const { showLoading, user } = useSelector((state) => state.auth);
  const { profileLink } = useSelector((state) => state.AmbassadorReducer?.getProfileLink);
  const { getProfileLinkErr } = useSelector((state) => state.AmbassadorReducer);
  return (
    <>
      <Box sx={{ pl: { xs: 0, md: 5, sm: 5, lg: 5 }, height: '100%' }}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            fullname: '',
            // username: '',
            email: '',
            profile: profileLink?.username || '',
            instagram: '',
            facebook: '',
            linkedin: '',
            message: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            // firstname: Yup.string()
            //   .transform((value) => (value ? value.trim() : value))
            //   .max(255)
            //   .required('First Name is required'),
            // lastname: Yup.string()
            //   .transform((value) => (value ? value.trim() : value))
            //   .max(255)
            //   .required('Last Name is required'),
            // username: Yup.string().required('Username is required'),
            // email: Yup.string().email('Must be a valid email').required('Email is required'),
            instagram: Yup.string(),
            facebook: Yup.string(),
            linkedin: Yup.string(),
            message: Yup.string(),
            profile: Yup.string().matches(/^(?=.*[a-zA-Z0-9_])[a-zA-Z0-9_]*$/, 'Must contain only letters, numbers, and underscores')
          })}
          onSubmit={async (values) => {
            const userAmbassadorData = {
              id: user?.id,
              userName: values.profile,
              instagramLink: values.instagram,
              facebookLink: values.facebook,
              linkedinLink: values.linkedin,
              profileLink: `${profileLink?.profileLink}${values.profile}`,
              imageUrl: user?.imageUrl
            };
            dispatch(becomeAnAmbassador(userAmbassadorData, navigate));
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="fullname-signup">Full Name</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        id="fullname-login"
                        type="text"
                        value={user && `${user?.firstName} ${user && user?.lastName}`}
                        name="fullname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Full Name"
                        fullWidth
                        readonly
                      />
                    </ThemeProvider>
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastname-signup">Last Name</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        fullWidth
                        id="lastname-signup"
                        type="text"
                        value={user && user?.lastName}
                        name="lastname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Last Name"
                        inputProps={{}}
                        readonly
                      />
                    </ThemeProvider>
                  </Stack>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">Email</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        id="email-login"
                        type="text"
                        value={user && user?.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Email"
                        fullWidth
                        readonly
                      />
                    </ThemeProvider>
                  </Stack>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="instagram-login">Instagram Link</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        id="instagram-login"
                        type="text"
                        value={values.instagram}
                        name="instagram"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Instagram Link"
                        fullWidth
                        error={Boolean(touched.instagram && errors.instagram)}
                      />
                    </ThemeProvider>
                    {touched.instagram && errors.instagram && (
                      <FormHelperText error id="helper-text-instagram-signup">
                        {errors.instagram}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="facebook-login">Facebook Link</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        id="facebook-login"
                        type="text"
                        value={values.facebook}
                        name="facebook"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Facebook Link"
                        fullWidth
                        error={Boolean(touched.facebook && errors.facebook)}
                      />
                    </ThemeProvider>
                    {touched.facebook && errors.facebook && (
                      <FormHelperText error id="helper-text-facebook-signup">
                        {errors.facebook}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="linkedin-login">Linkedin Link</InputLabel>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        id="linkedin-login"
                        type="text"
                        value={values.linkedin}
                        name="linkedin"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Linkedin Link"
                        fullWidth
                        error={Boolean(touched.linkedin && errors.linkedin)}
                      />
                    </ThemeProvider>
                    {touched.linkedin && errors.linkedin && (
                      <FormHelperText error id="helper-text-linkedin-signup">
                        {errors.linkedin}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="profile-signup">Profile Url</InputLabel>
                    <Box className="profileInputs">
                      <Grid md={6}>
                        <ThemeProvider theme={TextFieldDesign}>
                          <OutlinedInput id="profile-login" type="text" value={profileLink?.profileLink} fullWidth readonly />
                        </ThemeProvider>
                      </Grid>
                      <Grid md={6}>
                        <ThemeProvider theme={TextFieldDesign}>
                          <OutlinedInput
                            id="profile-login"
                            type="text"
                            className="inputProfile"
                            value={values.profile}
                            name="profile"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter Profile Url"
                            fullWidth
                            error={Boolean(touched.profile && errors.profile)}
                          />
                        </ThemeProvider>
                        {touched.profile && errors.profile && (
                          <FormHelperText error id="helper-text-profile-signup">
                            {errors.profile}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Box>
                  </Stack>
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button disableElevation disabled={getProfileLinkErr === 400} size="large" type="submit" className="globalButtonSecond">
                    {showLoading !== 'becomeAmbassador' ? 'Submit' : <CircularProgress size={25} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Ambassador;
