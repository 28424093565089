import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import img from '../../assets/images/common/test1.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useNavigate } from 'react-router-dom';
import { transferOwnerDetailsAction, transferOwnerEmailAction } from 'store/actions/ShareDocuments/ShareDocuments';
import UserProfileImage from 'pages/Profileimage/UserProfileImage';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { subscriptionValidation } from 'utils/SubscriptionValidation';
import './Invitations.css';
const TransferProjectInvitation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoading, user } = useSelector((state) => state.auth);
  const { invitationDetails } = useSelector((state) => state.shareDocuments);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  useEffect(() => {
    dispatch(transferOwnerDetailsAction(id, navigate));
  }, [dispatch, id, navigate]);

  const handleAcceptInvitation = (status) => {
    if (!subscriptionValidation(currentPlanwordsTeammates, user)) {
      dispatch(subscriptionPlanModalOpen(true));
    } else {
      dispatch(transferOwnerEmailAction(id, { status: status }, navigate));
    }
  };

  const handleDeclineInvitation = (status) => {
    dispatch(transferOwnerEmailAction(id, { status: status }, navigate));
  };

  return (
    <>
      {invitationDetails?.transfer_by ? (
        <Box>
          <Box className="logo-container" align="center">
            <img src={img} className="logo testImg" alt="cross" />
          </Box>
          <Box align="center" mt={-5}>
            <Box className="commonFlex">
              <Box sx={{ mr: 5 }}>
                <UserProfileImage image={invitationDetails?.transfer_by?.profilePic} />
                <Typography variant="subtitle1" align={'center'}>
                  {invitationDetails?.transfer_by?.firstName + ' ' + invitationDetails?.transfer_by?.lastName}
                </Typography>
              </Box>
              <TrendingFlatIcon sx={{ fontSize: '60px' }} mb={3} />
              <Box sx={{ ml: 5 }}>
                <UserProfileImage image={user?.profilePic} />
                <Typography variant="subtitle1" align={'center'}>
                  {user?.firstName} {user?.lastName} (You)
                </Typography>
              </Box>
            </Box>
            <Typography variant="h5" mt={5}>
              {invitationDetails?.transfer_by?.firstName + ' ' + invitationDetails?.transfer_by?.lastName} invited you to be the owner of
              the project
            </Typography>
            <Box spacing={2} mt={5} pb={3} display="flex" justifyContent="center">
              <Button variant="contained" onClick={() => handleAcceptInvitation('accepted')} type="submit" className="acceptBtn">
                {showLoading !== 'teammateStatus' ? 'Accept Invitation' : <CircularProgress size={25} />}
              </Button>
              <Button
                disableElevation
                variant="contained"
                // onClick={() => handleAcceptInvitation('rejected')}
                onClick={() => handleDeclineInvitation('rejected')}
                sx={{
                  ml: 5
                }}
                className="declineBtn"
              >
                {showLoading !== 'teammateStatus' ? 'Decline' : <CircularProgress size={25} />}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 5, m: 5, textAlign: 'center' }}>
          <Typography variant="h2" mt={5}>
            Invitation expired
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TransferProjectInvitation;
