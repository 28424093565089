import React, { useEffect, useState } from 'react';
import AnalyticEcommerce from './AnalyticEcommerce';
import {
  Grid,
  Stack,
  Button
  // Typography, Box, Tab
} from '@mui/material';
import UserGraph from './UserGraph';
import Clients from 'pages/Clients/Clients';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getGraphData } from 'store/actions/Ambassador/AmbassadorAction';

const AmbassadorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slot, setSlot] = useState('monthly');

  useEffect(() => {
    dispatch(getGraphData(slot, navigate));
  }, [dispatch, navigate, slot]);

  const { totalClicks, totalClients, getAmbassadorGraphData } = useSelector((state) => state.AmbassadorReducer);

  return (
    <Grid spacing={4} container>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          sx={{
            mt: {
              xs: 0,
              sm: 0,
              md: getAmbassadorGraphData && getAmbassadorGraphData.length > 0 && 4,
              lg: getAmbassadorGraphData && getAmbassadorGraphData.length > 0 && 4
            }
          }}
        >
          <Grid item lg={12} md={12} sm={6} xs={12}>
            <AnalyticEcommerce title="Total Users" count={totalClients} />
          </Grid>
          <Grid item lg={12} md={12} sm={6} xs={12}>
            <AnalyticEcommerce title="Clicks" count={totalClicks} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={9} md={9} sm={12} xs={12}>
        {getAmbassadorGraphData && getAmbassadorGraphData.length > 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" alignItems="center" spacing={0} sx={{ justifyContent: 'flex-end' }}>
              <Button
                size="small"
                onClick={() => setSlot('monthly')}
                sx={{
                  color: slot === 'monthly' ? '#6631A5' : '#000000',
                  borderColor: slot === 'monthly' ? '#6631A5' : '',
                  '&:hover': {
                    borderColor: '#6631A5'
                  }
                }}
                // color={slot === 'monthly' ? 'primary' : 'secondary'}
                variant={slot === 'monthly' ? 'outlined' : 'text'}
              >
                Month
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('yearly')}
                sx={{
                  color: slot === 'yearly' ? '#6631A5' : '#000000',
                  borderColor: slot === 'yearly' ? '#6631A5' : '',
                  '&:hover': {
                    borderColor: '#6631A5'
                  }
                }}
                variant={slot === 'yearly' ? 'outlined' : 'text'}
              >
                Year
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            background:
              'linear-gradient(99deg, rgba(207,207,239,1) 1%, rgba(214,202,245,1) 2%, rgba(194,208,250,1) 99%, rgba(191,207,255,1) 99%)',
            borderRadius: '16px',
            mt: 2
          }}
          className="userGraph"
        >
          <UserGraph slot={slot} />
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Clients />
      </Grid>
    </Grid>
  );
};

export default AmbassadorDashboard;
