import { authApi } from './AxiosInstance';

export function genieShorten(id, content) {
  return authApi.post(`/project/${id}/shorten`, content);
}

export function genieExpand(id, content) {
  return authApi.post(`/project/${id}/expand`, content);
}

export function genieRephrase(id, content) {
  return authApi.post(`/project/${id}/rephrase`, content);
}
export function genieReadingLevel(id, content) {
  return authApi.post(`/project/${id}/reading-level`, content);
}
export function genieChangeTone(id, content) {
  return authApi.post(`/project/${id}/adjust-tone`, content);
}
