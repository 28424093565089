import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
const EllipsisTooltip = ({ text, maxWidth, ml, variant, cursor, className, color, maxwidth }) => {
  const boxRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const boxElement = boxRef.current;
    if (boxElement) {
      setIsOverflowing(boxElement.scrollWidth > boxElement.clientWidth);
    }
  }, [text, maxWidth]);

  const getMaxWidth = (breakpoint) => {
    if (typeof maxWidth === 'object' && maxWidth[breakpoint]) {
      return maxWidth[breakpoint];
    } else if (typeof maxWidth === 'string') {
      return maxWidth;
    }
    // return '100%'; // default to 100% if no maxWidth is provided for the breakpoint
  };

  const getMarginLeft = (breakpoint) => {
    if (typeof ml === 'object' && ml[breakpoint]) {
      return ml[breakpoint];
    } else if (typeof ml === 'number') {
      return ml;
    }
    // return '100%'; // default to 100% if no maxWidth is provided for the breakpoint
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: color,
        cursor: cursor,
        maxWidth: {
          xs: getMaxWidth('xs'),
          sm: getMaxWidth('sm'),
          md: getMaxWidth('md'),
          lg: getMaxWidth('lg'),
          xl: getMaxWidth('xl')
        },
        ml: {
          xs: getMarginLeft('xs'),
          sm: getMarginLeft('sm'),
          md: getMarginLeft('md'),
          lg: getMarginLeft('lg'),
          xl: getMarginLeft('xl')
        }
      }}
    >
      {isOverflowing ? (
        <Tooltip title={<Typography style={{ fontSize: '10px' }}>{text}</Typography>} placement="bottom-start">
          <Typography className={className} variant={variant} sx={{ maxWidth: maxwidth }}>
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={className} variant={variant} sx={{ maxWidth: maxwidth }}>
          {text}
        </Typography>
      )}
    </Box>
  );
};

EllipsisTooltip.propTypes = {
  text: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  variant: PropTypes.string,
  cursor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  maxwidth: PropTypes.string
};
export default EllipsisTooltip;
