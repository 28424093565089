import React, { useState, useEffect, useRef } from 'react';

const AutoSaveComponent = () => {
  const [text, setText] = useState('');
  const saveTimeoutRef = useRef(null);

  useEffect(() => {
    const saveData = () => {
      // Implement your saving logic here
    };

    const debounce = (func, delay) => {
      return (...args) => {
        clearTimeout(saveTimeoutRef.current);
        saveTimeoutRef.current = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const delayedSave = debounce(saveData, 5000); // Adjust debounce delay as needed

    const handleInputChange = (event) => {
      const newText = event.target.value;
      setText(newText);
      delayedSave();
    };

    const cleanup = () => {
      clearTimeout(saveTimeoutRef.current); // Clear the timeout when unmounting
    };

    document.addEventListener('input', handleInputChange);

    return () => {
      document.removeEventListener('input', handleInputChange);
      cleanup();
    };
  }, [text]);

  return <textarea value={text} onChange={(event) => setText(event.target.value)} placeholder="Type something..." />;
};

export default AutoSaveComponent;
