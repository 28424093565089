import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const Thankyou = Loadable(lazy(() => import('pages/authentication/Thankyou/Thankyou')));
const Cancel = Loadable(lazy(() => import('pages/authentication/Cancel/Cancel')));

const SubscriptionRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'success',
      element: <Thankyou />
    },
    {
      path: 'cancel',
      element: <Cancel />
    }
  ]
};

export default SubscriptionRoutes;
