import { authApi } from './AxiosInstance';

export function sendInvitationsToTeammates(emails) {
  return authApi.post('/teammate/invite/email', emails);
}
export function teamMateDetails(code) {
  return authApi.get(`teammate/invitation/${code}`);
}
export function updateTeammateStatus(content) {
  return authApi.post(`teammate/invite`, content);
}
export function getTeamMatesList(limit, offset, role) {
  return authApi.get(`teammate/list?limit=${limit}&offset=${offset}&role=${role}`);
}
export function changeTeammateRole(teammateId, content) {
  return authApi.post(`teammate/${teammateId}/change-role`, content);
}
export function reSendInvitation(content) {
  return authApi.post(`teammate/re-send/invitation`, content);
}
