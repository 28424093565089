/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Dialog, Grid, OutlinedInput, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { inviteTeammates, teamMatesModal } from 'store/actions/Teammates/TeammatesActions';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import { allSubsPlans, subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import './Teammates.css';
function InviteTeamMates() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { teammateModal } = useSelector((state) => state.teammates);
  const { subscriptionPlans } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();

  const { showLoading } = useSelector((state) => state.auth);
  const [dynamicFields, setDynamicFields] = useState([{ email: '' }]);

  const handleAddDynamicField = () => {
    const allFieldsValid = dynamicFields.every((field) => {
      const fieldSchema = Yup.string().email('Invalid email address').required('Email is required');
      try {
        fieldSchema.validateSync(field.email);
        return true;
      } catch (error) {
        return false;
      }
    });

    if (allFieldsValid && dynamicFields.length < 5) {
      setDynamicFields([...dynamicFields, { email: '' }]);
    } else {
      let message = {
        code: 204,
        message: dynamicFields.length >= 5 ? 'Maximum limit reached' : 'Please fill the previous field with a valid email'
      };
      dispatch(snackbarAction(true, message));
    }
  };

  const handleRemoveDynamicField = () => {
    const updatedFields = [...dynamicFields];
    updatedFields.pop();
    setDynamicFields(updatedFields);
  };

  const dynamicFieldSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const validateDynamicFields = async () => {
    try {
      const validationPromises = dynamicFields.map((field) => dynamicFieldSchema.validate(field, { abortEarly: false }));
      await Promise.all(validationPromises);
      return {};
    } catch (validationErrors) {
      const errors = {};
      validationErrors.inner.forEach((error) => {
        errors.email = error.message;
      });
      return errors;
    }
  };
  const handleDynamicFieldChange = (index, newValue) => {
    setDynamicFields((prevDynamicFields) => prevDynamicFields.map((field, i) => (i === index ? { ...field, email: newValue } : field)));
  };
  const dynamicFieldSchemaCheck = dynamicFields.reduce((acc, _, index) => {
    acc[`email${index}`] = Yup.string()
      .email('Invalid email address')
      .required('Email is required')

      .test(`uniqueEmail${index}`, 'Email already exists in the list', function (value) {
        const isExisting = dynamicFields.some((field, i) => i !== index && field.email === value);
        if (isExisting) {
          return false;
        }
        return true;
      })
      .test(`matchesUserEmail${index}`, 'You cant invite yourself', function (value) {
        if (value === user.email) {
          return false;
        }
        const isMatching = dynamicFields.some((field, i) => i !== index && field.email === value);
        if (isMatching) {
          return false;
        }
        return true;
      });
    return acc;
  }, {});
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        dispatch(teamMatesModal(false));
      }}
      open={teammateModal}
    >
      <Box p={5}>
        <Typography id="modal-modal-title" variant="h4" align="center" component="h2">
          Invite your team
        </Typography>
        <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
          Bring your team to collaborate and unlock the full power of WriterGenie together!
        </Typography>
        {currentPlanwordsTeammates?.status === 'cancelled' && (
          <Typography mt={3} variant="body1" align="center" color="red">
            Warning: Your subscription will be resumed as planned
          </Typography>
        )}
        <Formik
          initialValues={{
            ...dynamicFields.reduce((acc, field, index) => {
              acc[`email${index}`] = field.email;
              return acc;
            }, {})
          }}
          validationSchema={Yup.object().shape(dynamicFieldSchemaCheck)}
          validate={async (values) => {
            const dynamicFieldErrors = await validateDynamicFields(values);
            return {
              ...dynamicFieldErrors
            };
          }}
          onSubmit={async (values) => {
            const collectedEmails = dynamicFields.map((_, index) => values[`email${index}`]);
            let invitations = {
              emails: collectedEmails
            };
            dispatch(allSubsPlans(invitations, navigate));
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3} sx={{ width: '100%', m: 0 }}>
                <Grid xs={12} sx={{ mt: 2 }}>
                  {dynamicFields.map((field, index) => (
                    <Box key={field.id} sx={{ mt: 3 }}>
                      <ThemeProvider theme={TextFieldDesign}>
                        <OutlinedInput
                          fullWidth
                          id={`email${index}`}
                          name={`email${index}`}
                          value={field.email}
                          onChange={(e) => {
                            handleChange(e);
                            handleDynamicFieldChange(index, e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={touched[`email${index}`] && Boolean(errors[`email${index}`])}
                          label="Email"
                          placeholder="Enter Email Address"
                        />
                      </ThemeProvider>
                      {touched[`email${index}`] && errors[`email${index}`] && (
                        <FormHelperText error>{errors[`email${index}`]}</FormHelperText>
                      )}
                      <Box
                        className="addBox"
                        sx={{
                          mt: 0.5
                        }}
                      >
                        {dynamicFields?.length > 1 && index === dynamicFields?.length - 1 && (
                          <Box>
                            <Typography
                              variant="h6"
                              onClick={() => handleRemoveDynamicField(field.id)}
                              className="primaryColor removeTeammates"
                              sx={{ mt: 0.5 }}
                            >
                              <RemoveCircleOutlineIcon sx={{ mr: 1 }} color="error" /> Remove
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Grid>

                {errors.submit && (
                  <Grid xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}

                <Box
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '600',
                    cursor: 'pointer',
                    width: '100%',
                    justifyContent: 'center',
                    mt: 4
                  }}
                  onClick={handleAddDynamicField}
                  className="primaryColor"
                >
                  <AddIcon sx={{ color: 'inherit' }} /> Add Teammates
                </Box>
                <Grid xs={12} sx={{ mb: 5, mt: 5, textAlign: 'center' }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ px: 5, height: '54px', fontWeight: '600' }}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="globalButtonSecond"
                  >
                    {showLoading !== 'inviteteammate' ? 'Send Invite' : <CircularProgress size={25} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default InviteTeamMates;
