import { GET_TEMPLATES } from 'store/actions/Templates/TemplateActions';

const initialState = {
  templatesList: []
};

export function TemplatesReducer(state = initialState, action) {
  if (action.type === GET_TEMPLATES) {
    return {
      ...state,
      templatesList: action.payload
    };
  }
  return state;
}
