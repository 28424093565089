/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { CgClose } from 'react-icons/cg';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import Button from '@mui/material/Button';
import crossButton from 'assets/designs/crossButton.svg';

const GlobalDialog = ({ open, close, action }) => {
  return (
    <Dialog maxWidth="xs" fullWidth={true} aria-labelledby="customized-dialog-title" open={open} onClose={close}>
      <Box p={3}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
            className="crossButton"
          >
            <img src={crossButton} alt="cross" />
          </IconButton>
        </DialogTitle>
        <Box>
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
            <Typography align="center" className="primaryHeading">
              Are you sure?
            </Typography>
            <Typography sx={{ mb: 3 }} align="center" className="infoTextGlobal">
              In clicking Yes, you cannot undo this action.
            </Typography>
            <Box mt={3}>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2, gap: '20px' }}>
                <Button className="globalButtons" onClick={close}>
                  Cancel
                </Button>
                <Button onClick={action} className="globalButtonSecond">
                  Yes
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

GlobalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired
};

export default GlobalDialog;
