import React, { useEffect } from 'react';
import { Dialog, Box, Typography, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import { genieLoaderAction } from 'store/actions/Authentication/AuthActions';
import { useDispatch } from 'react-redux';
import crossButton from 'assets/designs/crossButton.svg';

// eslint-disable-next-line react/prop-types
const MdModal = ({ open, title, subTitle, handleClose, closeButton, children, borderRadius }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(genieLoaderAction(false));
  }, [dispatch]);
  return (
    <Dialog p={3} maxWidth="xs" open={open} onClose={handleClose}>
      <Box className="modalBox" sx={{ borderRadius: `${borderRadius} !important`, p: 3 }} align="center">
        {closeButton && (
          <IconButton onClick={handleClose} className="crossButton">
            <img src={crossButton} alt="cross" />
          </IconButton>
        )}
        <Box pt={4}>
          <Typography sx={{ width: '80%', margin: '0 auto' }} className="primaryHeading" align="center" fontWeight="500" pb={3}>
            {title}
          </Typography>
          <Typography className="infoTextGlobal" fontWeight="500" align="center" pb={3}>
            {subTitle}
          </Typography>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default MdModal;
