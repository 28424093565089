import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, TextField } from '@mui/material';
import './Clients.css';
import ClientTable from './ClientTable';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import TableWrapper from 'components/TableWrapper/TableWrapper';

const isAuthenticated = () => {
  const user = localStorage.getItem('token');
  return !!user;
};
const Clients = () => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const userIsAuthenticated = isAuthenticated();
    setLoading(false);

    if (userIsAuthenticated && user?.ambassador === null) {
      navigate('/');
    }
  }, [navigate, user?.ambassador]);

  if (loading) {
    return null;
  }

  return (
    <TableWrapper>
      <Grid
        // sx={{ pl: { xs: 0, md: 5, sm: 5, lg: 5 } }}
        container
      >
        <Grid item lg={11} sm={11} xs={12} md={11}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item lg={9} sm={9} xs={12} md={9} sx={{ mb: { xs: 1 } }}>
              <Typography variant="h3" className="primaryColor">
                Users List
              </Typography>
            </Grid>
            <Grid
              // mt={3}
              className="searchGrid"
              sx={{
                pt: 1,
                pb: 1,
                borderRadius: 4
              }}
              lg={3}
              sm={3}
              xs={12}
              md={3}
            >
              <Box mt={1} mr={1} ml={2}>
                <SearchIcon />
              </Box>
              <TextField
                variant="standard"
                fullWidth
                placeholder="Search users"
                InputProps={{
                  disableUnderline: true
                }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setSearch(inputValue);
                }}
              />
            </Grid>
          </Grid>
          <Grid mt={3} lg={2.5} sm={2.5} md={2.5}></Grid>

          <ClientTable search={search} setSearch={setSearch} />
        </Grid>
        <Grid item lg={1} sm={1} md={1}></Grid>
      </Grid>
    </TableWrapper>
  );
};

export default Clients;
