/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, Box, Typography, Button } from '@mui/material';
import AppBarStyled from './AppBarStyled';
import { useSelector } from 'react-redux';
import bars from 'assets/designs/bars.svg';
import help from 'assets/designs/help.svg';
import SearchBar from './Searchbar';
import Lamp from 'assets/designs/blueLamp.svg';
import AddIcon from '@mui/icons-material/Add';
import { createNewProject } from 'store/actions/Projects/ProjectsActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { teamMatesModal } from 'store/actions/Teammates/TeammatesActions';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { subscriptionValidation } from 'utils/SubscriptionValidation';
import { useLocation } from 'react-router-dom';
import { drawerWidth } from 'config';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

// eslint-disable-next-line react/prop-types
const Header = ({ open, handleDrawerToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownMD1 = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state) => state.auth.user);
  const createProject = (setStatus) => {
    dispatch(createNewProject(setStatus, navigate));
  };
  const location = useLocation();
  const { pathname } = location;
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const mainHeader = (
    <>
      <Toolbar
        sx={{
          mt: 1.8,
          py: 1.5,
          mx: { md: 5, lg: 5, sm: 2, xs: 1 },
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box onClick={handleDrawerToggle} display="flex">
          {matchDownMD && <img src={bars} alt="bars" />}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {!matchDownMD && <SearchBar pathname={pathname} />}
          <Box sx={{ ml: 3, pl: 2, borderLeft: '1px solid #b6b6b6' }} display="flex">
            <img src={help} alt="help" />
          </Box>
        </Box>
      </Toolbar>
      {pathname !== '/seo-tools' && (
        <Box sx={{ mx: { lg: 5, sm: 2, xs: 2 }, pl: { lg: 2, md: 2, sm: 1, xs: 2 } }} className="globalAlignment">
          {pathname !== '/profile' && pathname !== '/medium' && pathname !== '/seo-tools/searchhistory' && (
            <Box sx={{ ml: { lg: 2, sm: 2, xs: 0 } }}>
              <Typography sx={{ mt: 1, overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '20px' }}>Greetings,</Typography>
              <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }} className="header headerName">
                {user?.firstName} {user?.lastName ? user?.lastName : ''}
              </Typography>
            </Box>
          )}
          <Box className="globalAlignment">
            {pathname !== '/teammates' ? (
              (pathname === '/' || pathname === '/my-project' || pathname === '/shared-projects') && (
                <>
                  <Button
                    onClick={() => {
                      createProject(true);
                    }}
                    sx={{ borderRadius: '24px !important', ml: { lg: 3, md: 3, sm: 2, xs: 1 } }}
                    className="globalButtons"
                  >
                    <img src={Lamp} alt="lamp" className="lampImage" />
                    <span className="hideHelpMe">Write with Genie</span>
                  </Button>
                  <Button
                    sx={{ borderRadius: '24px !important', ml: { lg: 3, md: 3, sm: 2, xs: 1 } }}
                    onClick={() => {
                      createProject(false);
                    }}
                    className="globalButtonSecond"
                  >
                    <AddIcon sx={{ color: 'inherit' }} />
                    <span className="hideHelpMe">Create Document</span>
                  </Button>
                </>
              )
            ) : (
              <Button
                sx={{ borderRadius: '24px !important' }}
                className="globalButtonSecond"
                onClick={() => {
                  if (!subscriptionValidation(currentPlanwordsTeammates, user, true)) {
                    dispatch(subscriptionPlanModalOpen(true));
                  } else {
                    dispatch(teamMatesModal(true));
                  }
                }}
              >
                <AddIcon sx={{ color: 'inherit' }} /> <span className="hideHelpMe"> Add Teammates</span>
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
  // app-bar params
  const appBar = {
    position: 'fixed',
    backgroundColor: 'red',
    color: 'inherit',
    elevation: 0,
    sx: {
      width: open ? `calc(100% - ${drawerWidth}px)` : matchDownMD ? '100%' : `calc(100% - ${90}px)`
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};
export default Header;
