import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { teammateSubscriptionPlanModal } from '../../store/actions/Subscription/SubscriptionActions';
import { useNavigate } from 'react-router-dom';
import { inviteTeammates } from 'store/actions/Teammates/TeammatesActions';
import './Profile.css';
import crossButton from 'assets/designs/crossButton.svg';
import blackDot from 'assets/designs/blackDot.svg';
import Box from '@mui/material/Box';
import logoGradient from 'assets/images/common/genieOn1.svg';

const TeammtesSubsciption = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teammateSubscriptionModal } = useSelector((state) => state.auth);
  const { subscriptionPlans } = useSelector((state) => state.subscription);
  const subsplan = async () => {
    dispatch(inviteTeammates({ emails: subscriptionPlans?.validEmails }, navigate));
  };

  const infoOfOPlan = [
    { img: blackDot, text: 'AI-powered writing assistance for advanced writing needs' },
    { img: blackDot, text: 'Full access to all advanced features' },
    { img: blackDot, text: 'Create and manage your team of writers' },
    { img: blackDot, text: 'Share documents with your team members' },
    { img: blackDot, text: 'Create and manage team projects' },
    { img: blackDot, text: 'Collaborate in real time on documents' }
  ];
  return (
    <Dialog
      open={teammateSubscriptionModal}
      onClose={() => {
        dispatch(teammateSubscriptionPlanModal(false));
      }}
    >
      <DialogTitle sx={{ p: 5 }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(teammateSubscriptionPlanModal(false));
          }}
          className="crossButton"
        >
          <img src={crossButton} alt="cross" />
        </IconButton>
        <Typography align="center">
          <img src={logoGradient} alt="logo" />
        </Typography>
        <Typography my={3} align="center" className="primaryHeading">
          Buy teammates
        </Typography>
        <Box mt={3}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <span className="dollarSign">$</span>
            <Typography className="priceText">{subscriptionPlans?.totalPrice}</Typography>
          </Box>
          {infoOfOPlan.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
              <img src={item.img} alt="logo" />
              <Typography sx={{ ml: 2 }} className="secondaryHeading">
                {item.text}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button className="globalButtonSecond" sx={{ my: 3 }} onClick={subsplan} fullWidth>
          Buy teammates
        </Button>
      </DialogTitle>
    </Dialog>
  );
};

export default TeammtesSubsciption;
