import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// import navigation from 'menu-items';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/actions/Menu/Menu';

// ==============================|| MAIN LAYOUT ||============================== //

const SeoLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const drawerOpen = useSelector((state) => state.menu.openDrawer);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer(!matchDownLG));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);
  return (
    <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
      {/* <Toolbar /> */}
      {/* <Breadcrumbs navigation={navigation} title /> */}
      <Outlet />
    </Box>
  );
};

export default SeoLayout;
