// material-ui
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

// project import
import { drawerWidth } from 'config';
const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  boxShadow: 'none',
  background: 'linear-gradient(99deg, rgba(207,207,239,1) 1%, rgba(214,202,245,1) 2%, rgba(194,208,250,1) 99%, rgba(191,207,255,1) 99%)',
  marginTop: '10px',
  marginLeft: '10px',
  mt: 2,
  borderRadius: '12px'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: 98,
  background: 'linear-gradient(99deg, rgba(207,207,239,1) 1%, rgba(214,202,245,1) 2%, rgba(194,208,250,1) 99%, rgba(191,207,255,1) 99%)',
  marginTop: '10px',
  marginLeft: '10px',
  mt: 2,
  borderRight: 'none',
  boxShadow: 'none',
  borderRadius: '12px'
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: '300px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default MiniDrawerStyled;
