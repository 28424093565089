import {
  GET_USER_SITES,
  MEDIUM_SITE_URL,
  GET_CREATE_POST,
  GET_WORDPRESS_CATEGORIES_LIST
} from 'store/actions/MediumAndWordPress/MediumAndWordPressAction';
const initialState = {
  getMediumSiteUrlResp: [],
  userSites: [],
  postCreate: {},
  WordPressCategoriesList: []
};

export function MediumAndWordPressReducer(state = initialState, action) {
  if (action.type === MEDIUM_SITE_URL) {
    return {
      ...state,
      getMediumSiteUrlResp: action.payload
    };
  }
  if (action.type === GET_USER_SITES) {
    return {
      ...state,
      userSites: action.payload
    };
  }
  if (action.type === GET_CREATE_POST) {
    return {
      ...state,
      postCreate: action.payload
    };
  }
  if (action.type === GET_WORDPRESS_CATEGORIES_LIST) {
    return {
      ...state,
      WordPressCategoriesList: action.payload
    };
  }

  return state;
}
