import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Typography, ListItemButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { activeItem } from 'store/actions/Menu/Menu';
import prlogo from '../assets/images/common/prlogo.svg';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './menuItems.css';

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  const theme = useTheme();
  const isSelected = openItem === 'profile';
  const iconSelectedColor = 'primary.contrastText';
  const level = 1;
  const profileShow = () => {
    dispatch(activeItem('profile'));
    navigate('/profile');
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/profile') {
      dispatch(activeItem('profile'));
    }
  }, [dispatch, pathname]);

  return (
    <>
      <ListItemButton
        onClick={profileShow}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          my: 1,
          mx: 2,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          borderRadius: '8px',
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: '#fff',
              color: 'black',
              transition: 'none'
            },
            '&.Mui-selected': {
              bgcolor: theme.palette.primary.mainBg,
              // borderRight: `2px solid ${theme.palette.primary.dark}`,
              color: iconSelectedColor,
              borderRadius: '8px',
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: theme.palette.primary.mainBg,
                borderRadius: '8px'
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          })
        }}
      >
        <Box className="nameBox">
          <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={user?.profilePic || prlogo} alt="profile" className="userImg" />
            &nbsp; &nbsp; &nbsp;
            {drawerOpen && (
              <Grid item>
                <Typography className="userName" sx={{ color: isSelected ? '#FFFFFF' : '#6631A5' }}>
                  <b>
                    {user?.firstName} {user?.lastName && user?.lastName}
                  </b>
                </Typography>
                <Typography
                  className="userWriter"
                  sx={{
                    color: isSelected ? '#FFFFFF' : '#000000'
                  }}
                >
                  {/* {role} */}
                  {user?.ambassador === null || user?.ambassador?.adminApproval !== 'approved' ? 'Awesome Writer' : 'Ambassador'}
                  {/* {sessionStorage.getItem('ambassador') === 'null' ? 'Awesome Writer' : 'Ambassador'} */}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* {pathname === '/profile' && <KeyboardArrowRightIcon style={iconStyle} />} */}
      </ListItemButton>
      <Grid
        sx={{
          width: '80%',
          margin: '0 auto',
          background: '#b8aeae',
          height: '0.5px'
        }}
      ></Grid>
    </>
  );
}

export default Profile;
