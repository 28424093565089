// import { act } from 'react-dom/test-utils/index';
import { SUBSCRIPTION_PLAN_MODAL, TEAMMTES_SUBSCRIPTION_PLAN_MODAL } from 'store/actions/Subscription/SubscriptionActions';
import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  LOGIN_USER_ACTION,
  SNACBAR_ACTION,
  VERIFY_EMAIL,
  VERIFY_EMAIL_FAILED_ACTION,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
  UPDATE_PASSWORD,
  LOADING,
  GENIE_LOADER_ACTION,
  LAMP_LOADER_MODAL,
  BLACK_FRIDAY_MODAL
} from '../actions/Authentication/AuthActions';

const initialState = {
  auth: {
    email: '',
    idToken: '',
    localId: '',
    expiresIn: '',
    refreshToken: ''
  },
  snackbar: {
    open: false,
    message: '',
    type: ''
  },
  errorMessage: '',
  successMessage: '',
  showLoading: '',
  verifyEmail: '',
  user: null,
  resetPassword: '',
  forgotPassword: '',
  checkConfirmPassword: {},
  updatePassword: {},
  userProfilePic: {},
  genieLoader: false,
  subscriptionPlanModal: false,
  modalType: '',
  lambLoader: false,
  contentValue: '',
  invitedTeammates: [],
  teammateSubscriptionModal: false,
  localValue: false,
  blackFridayModal: false
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false
    };
  }
  if (action.type === LOADING) {
    return {
      ...state,
      showLoading: action.payload
    };
  }
  if (action.type === BLACK_FRIDAY_MODAL) {
    return {
      ...state,
      blackFridayModal: action.payload
    };
  }
  if (action.type === LAMP_LOADER_MODAL) {
    return {
      ...state,
      lambLoader: action.payload,
      contentValue: action.contentType
    };
  }

  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false
    };
  }
  if (action.type === VERIFY_EMAIL) {
    return {
      ...state,
      verifyEmail: action.payload
    };
  }
  if (action.type === FORGOT_PASSWORD) {
    return {
      ...state,
      forgotPassword: action.payload
    };
  }
  if (action.type === RESET_PASSWORD) {
    return {
      ...state,
      resetPassword: action.payload
    };
  }

  if (action.type === VERIFY_EMAIL_FAILED_ACTION) {
    return {
      ...state,
      verifyEmail: action.payload
    };
  }
  if (action.type === LOGIN_USER_ACTION) {
    return {
      ...state,
      user: action.payload
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: '',
      successMessage: '',
      auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: ''
      }
    };
  }
  if (action.type === SNACBAR_ACTION) {
    return {
      ...state,
      snackbar: {
        open: action.open,
        message: action.payload.message,
        type: action.payload.code === 200 || action.payload.code === 201 || action.payload.code === 204 ? '#707070' : 'red'
      }
    };
  }

  if (action.type === SIGNUP_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      successMessage: '',
      showLoading: false
    };
  }
  if (action.type === GENIE_LOADER_ACTION) {
    return {
      ...state,
      genieLoader: action.payload
    };
  }
  if (action.type === CONFIRM_PASSWORD) {
    return {
      ...state,
      checkConfirmPassword: action.payload
    };
  }
  if (action.type === UPDATE_PASSWORD) {
    return {
      ...state,
      updatePassword: action.payload
    };
  }
  if (action.type === SUBSCRIPTION_PLAN_MODAL) {
    return {
      ...state,
      subscriptionPlanModal: action.payload,
      localValue: action.value
    };
  }
  if (action.type === TEAMMTES_SUBSCRIPTION_PLAN_MODAL) {
    return {
      ...state,
      teammateSubscriptionModal: action.payload
    };
  }
  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload
    };
  }
  return state;
}
