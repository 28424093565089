import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import crossButton from 'assets/designs/crossButton.svg';
import { useDispatch, useSelector } from 'react-redux';
import { mySubsplan, allSubsPlans, subscriptionPlanModalOpen } from '../../store/actions/Subscription/SubscriptionActions';
import { useNavigate, useLocation } from 'react-router-dom';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import './Profile.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import blackDot from 'assets/designs/blackDot.svg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GlobalDialog = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('sm'));
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState('monthly');
  const [businessPlan, setBusinessPlan] = useState('monthly');
  const [planType, setPlanType] = useState('');
  const { subscriptionPlanModal, user } = useSelector((state) => state.auth);
  const { subscriptionPlans, subscriptionPlansPro } = useSelector((state) => state.subscription);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const [tabsValue, setTabsValue] = useState(0);
  const [tabsValueBussiness, setTabsValueBussiness] = useState(0);
  const infoOfOPlan = [
    { img: blackDot, text: 'AI-powered writing assistance for advanced writing needs' },
    { img: blackDot, text: 'Full access to all advanced features' }
    // { img: blackDot, text: 'Create and manage your team of writers' },
    // { img: blackDot, text: 'Share documents with your team members' },
    // { img: blackDot, text: 'Create and manage team projects' },
    // { img: blackDot, text: 'Collaborate in real time on documents' }
  ];
  const infoOfOPlaBussiness = [
    { img: blackDot, text: 'AI-powered writing assistance for advanced writing needs' },
    { img: blackDot, text: 'Full access to all advanced features' },
    { img: blackDot, text: 'Create and manage your team of writers' },
    { img: blackDot, text: 'Share documents with your team members' },
    { img: blackDot, text: 'Create and manage team projects' },
    { img: blackDot, text: 'Collaborate in real time on documents' }
  ];
  const handleChangeTabs = (event, newValue) => {
    setSelectedUser(newValue === 0 ? 'monthly' : 'annually');
    setTabsValue(newValue);
    setPlanType('pro');
  };
  const handleChangeTabsBussiness = (event, newValue) => {
    setBusinessPlan(newValue === 0 ? 'monthly' : 'annually');
    setTabsValueBussiness(newValue);
    setPlanType('business');
  };

  const tabStyle = {
    backgroundColor: 'transparent',
    color: '#707070',
    borderRadius: '27px',
    width: !matchDownLG ? '232.185px' : '',
    height: '48px',
    fontSize: '16px',
    margin: '3px'
  };

  const activeTabStyle = {
    backgroundColor: '#fff',
    borderBottom: 'none',
    color: '#6631A5',
    fontWeight: 700
  };

  const subsplan = async (planType) => {
    let body = {
      duration: planType === 'pro' ? selectedUser : businessPlan,
      redirectURL: location.pathname,
      planType: planType
    };
    dispatch(mySubsplan(body, navigate));
  };
  useEffect(() => {
    dispatch(allSubsPlans({ duration: 'monthly', planType: 'pro' }, 'subscription', navigate));
    dispatch(allSubsPlans({ duration: 'monthly', planType: 'business' }, 'subscription', navigate));
  }, [dispatch, navigate]);

  useEffect(() => {
    if (planType !== '') {
      dispatch(allSubsPlans({ duration: planType === 'pro' ? selectedUser : businessPlan, planType: planType }, 'subscription', navigate));
    }
  }, [dispatch, selectedUser, planType, navigate, businessPlan]);

  // const handleUserChange = (event, planType) => {
  //   if (planType === 'pro') {
  //     setSelectedUser(event.target.value);
  //   } else {
  //     setBusinessPlan(event.target.value);
  //   }
  //   setPlanType(planType);
  // };
  const registerType = localStorage.getItem('register_type');

  return (
    <>
      <Dialog
        maxWidth={
          currentPlanwordsTeammates?.planType || registerType !== null ? 'sm' : user?.ambassador?.adminApproval === 'approved' ? 'sm' : 'lg'
        }
        fullWidth={true}
        open={subscriptionPlanModal}
        onClose={() => {
          dispatch(subscriptionPlanModalOpen(false));
          localStorage.removeItem('register_type');
        }}
        sx={{ width: '100%', borderRadius: '20px' }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(subscriptionPlanModalOpen(false));
            localStorage.removeItem('register_type');
          }}
          className="crossButton"
        >
          <img src={crossButton} alt="cross" />
        </IconButton>
        <Box
          sx={{ p: { lg: 5, md: 5, sm: 2, xs: 1 } }}
          className={
            Object.keys(currentPlanwordsTeammates).length === 0 &&
            (user?.ambassador === null || user?.ambassador?.adminApproval !== 'approved') &&
            registerType == null
              ? 'combineModal'
              : ''
          }
        >
          {(((user?.ambassador === null || user?.ambassador?.adminApproval !== 'approved') &&
            Object.keys(currentPlanwordsTeammates).length === 0 &&
            registerType == null) ||
            registerType === 'pro') && (
            <Box>
              <Box sx={{ p: { lg: 4, md: 4, sm: 2, xs: 1 }, borderRight: '2px solid #70707080' }}>
                <Typography align="center">
                  <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
                </Typography>
                <Typography my={3} align="center" className="primaryHeading">
                  Subscribe to Professional Plan?
                </Typography>
                <Tabs
                  my={3}
                  tabsValue={tabsValue}
                  onChange={handleChangeTabs}
                  sx={{ backgroundColor: '#cdcdcd80', borderRadius: '27px', justifyContent: 'space-between' }}
                >
                  <Tab label="Monthly" style={tabsValue === 0 ? { ...tabStyle, ...activeTabStyle } : tabStyle} />
                  <Tab label="Annually" style={tabsValue === 1 ? { ...tabStyle, ...activeTabStyle } : tabStyle} />
                </Tabs>
                <Box mt={3} pb={9}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="dollarSign">$</span>
                    <Typography className="priceText">{subscriptionPlansPro?.totalPrice}</Typography>
                  </Box>
                  {infoOfOPlan.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <img src={item.img} alt="logo" />
                      <Typography sx={{ ml: 2 }} className="secondaryHeading">
                        {item.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <DialogActions sx={{ justifyContent: 'center', mt: { xs: 3, lg: 8 } }}>
                  <Button
                    className="globalButtons"
                    onClick={() => {
                      subsplan('pro');
                    }}
                    fullWidth
                  >
                    Get Professional Plan
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          )}

          {(registerType !== 'pro' || user?.ambassador === null || user?.ambassador?.adminApproval === 'approved') && (
            <Box>
              <Box sx={{ p: { lg: 4, md: 4, sm: 2, xs: 1 } }}>
                <Typography align="center">
                  <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
                </Typography>
                <Typography my={3} align="center" className="primaryHeading">
                  Subscribe to Business Plan?
                </Typography>
                <Tabs
                  my={3}
                  tabsValue={tabsValueBussiness}
                  onChange={handleChangeTabsBussiness}
                  sx={{ backgroundColor: '#cdcdcd80', borderRadius: '27px' }}
                >
                  <Tab label="Monthly" style={tabsValueBussiness === 0 ? { ...tabStyle, ...activeTabStyle } : tabStyle} />
                  <Tab label="Annually" style={tabsValueBussiness === 1 ? { ...tabStyle, ...activeTabStyle } : tabStyle} />
                </Tabs>
                <Box my={3}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="dollarSign">$</span>
                    <Typography className="priceText">{subscriptionPlans?.totalPrice}</Typography>
                  </Box>
                  {infoOfOPlaBussiness.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <img src={item.img} alt="logo" />
                      <Typography sx={{ ml: 2 }} className="secondaryHeading">
                        {item.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <DialogActions sx={{ justifyContent: 'center', mt: { xs: 3 } }}>
                  <Button
                    className="globalButtonSecond"
                    onClick={() => {
                      subsplan('business');
                    }}
                    fullWidth
                  >
                    Get Business Plan
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default GlobalDialog;
