import React, { useEffect } from 'react';
import {
  // Box,
  Typography,
  Grid,
  // TextField,
  Button
  // useMediaQuery
} from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import SubscriptionsTable from './SubscriptionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { saveUserDetails } from 'store/actions/Authentication/AuthActions';
import { useNavigate } from 'react-router-dom';
import './Subscriptions.css';
function Subscriptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);

  const handleUpgrade = () => {
    dispatch(subscriptionPlanModalOpen(true));
  };

  useEffect(() => {
    saveUserDetails(navigate);
  }, [navigate]);

  return (
    <Grid container lg={12} md={12} sx={{ mt: 5 }}>
      <Grid item lg={10} md={10} sm={12} xs={12} sx={{ mb: 3 }} className="subscriptionsText">
        <Grid>
          <Typography className="historyText">Subscriptions History</Typography>
        </Grid>
        <Grid>
          {currentPlanwordsTeammates &&
          (Object.keys(currentPlanwordsTeammates).length === 0 || currentPlanwordsTeammates?.status === 'expired') ? (
            <Button className="globalButtonSecond" onClick={handleUpgrade}>
              Upgrade to Pro
            </Button>
          ) : currentPlanwordsTeammates?.status === 'cancelled' ? null : null}
        </Grid>
      </Grid>

      <Grid item lg={10} md={10} sm={12} xs={12} sx={{ paddingLeft: '20px' }}>
        <SubscriptionsTable />
      </Grid>
    </Grid>
  );
}

export default Subscriptions;
