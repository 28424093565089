import React, { useState, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Box, OutlinedInput } from '@mui/material';
import './DragAndDropImage.css';
import PropTypes from 'prop-types';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import { ThemeProvider } from '@mui/material/styles';
const DragAndDropImage = ({ open, setOpen, handleUploadImage }) => {
  const [file, setFile] = useState(null);
  const [isShadow, setIsShadow] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsShadow(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    setImagePreview(URL.createObjectURL(droppedFile));
    setIsShadow(false);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  const handleUrlPreview = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setImagePreview(imageUrl);
      setFile(imageUrl);
    };

    img.onerror = () => {
      setImagePreview(null);
    };
  };

  const clearFile = () => {
    setFile(null);
    setImagePreview(null);
    setImageUrl('');
  };

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        // sx={{ width: '100%' }}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        onDragLeave={() => setIsShadow(false)}
      >
        {isShadow && (
          <Box onDragOver={handleDragOver} onDrop={handleDrop} className="dropShadowInfo" sx={{ p: { xs: 1, sm: 1, md: 4, lg: 4 } }}>
            Drop
          </Box>
        )}
        <Box className="dragBox" onDragOver={handleDragOver} onDrop={handleDrop} sx={{ p: { xs: 1, sm: 1, md: 4, lg: 4 } }}>
          {imagePreview && (
            <DialogContent className="imgDialog">
              <img src={imagePreview} alt="Preview" width="100%" />
            </DialogContent>
          )}

          {file ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="outlined" className="cancelBtnWithBorder" onClick={clearFile}>
                  Cancel
                </Button>
                <Button variant="contained" className="commonSaveBtn" onClick={() => handleUploadImage(file)}>
                  Upload
                </Button>
              </Box>
            </>
          ) : (
            <DialogActions>
              <Box>
                <Typography variant="h5" sx={{ color: '#2A303B', mb: 3 }}>
                  You can Drag & drop any files
                </Typography>

                <input type="file" onChange={handleFileSelect} hidden accept="image/png, image/jpeg" ref={inputRef} />
                <Button variant="contained" className="globalButtonSecond" onClick={() => inputRef.current.click()}>
                  Choose a local file
                </Button>

                <Box mt={3} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ThemeProvider theme={TextFieldDesign}>
                    <OutlinedInput
                      autoComplete="off"
                      type="text"
                      placeholder="Enter Image Url"
                      value={imageUrl}
                      onChange={(e) => setImageUrl(e.target.value)}
                    />
                  </ThemeProvider>
                  <Button
                    sx={{
                      ml: 2
                    }}
                    variant="contained"
                    onClick={handleUrlPreview}
                    className="globalButtonSecond"
                  >
                    Preview
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </>
  );
};

DragAndDropImage.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  handleUploadImage: PropTypes.func
};

export default DragAndDropImage;
