import {
  SEOTOOLS_SEARCH,
  SEOTOOLS_SEARCH_UPDATE,
  SEOTOOLS_SEARCH_LISTS,
  SEOTOOLS_SEARCH_DETAILS,
  SEOTOOLS_SEARCH_DELETE,
  SEOTOOLS_SEARCH_SAVE,
  SEOTOOLS_SEARCH_CREATE_DOCUMENT,
  SEOTOOLS_SEARCH_LOCATION,
  SEOTOOLS_SEARCH_RESEARCH
} from '../actions/SeoTools/SeoToolActions';
const initialState = {
  seotoolsSearch: [],
  seotoolsSearchLists: [],
  seotoolsSearchDetails: [],
  seotoolsSearchUpdate: [],
  seotoolsSearchDelete: [],
  seotoolsSearchSave: [],
  seotoolsCreateDocument: [],
  seotoolsLocation: [],
  seotoolsResearch: [],
  searchListCount: 0
};

export function SeoToolsReducer(state = initialState, action) {
  if (action.type === SEOTOOLS_SEARCH) {
    return {
      ...state,
      seotoolsSearch: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_LISTS) {
    return {
      ...state,
      seotoolsSearchLists: action.payload,
      searchListCount: action.count
    };
  }
  if (action.type === SEOTOOLS_SEARCH_DETAILS) {
    return {
      ...state,
      seotoolsSearchDetails: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_UPDATE) {
    return {
      ...state,
      seotoolsSearchUpdate: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_DELETE) {
    return {
      ...state,
      seotoolsSearchDelete: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_SAVE) {
    return {
      ...state,
      seotoolsSearchSave: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_CREATE_DOCUMENT) {
    return {
      ...state,
      seotoolsCreateDocument: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_LOCATION) {
    return {
      ...state,
      seotoolsLocation: action.payload
    };
  }
  if (action.type === SEOTOOLS_SEARCH_RESEARCH) {
    return {
      ...state,
      seotoolsResearch: action.payload.seoTool
    };
  }

  return state;
}
