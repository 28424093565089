import { genieChangeTone, genieExpand, genieReadingLevel, genieRephrase, genieShorten } from 'services/GenieToolsService';
import { genieLoaderAction, openLampLoader } from '../Authentication/AuthActions';
import { handleErrors } from '../Projects/ProjectsActions';

export const GENIE_TOOL = 'GENIE_TOOL';
export const TOOLS_MODAL = 'TOOLS_MODAL';
export const GENIE_TOOL_DETAILS = 'GENIE_TOOL_DETAILS';
export const READING_LEVEL_MODAL = 'READING_LEVEL_MODAL';
export const ADJUST_TONE = 'ADJUST_TONE';
export const SELECTED_GENIE_TEXT = 'SELECTED_GENIE_TEXT';
export const SHORTEN_DATA = 'SHORTEN_DATA';
export const EXPAND_DATA = 'EXPAND_DATA';
export const TONE_DATA = 'TONE_DATA';
export const GET_STATUS_CODE = 'GET_STATUS_CODE';
export const REPLACE_TEXT = 'REPLACE_TEXT';
export const SET_QUILL_SELECTION = 'SET_QUILL_SELECTION';
export const SET_RANGE_SELECTION = 'SET_RANGE_SELECTION';
export function shortenText(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, ''));
    genieShorten(id, content)
      .then((response) => {
        dispatch(saveModifiedData(response?.data?.shorten));
        dispatch(getGenieHoverStatusCode(response?.status));
        // dispatch(openToolsModal(false));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openToolsModal(false));
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function expandText(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, ''));
    genieExpand(id, content)
      .then((response) => {
        dispatch(saveModifiedData(response?.data?.expand));
        dispatch(getGenieHoverStatusCode(response?.status));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openToolsModal(false));
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function rephraseText(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, ''));
    genieRephrase(id, content)
      .then((response) => {
        dispatch(saveModifiedData(response?.data?.rephrase));
        dispatch(getGenieHoverStatusCode(response?.status));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openToolsModal(false));
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function readingLevelText(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, ''));
    genieReadingLevel(id, content)
      .then((response) => {
        dispatch(saveModifiedData(response?.data?.reading_level));
        dispatch(getGenieHoverStatusCode(response?.status));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function changeTextTone(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, ''));
    genieChangeTone(id, content)
      .then((response) => {
        dispatch(openLampLoader(false));
        dispatch(saveModifiedData(response?.data?.tone));
        dispatch(getGenieHoverStatusCode(response?.status));
      })
      .catch((error) => {
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function replaceText(quill, textRange, newText) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    quill.deleteText(textRange?.index, textRange?.length);
    quill.insertText(textRange?.index, newText);
    dispatch(saveModifiedData({}));
    dispatch(genieLoaderAction(false));
  };
}

export function openGenieTools(data) {
  return {
    type: GENIE_TOOL,
    payload: data
  };
}

export function changedToneData(data) {
  return {
    type: TONE_DATA,
    payload: data
  };
}

export function saveModifiedData(data) {
  return {
    type: SHORTEN_DATA,
    payload: data
  };
}
export function getGenieHoverStatusCode(data) {
  return {
    type: GET_STATUS_CODE,
    payload: data
  };
}
export function openToolsModal(data) {
  return {
    type: TOOLS_MODAL,
    payload: data
  };
}

export function genieToolDetails(data) {
  return {
    type: GENIE_TOOL_DETAILS,
    payload: data
  };
}

export function readingLevelModal(data) {
  return {
    type: READING_LEVEL_MODAL,
    payload: data
  };
}

export function adjustContentTone(data) {
  return {
    type: ADJUST_TONE,
    payload: data
  };
}

export function selectedGenieText(data) {
  return {
    type: SELECTED_GENIE_TEXT,
    payload: data
  };
}

export function setQuillSelection(data) {
  return {
    type: SET_QUILL_SELECTION,
    payload: data
  };
}

export function setRangeSelection(data) {
  return {
    type: SET_RANGE_SELECTION,
    payload: data
  };
}
