import React, { useEffect, useState } from 'react';
import { Box, Typography, TableContainer, Table, Grid, Button, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonDeleteModal from 'components/Modal/CommoneDeleteModal/CommonDeleteModal';
import { reStartSubscription, schedulePlanCancel, usersSubslist } from 'store/actions/Subscription/SubscriptionActions';
import moment from 'moment';
import PaginationComp from 'components/Pagination/Pagination';
const SubscriptionsTable = () => {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userSubscriptionListCount, userSubscriptionList, currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  useEffect(() => {
    dispatch(usersSubslist(limit, offset, navigate));
  }, [dispatch, navigate, offset, currentPage]);

  const handleCancel = () => {
    setOpen(true);
  };

  const handleDelete = async () => {
    dispatch(schedulePlanCancel({ type: 'Subscription', subscriptionId: currentPlanwordsTeammates._id }, navigate));

    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
  };

  return (
    <TableContainer component={Box} className="customTable">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: '1px solid #CED4DA' }}>
                <TableCell className="headerText">Plan</TableCell>
                <TableCell className="headerText">Amount</TableCell>
                <TableCell className="headerText">Start Date</TableCell>
                <TableCell className="headerText">Expiry Date</TableCell>
                <TableCell className="headerText">Status</TableCell>
                <TableCell className="headerText">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userSubscriptionList && userSubscriptionList?.length === 0 ? (
                <TableRow sx={{ borderBottom: 'none !important', borderColor: 'none !important' }}>
                  <TableCell colSpan={12} align="center">
                    <Typography variant="h3" mt={10}>
                      No Records Found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                userSubscriptionList?.length > 0 &&
                userSubscriptionList?.map((subscriptionList) => (
                  <TableRow key={subscriptionList._id} sx={{ borderBottom: '1px solid #CED4DA', borderColor: 'none' }}>
                    <TableCell className="bottomborder" sx={{ textTransform: 'capitalize', paddingBlock: '29px' }}>
                      {subscriptionList?.planType}
                    </TableCell>
                    <TableCell className="bottomborder">${subscriptionList?.amount || 0}</TableCell>
                    <TableCell className="bottomborder">{moment(subscriptionList?.startDate).format('MM/DD/YYYY')}</TableCell>
                    <TableCell className="bottomborder">{moment(subscriptionList?.endDate).format('MM/DD/YYYY')}</TableCell>
                    <TableCell className="bottomborder" sx={{ textTransform: 'capitalize' }}>
                      {subscriptionList?.status}
                    </TableCell>
                    <TableCell className="bottomborder">
                      {subscriptionList?.status === 'active' ? (
                        <Button onClick={handleCancel} className="globalButtons">
                          Cancel
                        </Button>
                      ) : subscriptionList?.status === 'cancelled' ? (
                        <Button
                          className="globalButtonSecond"
                          onClick={() => {
                            dispatch(reStartSubscription({ subscriptionId: currentPlanwordsTeammates?._id }, navigate));
                          }}
                        >
                          Restart
                        </Button>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {userSubscriptionListCount > 10 && (
        <PaginationComp count={Math.ceil(userSubscriptionListCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
      )}

      <CommonDeleteModal
        handleDelete={handleDelete}
        open={open}
        setOpen={setOpen}
        title="Cancel Subscription?"
        description=" You can cancel your subscription at any time. You'll continue to have access to your account until your current billing period ends."
        cancelText="Cancel"
        deleteText="Cancel Subscription"
      />
    </TableContainer>
  );
};

export default SubscriptionsTable;
