import { TEAM_MATES_MODAL, TEAM_MATES_DETAILS, TEAM_MATES_LIST } from '../actions/Teammates/TeammatesActions';

const initialState = {
  teammateModal: false,
  teammateDetails: {},
  teammatesList: [],
  teammatesCount: 0
};

export function TeammateReducer(state = initialState, action) {
  if (action.type === TEAM_MATES_MODAL) {
    return {
      ...state,
      teammateModal: action.payload
    };
  }
  if (action.type === TEAM_MATES_DETAILS) {
    return {
      ...state,
      teammateDetails: action.payload
    };
  }
  if (action.type === TEAM_MATES_LIST) {
    return {
      ...state,
      teammatesList: action.payload,
      teammatesCount: action.count
    };
  }
  return state;
}
