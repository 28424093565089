import { createTemplate, getTemplates } from 'services/TemplateService';
import { genieLoaderAction, loaderAction } from '../Authentication/AuthActions';
import { getCreatedProject, handleErrors } from '../Projects/ProjectsActions';
// create function for gte list of templates

export const GET_TEMPLATES = '[templates] get templates';

// create function for gte list of templates
export function getTemplatesAction(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    dispatch(loaderAction('getTemplates'));
    getTemplates()
      .then((response) => {
        dispatch(loaderAction(''));
        dispatch(genieLoaderAction(false));
        dispatch(getTemplatesList(response?.data?.templates));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function createNewProjectWithTemplate(title, topic, audience, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    createTemplate(title, topic, audience)
      .then((response) => {
        dispatch(getCreatedProject(response?.data?.project));
        dispatch(loaderAction(''));
        if (response?.data?.project[0]?._id) {
          navigate(`/project/${response?.data?.project[0]?._id}`);
        }
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);

        dispatch(loaderAction(''));
      });
  };
}

export function getTemplatesList(templates) {
  return {
    type: GET_TEMPLATES,
    payload: templates
  };
}
