// ==============================|| MAIN LOGO ||============================== //
import React from 'react';
import proIcon from 'assets/designs/proIcon.svg';
import PropTypes from 'prop-types';

const ProIcon = ({ top, visibility, right }) => {
  return <img src={proIcon} alt="pro-icon" style={{ position: 'absolute', top: top, right: right || '10px', visibility: visibility }} />;
};
ProIcon.propTypes = {
  top: PropTypes.string,
  right: PropTypes.string,
  visibility: PropTypes.string
};
export default ProIcon;
