import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Profile from 'pages/Profile/Profile';
import AuthGuard from 'services/AuthGuard';
import CommingSoon from '../pages/ComingSoon';
// import EmailVerification from 'pages/authentication/EmailVerification/EmailVerification';
import MyProject from 'pages/MyProjects/MyProjects';
import ShareWithMe from 'pages/ShareWithMe/ShareWithMe';
import Teammates from 'pages/teammates/Teammates';
import TestComponent from '../components/Test/TestComponent';
import TeamatesInvitation from 'pages/Invitations/TeammateInvitation';
import DeletedDocuments from 'pages/DeletedDocuments/DeletedDocuments';
import SeoTools from 'pages/SeoTools/SeoTools';

import TransferProjectInvitation from 'pages/Invitations/TransferPorjectInvitation';
import SearchHistory from 'pages/SeoTools/SearchHistory/SearchHistory';
import Subscriptions from 'pages/Subscriptions/Subscriptions';
import Transactionhistory from 'pages/Transactionhistory/Transactionhistory';
import Ambassador from 'menu-items/ambassador';
// import Clients from 'pages/Clients/Clients';
import ClientActivity from 'pages/Clients/ClientActivity/ClientActivity';
// import SocialAssets from 'pages/SocialAssets/SocialAssets';
import MediumPortal from 'pages/Medium/MediumPortal';
// import MediumTokenGeneration from 'pages/Medium/TokengenerateSteps/MediumTokenGeneration';
// import WordpressAppPassName from 'pages/Medium/TokengenerateSteps/WordpressAppPassName';
import { stubTrue } from 'lodash';
import AmbassadorPortal from 'pages/AmbassadorPortal/AmbassadorPortal';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard auth={stubTrue()}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <DashboardDefault />
    },
    {
      path: 'teammates',
      element: <Teammates />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'ambassador',
      element: <Ambassador />
    },
    // {
    //   // path: 'verify-email/:id',
    //   path: 'verify-email',
    //   element: <EmailVerification />
    // },
    {
      path: 'help',
      element: <CommingSoon />
    },
    // {
    //   path: 'referral',
    //   element: <CommingSoon />
    // },
    {
      path: 'my-project',
      element: <MyProject />
    },
    {
      path: 'shared-projects',
      element: <ShareWithMe />
    },
    {
      path: 'deleted-documents',
      element: <DeletedDocuments />
    },
    {
      path: 'subscriptions',
      element: <Subscriptions />
    },
    {
      path: 'transactions-history',
      element: <Transactionhistory />
    },
    {
      path: 'test',
      element: <TestComponent />
    },
    {
      path: 'coming-soon',
      element: <CommingSoon />
    },
    {
      path: 'teammate/invitation/:id',
      element: <TeamatesInvitation />
    },
    {
      path: 'seo-tools',
      element: <SeoTools />
    },
    {
      path: 'ambassador-portal',
      element: <AmbassadorPortal />
    },
    // {
    //   path: 'clients',
    //   element: <Clients />
    // },
    {
      path: 'clients/:id',
      element: <ClientActivity />
    },
    // {
    //   path: 'social-assets',
    //   element: <SocialAssets />
    // },

    {
      path: 'seo-tools/searchhistory',
      element: <SearchHistory />
    },
    {
      path: '/project-ownership/invitation/:id',
      element: <TransferProjectInvitation />
    },
    {
      path: '/medium',
      element: <MediumPortal />
    }
    // {
    //   path: '/mediumTokenGenerate',
    //   element: <MediumTokenGeneration />
    // }
    // {
    //   path: '/wordpressTokenGenerate',
    //   element: <WordpressAppPassName />
    // }
  ]
};

export default MainRoutes;
