import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import DateFormatter from '../../utils/DateFormatter';
import DeleteIcon from '../../assets/images/common/delete.svg';
import { seoToolsSearchLists, seoToolsSearchDelete } from 'store/actions/SeoTools/SeoToolActions';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import './RecentSearchesTable.css';
import CommonDeleteModal from 'components/Modal/CommoneDeleteModal/CommonDeleteModal';
import TableWrapper from 'components/TableWrapper/TableWrapper';
import emptySearch from 'assets/designs/search.svg';
import editIcon from 'assets/images/common/edit.svg';

const RecentSearchesTable = () => {
  const styleForButton = {
    width: '40px',
    height: '40px'
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seotoolsSearchLists, searchListCount } = useSelector((state) => state.seoTools);
  const [anchorElArray, setAnchorElArray] = useState(Array(seotoolsSearchLists.length).fill(null));

  const [searchLists, setSearchLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [getSearchId, setGetSearchId] = useState(null);
  useEffect(() => {
    if (seotoolsSearchLists) setSearchLists(seotoolsSearchLists);
  }, [seotoolsSearchLists]);

  useEffect(() => {
    dispatch(seoToolsSearchLists(10, 0, navigate));
  }, [dispatch, navigate]);

  const handleDelete = async () => {
    const deleted = {
      isDeleted: true
    };
    dispatch(seoToolsSearchDelete(getSearchId, deleted, navigate));
    setOpen(false);
  };

  const handleMenuOpen = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  // Function to handle closing a specific menu
  const handleMenuClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  return (
    <TableWrapper>
      {seotoolsSearchLists && seotoolsSearchLists?.length === 0 ? (
        <Box align="center">
          <img src={emptySearch} style={{ width: '20%' }} alt="empty-projects" />
          <Typography className="primaryHeading">No Recent Search here</Typography>
          <Typography className="infoTextGlobal" sx={{ my: 2 }}>
            You haven't search anything yet.
          </Typography>
        </Box>
      ) : (
        <TableContainer>
          <Box className="globalAlignment">
            <Typography className="primaryHeading">Recent Search</Typography>
            <Typography className="tableCount" sx={{ fontWeight: 700 }}>
              Searched Projects ({searchListCount})
            </Typography>
          </Box>
          <Table>
            {' '}
            <TableHead>
              <TableRow sx={{ mb: 4, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                <TableCell className="headerRow">
                  <Typography variant="subtitle11"> Project Name</Typography>
                </TableCell>
                <TableCell align="center" className="headerRow">
                  <Typography variant="subtitle11"> Last Updated</Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <Typography variant="subtitle11">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchLists?.length > 0 ? (
                searchLists
                  // ?.sort((a, b) => b.createdAt - a.createdAt)
                  ?.map((searchList, index) => {
                    return (
                      <TableRow key={searchList._id} className="tableRow" sx={{ borderBottom: '1px solid #CED4DA', borderColor: 'none' }}>
                        <TableCell style={{ padding: '29px', width: '28%' }}>
                          {/* <Link className="projectListItems" to={`/seo-tools/${searchList._id}`}> */}
                          <EllipsisTooltip text={searchList?.search_keyword} variant="subtitle7" maxWidth="300px" />
                          {/* </Link> */}
                        </TableCell>
                        <TableCell align="center" className="headerRow" sx={{ color: 'black' }}>
                          <DateFormatter date={searchList.updatedAt} />
                        </TableCell>
                        <TableCell align="center" className="headerRow" sx={{ color: 'black' }}></TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-controls="click-menu"
                            aria-haspopup="true"
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                            onClick={(event) => {
                              handleMenuOpen(event, index);
                            }}
                            edge="end"
                            color="inherit"
                          >
                            <MoreHorizIcon style={styleForButton} />
                          </IconButton>
                          <Menu
                            key={index}
                            id={`click-menu-${index}`}
                            anchorEl={anchorElArray[index]}
                            open={Boolean(anchorElArray[index])}
                            onClose={() => handleMenuClose(index)}
                            className="info"
                            PaperProps={{
                              sx: {
                                boxShadow: 1,
                                borderRadius: '8px'
                              }
                            }}
                          >
                            <MenuItem onClick={() => handleMenuClose(index)} sx={{ mt: 1 }}>
                              <Box
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  }
                                }}
                              >
                                <Typography
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                  onClick={() => navigate(`/seo-tools/${searchList._id}`)}
                                >
                                  <img
                                    className="deleteIcon"
                                    src={editIcon}
                                    style={{
                                      width: '20px',
                                      height: '20px'
                                    }}
                                    alt="edit-icon"
                                  />
                                  {/* <ControlPointIcon sx={{ color: 'rgb(25, 54, 92)' }} /> */}
                                  <Typography sx={{ ml: 1, color: '#000000' }}>Edit/ReWrite</Typography>
                                </Typography>
                              </Box>
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setGetSearchId(searchList._id);
                                setOpen(true);
                                handleMenuClose(index);
                              }}
                              sx={{ mb: 1 }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  }
                                }}
                              >
                                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    className="deleteIcon"
                                    src={DeleteIcon}
                                    style={{
                                      width: '20px',
                                      height: '20px'
                                    }}
                                    alt="delete-icon"
                                  />
                                  <Typography sx={{ color: '#000000', ml: 1 }}>Delete</Typography>
                                </Typography>
                              </Box>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow sx={{ borderBottom: 'none !important', borderColor: 'none !important' }}>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="h5">No Recent Search Found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button
            className="globalButtonSecond"
            onClick={() => navigate('/seo-tools/searchhistory')}
            sx={{ borderRadius: '24px !important', mt: 4 }}
            type="button"
          >
            View All Searches
          </Button>
          <CommonDeleteModal
            handleDelete={handleDelete}
            open={open}
            setOpen={setOpen}
            title="Delete permanently?"
            description="This item will be deleted permanently. You can’t undo this action."
            cancelText="Cancel"
            deleteText="Yes"
          />
        </TableContainer>
      )}
    </TableWrapper>
  );
};

export default RecentSearchesTable;
