/* eslint-disable no-unused-vars */
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { GlobalStyle } from './GlobalStyle';
import CustomizedSnackbars from './components/Snackbar';
import ThemeRoutes from 'routes/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GenieLoader from 'components/GenieLoader';
import InviteTeamMates from 'pages/teammates/InviteTeamates';
import GlobalDialog from 'pages/Profile/GlobalDialog';
import TeammateSubscription from 'pages/Profile/TeammateSubscription';
import Subscribtions from 'components/Subscription/Subscriptions';
import LampLoader from 'components/LampLoader';
import { singelUserSubslist, subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import ToolsGenie from 'components/GenieToolsModal/ToolsGenie';
import ReadingLevel from 'components/GenieToolsModal/ReadingLevel';
import AdjustTone from 'components/GenieToolsModal/AdjustTone';
import ShareDocuments from 'components/ShareDocuments/ShareDocuments';
import { gapi } from 'gapi-script';
import ConfirmationModal from 'pages/Editor/ConfirmationModal';
import BlackFridayModal from 'components/Modal/BlackFridayModal/BlackFridayModal';
import { getSingleUserSubscriptions } from 'services/SubscriptionService';
import { getCoupon } from 'store/actions/Coupons/CouponActions';
import { Helmet } from 'react-helmet';
import LogRocket from 'logrocket';
import { saveUserDetails } from 'store/actions/Authentication/AuthActions';
import { teamMatesList } from 'store/actions/Teammates/TeammatesActions';
import { runLogoutTimer } from 'services/AuthService';
const clientId = process.env.REACT_APP_GOOGLE_APP_ID;
const environment = process.env.REACT_APP_ENVIRONMENT;
const App = () => {
  const { snackbar, genieLoader, subscriptionPlanModal, user } = useSelector((state) => state.auth);
  const { upGradeSubscirptionModal } = useSelector((state) => state.subscription);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const { teammateModal } = useSelector((state) => state.teammates);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googlebtnClick = localStorage.getItem('googlebtnClicked');
  const token = localStorage.getItem('token');
  const registerType = localStorage.getItem('register_type');

  if (registerType === 'free') {
    localStorage.removeItem('register_type');
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    }
    gapi.load('client:auth2', start);
  }, [googlebtnClick]);

  useEffect(() => {
    let expiresIn = localStorage.getItem('expires');
    if (token) {
      const currentTime = Date.now();
      const expiresInMilliseconds = parseInt(JSON.parse(expiresIn), 10) - parseInt(currentTime, 10);
      const getMilliseconds = expiresInMilliseconds - 60000;
      runLogoutTimer(dispatch, getMilliseconds, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let userToken = JSON.parse(localStorage.getItem('token'));
  useEffect(() => {
    if (userToken) {
      dispatch(getCoupon(navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    if (environment === 'production' && user !== null) {
      getSingleUserSubscriptions().then((res) => {
        LogRocket.init('khre1w/writergenie');
        LogRocket.identify(user?.id, {
          name: user?.firstName + ' ' + user?.lastName,
          email: user?.email,
          subscriptionType: Object.keys(res?.data.subs).length > 0 ? res?.data.subs?.subscriptionPlanId?.name : 'free'
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlanwordsTeammates]);

  useEffect(() => {
    let userToken = JSON.parse(localStorage.getItem('token'));
    let role = '';
    if (userToken) {
      dispatch(saveUserDetails(navigate));
      dispatch(singelUserSubslist(navigate));
      dispatch(teamMatesList(10, 0, role, navigate));
      dispatch(getCoupon(navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    if (userToken && (registerType === 'pro' || registerType === 'business')) {
      getSingleUserSubscriptions().then((res) => {
        if (Object.keys(res?.data.subs).length === 0) {
          dispatch(subscriptionPlanModalOpen(true, true));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <>
      {environment === 'production' && (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-RCY3ENDK6B"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-RCY3ENDK6B');
            `}
          </script>
        </Helmet>
      )}
      <ThemeCustomization>
        {upGradeSubscirptionModal && <Subscribtions />}
        <LampLoader />
        <ReadingLevel />
        <AdjustTone />
        <ToolsGenie />
        <ShareDocuments />
        <ConfirmationModal />
        <TeammateSubscription />
        <BlackFridayModal />
        {genieLoader && <GenieLoader />}
        {teammateModal && <InviteTeamMates />}
        {subscriptionPlanModal && <GlobalDialog />}
        <ScrollTop>
          {snackbar.open == true && <CustomizedSnackbars open={snackbar.open} message={snackbar.message} type={snackbar.type} />}
          <ThemeRoutes />
          <GlobalStyle />
        </ScrollTop>
      </ThemeCustomization>
    </>
  );
};

export default App;
