import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import img from '../../assets/images/common/test1.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { getTeamMateDetails, setTeammateStatus } from 'store/actions/Teammates/TeammatesActions';
import { useNavigate } from 'react-router-dom';
import './Invitations.css';

const InvitationAcceptance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoading, user } = useSelector((state) => state.auth);
  const { teammateDetails } = useSelector((state) => state.teammates);

  useEffect(() => {
    dispatch(getTeamMateDetails(id));
  }, [dispatch, id]);

  const handleAcceptInvitation = (status) => {
    dispatch(setTeammateStatus({ status: status, userId: [teammateDetails?.ownerId?.id] }, navigate));
  };
  return (
    <>
      {teammateDetails.ownerId ? (
        <Box>
          <Box className="logo-container" align="center">
            <img src={img} className="logo testImg" alt="cross" />
          </Box>
          <Box align="center" mt={-5}>
            <Box className="commonFlex">
              <Box sx={{ mr: 5 }}>
                {teammateDetails?.ownerId?.profilePic ? (
                  <img src={teammateDetails?.ownerId?.profilePic} alt="" className="profilePic" />
                ) : (
                  <AccountCircleIcon sx={{ fontSize: '80px' }} />
                )}
                <Typography variant="subtitle1" align={'center'}>
                  {teammateDetails?.ownerId?.firstName + ' ' + teammateDetails?.ownerId?.lastName}
                </Typography>
              </Box>
              <TrendingFlatIcon sx={{ fontSize: '60px' }} mb={3} />
              <Box sx={{ ml: 5 }}>
                {user?.profilePic ? (
                  <img src={user?.profilePic} alt="" className="profilePic" />
                ) : (
                  <AccountCircleIcon sx={{ fontSize: '80px' }} />
                )}
                <Typography variant="subtitle1" align={'center'}>
                  {user?.firstName} {user?.lastName} (You)
                </Typography>
              </Box>
            </Box>
            <Typography variant="h5" mt={5}>
              {teammateDetails?.ownerId?.firstName + ' ' + teammateDetails?.ownerId?.lastName} invited you to collaborate
            </Typography>
            <Box spacing={2} mt={5} pb={3} display="flex" justifyContent="center">
              <Button variant="contained" onClick={() => handleAcceptInvitation('accepted')} type="submit" className="acceptBtn">
                {showLoading !== 'teammateStatus' ? 'Accept Invitation' : <CircularProgress size={25} />}
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={() => handleAcceptInvitation('rejected')}
                className="declineBtn"
                sx={{ ml: 5 }}
              >
                {showLoading !== 'teammateStatus' ? 'Decline' : <CircularProgress size={25} />}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 5, m: 5, textAlign: 'center' }}>
          <Typography variant="h3">Invitation token is not valid</Typography>
        </Box>
      )}
    </>
  );
};

export default InvitationAcceptance;
