import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Chart from 'react-apexcharts';

const UserGraph = ({ slot }) => {
  const { getAmbassadorGraphData } = useSelector((state) => state.AmbassadorReducer);
  useEffect(() => {
    const maxClients = Math.max(...getAmbassadorGraphData.map((item) => item.totalClients));
    setOption({
      options: {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: { show: false }
        },
        xaxis: {
          categories:
            slot === 'monthly'
              ? getAmbassadorGraphData?.map((item) => `Week ${item?._id?.week}`)
              : slot === 'yearly'
              ? // ? getAmbassadorGraphData?.map((item) => `Month ${item?._id?.month}`)
                getAmbassadorGraphData?.map((item) => {
                  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                  return monthNames[item?._id?.month - 1];
                })
              : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yaxis: {
          min: 0, // Start the y-axis from 1
          max: Math.ceil(maxClients / 10) * 10 // Set the maximum value of the y-axis
          // forceNiceScale: true // Force y-axis ticks to be nicely spaced
        },
        colors: ['#6631A5']
      },
      series: [
        {
          data: getAmbassadorGraphData?.map((item) => item?.totalClients)
        }
      ]
    });
  }, [getAmbassadorGraphData, slot]);
  const [option, setOption] = useState({
    options: {
      chart: {
        id: 'apexchart-example'
      },
      xaxis: {
        categories:
          slot === 'monthly'
            ? getAmbassadorGraphData?.map((item) => `Week ${item?._id?.week}`)
            : slot === 'yearly'
            ? getAmbassadorGraphData?.map((item) => `Month ${item?._id?.month}`)
            : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },

      yaxis: {
        min: 0
        // max: Number.MAX_VALUE
        // forceNiceScale: true
      },
      colors: ['#6631A5']
    },
    series: [
      {
        // name: 'series-1',
        // data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 95, 25]
        data: getAmbassadorGraphData?.map((item) => item?.totalClients)
      }
    ]
  });

  return <Chart options={option?.options} series={option?.series} type="area" height={450} />;
};

UserGraph.propTypes = {
  slot: PropTypes.string
};

export default UserGraph;
