import React, { useRef } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import img1 from '../../assets/images/brandkit/img1.svg';
import img2 from '../../assets/images/brandkit/img2.svg';
import img3 from '../../assets/images/brandkit/img3.svg';
import img4 from '../../assets/images/brandkit/img4.svg';
import color1 from '../../assets/images/brandkit/color1.svg';
import color2 from '../../assets/images/brandkit/color2.svg';
import color3 from '../../assets/images/brandkit/color3.svg';
import color4 from '../../assets/images/brandkit/color4.svg';
import color5 from '../../assets/images/brandkit/color5.svg';
import copyIcon from '../../assets/images/brandkit/draft.svg';
import { useDispatch } from 'react-redux';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';

const BrandKit = () => {
  const dispatch = useDispatch();
  const copyColorRefs = useRef([]);
  const imgData = [
    { id: 1, img: img1 },
    { id: 2, img: img2 },
    { id: 3, img: img3 }
  ];

  const colorData = [
    { id: 1, img: color1, colorName: '#744C9E' },
    { id: 2, img: color2, colorName: '#20BCD8' },
    { id: 3, img: color3, colorName: '#FFCA36' },
    { id: 4, img: color4, colorName: '#FFCA36' },
    { id: 5, img: color5, colorName: '#F28F45' }
  ];

  const fontStyle = [
    { id: 1, title: 'General Sans', word: 125, fontWeight: 'Bold' },
    { id: 2, title: 'Inter', word: 125, fontWeight: 'Bold' }
  ];

  const handleCopy = (colorName) => {
    navigator.clipboard.writeText(colorName);
    let message = {
      code: 204,
      message: 'Copied to clipboard.'
    };
    dispatch(snackbarAction(true, message));
  };

  return (
    <Box className="globalShadow">
      <Grid spacing={3} container sx={{ paddingBottom: '16px', pl: 1.2 }}>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <Box>
            <Typography variant="h4" className="primaryColor">
              Logos
            </Typography>
          </Box>
        </Grid>
        {imgData?.map((item) => (
          <>
            <Grid item lg={2} key={item.id} sm={4} xs={12} md={2} pr={1.6}>
              <Box style={{ position: 'relative' }}>
                <img src={item.img} alt="" width="100%" />
                <Grid item>
                  <a href={item.img} download>
                    <img src={img4} alt="" style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
                  </a>
                </Grid>
              </Box>
            </Grid>
          </>
        ))}
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <Box>
            <Typography variant="h4" className="primaryColor">
              Colors
            </Typography>
          </Box>
        </Grid>
        {colorData?.map((item, index) => (
          <>
            <Grid item lg={1.3} key={item.id} sm={3} xs={4} md={1.5} pr={1.8}>
              <Box
                sx={{
                  display: 'grid',
                  placeContent: 'baseline'
                }}
              >
                <img src={item.img} alt="" width="100%" />

                <Box
                  ref={(element) => (copyColorRefs.current[index] = element)}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 0.5, cursor: 'pointer' }}
                  onClick={() => handleCopy(item.colorName)}
                >
                  {item.colorName}
                  <img src={copyIcon} alt="" style={{ paddingLeft: '3px' }} />
                </Box>
              </Box>
            </Grid>
          </>
        ))}

        <Grid item lg={12} sm={12} xs={12} md={12} pr={1.8}>
          <Box>
            <Typography variant="h4" className="primaryColor">
              Fonts
            </Typography>
          </Box>
        </Grid>
        {fontStyle?.map((item) => (
          <>
            <Grid
              item
              lg={8}
              sx={{ background: '#f9f9f9', paddingTop: '0px !important', mt: 1, borderRadius: '8px' }}
              key={item.id}
              sm={12}
              xs={12}
              md={12}
              mx={2}
              ml={3}
            >
              <Typography
                sx={{ fontSize: '14px', paddingBlock: '12px !important', fontWeight: 'bold' }}
              >{`${item.title}, ${item.word}, ${item.fontWeight}`}</Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default BrandKit;
