import {
  postSubscription,
  getSubscriptionAllplans,
  getUsersSubscriptions,
  getSingleUserSubscriptions,
  getCancelSubscription,
  getSubscriptionSuccess,
  // getSubscriptionResultCancel,
  getSubscriptionFailed,
  myTransactionLists,
  getCancelScheduleSubscription,
  buyMoreTokens,
  restoreSubscription
} from '../../../services/SubscriptionService';
import { genieLoaderAction, snackbarAction } from '../Authentication/AuthActions';
import { openCommentBox } from '../CommentBox/CommentBox';
import { adjustContentTone, openToolsModal, readingLevelModal } from '../GenieTools/GenieTools';
import { handleErrors, openConfirmationLock } from '../Projects/ProjectsActions';
import { openShareDocument } from '../ShareDocuments/ShareDocuments';
import { teamMatesModal } from '../Teammates/TeammatesActions';

export const SUBSCRIPTION_PLAN = '[ subscription plan list ] payment';
export const All_SUBSCRIPTION_PLANS = '[ All_SUBSCRIPTION_PLANS] list';
export const USER_SUBSCRIPTION_LIST = '[ USER_SUBSCRIPTION_LIST] list';
export const SUBSCRIPTION_PLAN_MODAL = '[ SUBSCRIPTION_PLAN_MODAL] modal';
export const SINGEL_USER_SUBSCRIPTION_LIST = '[ SINGEL_USER_SUBSCRIPTION_LIST] list';
export const CANCEL_SUBSCRIPTION_PLAN = '[ CANCEL_SUBSCRIPTION_PLAN ] list';
export const SUBSCRIPTION_PLAN_SUCCESS = '[ SUBSCRIPTION_PLAN_SUCCESS ] data';
export const SUBSCRIPTION_PLAN_CANCEL = '[ SUBSCRIPTION_PLAN_CANCEL ] data';
export const SINGEL_USER_SUBSCRIPTION_ID = '[ SINGEL_USER_SUBSCRIPTION_ID ] data';
export const MY_TRANSACTION_LIST = '[ MY_TRANSACTION_LIST ] data';
export const SUBSCRIPTION_PLAN_FAILED = '[ SUBSCRIPTION_PLAN_FAILED ] data';
export const SINGEL_USER_ACTIVE_PLAN = '[ SINGEL_USER_ACTIVE_PLAN ] data';
export const UPGRADE_SUBSCRIPTION_MODAL = '[ UPGRADE_SUBSCRIPTION_MODAL ] data';
export const TEAMMTES_SUBSCRIPTION_PLAN_MODAL = '[ TEAMMTES_SUBSCRIPTION_PLAN_MODAL ] data';
export const All_SUBSCRIPTION_PLANS_PRO = '[ All_SUBSCRIPTION_PLANS_PRO ] data';
export function myTransactionList(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    myTransactionLists()
      .then((response) => {
        dispatch(setMyTransactionList(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function usersSubslist(limit, offset, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getUsersSubscriptions(limit, offset)
      .then((response) => {
        dispatch(setMyUsersubsLists(response.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function subscriptionResultSuccess(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getSubscriptionSuccess(content)
      .then((response) => {
        dispatch(setSubscriptionSuccess(response?.data));
        dispatch(genieLoaderAction(false));
        navigate(response?.data?.userSub?.redirectURL);
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function reStartSubscription(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    restoreSubscription(content)
      .then((response) => {
        dispatch(singelUserSubslist(navigate));
        dispatch(usersSubslist(10, 0, navigate));
        let message = {
          code: 200,
          message: response.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function subscriptionResultFailed(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getSubscriptionFailed(content)
      .then((response) => {
        dispatch(setMySubscriptionFailed(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function singelUserSubslist(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getSingleUserSubscriptions()
      .then((response) => {
        // if (response?.data?.subs?.length > 0) {
        dispatch(setMycurrentactiveplan(response?.data?.subs));
        dispatch(setMySingelUserubsLists(response?.data?.subs?._id));
        dispatch(setMySingleSubscriptionid(response?.data?.subs?.subscriptionId));
        // }
        dispatch(genieLoaderAction(false));
        dispatch(openConfirmationLock(false, '', null));
        dispatch(openToolsModal(false));
        dispatch(readingLevelModal(false));
        dispatch(adjustContentTone(false));
        dispatch(openShareDocument(false));
        dispatch(teamMatesModal(false));
        dispatch(teammateSubscriptionPlanModal(false));
        dispatch(subscriptionPlanModalOpen(false));

        dispatch(openCommentBox(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function mySubsplan(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    postSubscription(content)
      .then((response) => {
        localStorage.removeItem('register_type');
        if (response.data.message) {
          let message = {
            code: 200,
            message: response.data.message
          };
          dispatch(subscriptionPlanModalOpen(false));
          dispatch(snackbarAction(true, message));
          dispatch(genieLoaderAction(false));
          if (response.data.message === "user's subscriptions created") {
            dispatch(subscriptionPlanModalOpen(false));
            navigate('/success');
          }
        } else {
          dispatch(setMySubscriptionPlan(response?.data?.results));
          dispatch(genieLoaderAction(false));
          dispatch(subscriptionPlanModalOpen(false));
          window.location.href = response.data;
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function createMoreTokens(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    buyMoreTokens(content)
      .then((response) => {
        const link = response.data;
        dispatch(setMySubscriptionPlan(response?.data?.results));
        dispatch(genieLoaderAction(false));
        window.location.href = link;
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function schedulePlanCancel(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getCancelScheduleSubscription(content)
      .then((response) => {
        dispatch(setMySingleSubscriptionid([]));
        dispatch(singelUserSubslist(navigate));
        let message = {
          code: 200,
          message: response.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(usersSubslist(10, 0, navigate));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function myCurrentPlanCancel(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getCancelSubscription(id)
      .then((response) => {
        dispatch(setMySingleSubscriptionid([]));
        dispatch(singelUserSubslist(navigate));
        let message = {
          code: 200,
          message: response.data.message
        };
        dispatch(snackbarAction(true, message));
        dispatch(usersSubslist(10, 0, navigate));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function allSubsPlans(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getSubscriptionAllplans(content)
      .then((response) => {
        let dataKeys = Object.keys(response?.data);
        if (!dataKeys.includes('validEmails')) {
          if (content.planType === 'pro') {
            dispatch(setMyallSubscriptionPlansPro(response?.data));
          } else {
            dispatch(setMyallSubscriptionPlans(response?.data));
          }
          dispatch(subscriptionPlanModalOpen(true));
        } else {
          if (response?.data?.validEmails?.length === 0) {
            let message = {
              code: 400,
              message: 'Email address already exist'
            };
            dispatch(snackbarAction(true, message));
          } else {
            if (content.planType === 'pro') {
              dispatch(setMyallSubscriptionPlansPro(response?.data));
            } else {
              dispatch(setMyallSubscriptionPlans(response?.data));
            }
            dispatch(teammateSubscriptionPlanModal(true));
          }
        }

        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function subscriptionPlanModalOpen(data, value) {
  return {
    type: SUBSCRIPTION_PLAN_MODAL,
    payload: data,
    value: value ? value : false
  };
}
export function teammateSubscriptionPlanModal(data) {
  return {
    type: TEAMMTES_SUBSCRIPTION_PLAN_MODAL,
    payload: data
  };
}

export function setMySubscriptionPlan(data) {
  return {
    type: SUBSCRIPTION_PLAN,
    payload: data
  };
}
export function setMyallSubscriptionPlans(data) {
  return {
    type: All_SUBSCRIPTION_PLANS,
    payload: data
  };
}
export function setMyallSubscriptionPlansPro(data) {
  return {
    type: All_SUBSCRIPTION_PLANS_PRO,
    payload: data
  };
}
export function setMyUsersubsLists(data) {
  return {
    type: USER_SUBSCRIPTION_LIST,
    // payload: data
    payload: data.subscriptions,
    count: data.count
  };
}
export function setMySingelUserubsLists(data) {
  return {
    type: SINGEL_USER_SUBSCRIPTION_LIST,
    payload: data
  };
}
export function setMySingleSubscriptionid(data) {
  return {
    type: SINGEL_USER_SUBSCRIPTION_ID,
    payload: data
  };
}
export function setMycurrentactiveplan(data) {
  return {
    type: SINGEL_USER_ACTIVE_PLAN,
    payload: data
  };
}

export function setSubscriptionSuccess(data) {
  return {
    type: SUBSCRIPTION_PLAN_SUCCESS,
    payload: data
  };
}
export function setMySubscriptionFailed(data) {
  return {
    type: SUBSCRIPTION_PLAN_FAILED,
    payload: data
  };
}
export function setSubscriptionResultCancel(data) {
  return {
    type: SUBSCRIPTION_PLAN_CANCEL,
    payload: data
  };
}
export function setMyTransactionList(data) {
  return {
    type: MY_TRANSACTION_LIST,
    payload: data.transaction,
    count: data.count
  };
}
export function upGradeSubscirption(data) {
  return {
    type: UPGRADE_SUBSCRIPTION_MODAL,
    payload: data
  };
}
