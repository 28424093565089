import React from 'react';
import { Box, Typography, Grid, DialogContent, Dialog, DialogTitle, IconButton } from '@mui/material';
import wstep1 from '../../../assets/images/mediumAndWordpress/steps/step1wordpress.png';
import wstep2 from '../../../assets/images/mediumAndWordpress/steps/step2wordpress.png';
import wstep3 from '../../../assets/images/mediumAndWordpress/steps/step3wordpress.png';
import wstep4 from '../../../assets/images/mediumAndWordpress/steps/step4wordpress.png';
import './TokenGenerate.css';
import crossButton from 'assets/designs/crossButton.svg';
import logoGradient from '../../../assets/images/common/genieOn1.svg';
import PropTypes from 'prop-types';
const wordpressStepsData = [
  {
    title: 'Login with username and password',
    image: wstep1
  },
  {
    title: 'Go to users',
    image: wstep2
  },
  {
    title: 'Click on edit',
    image: wstep3
  },
  {
    title: 'Scroll down! Enter new application password name',
    image: wstep4
  }
];

const WordpressAppPassName = ({ open, handleClose }) => {
  return (
    <Dialog
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ p: { xs: 0.5, sm: 1, md: 2, lg: 2 } }}
    >
      <Box className="WordPressMediumBox" sx={{ position: 'relative' }}>
        <DialogTitle className="crossIcon" sx={{ padding: '8px 24px !important' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
            className="crossButton"
          >
            <img src={crossButton} alt="cross" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContentBox">
          <Box align="center">
            <Box className="logo-container">
              <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
            </Box>
          </Box>
          <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Typography
              // variant="h2"
              className="titleText"
              sx={{
                fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem', lg: '2rem' },
                textAlign: 'center !important'
              }}
            >
              How to generate access token of WordPress?
            </Typography>
            {wordpressStepsData.map((step, index) => (
              <Grid key={index} container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    className="titleText"
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.5rem' }
                    }}
                  >
                    <strong>{`${index + 1}.`} </strong>
                    {step.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} mt={1} sx={{ paddingLeft: '20px' }}>
                  <img src={step.image} alt={`Step ${index + 1}`} style={{ width: '100%', maxWidth: '100%' }} />
                </Grid>
              </Grid>
            ))}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
WordpressAppPassName.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default WordpressAppPassName;
