import { DashboardOutlined, ProjectOutlined, HomeOutlined, DeleteOutlined, TransactionOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
// import { FaCrown } from 'react-icons/fa';
import { logoutUser } from '../services/AuthService';
import { MdPayment } from 'react-icons/md';
import { LoginOutlined, ProfileOutlined, TeamOutlined } from '@ant-design/icons';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { TbLogout2 } from 'react-icons/tb';
import { LiaShareAltSolid } from 'react-icons/lia';
import { FaRegHandshake } from 'react-icons/fa6';
import ProIcon from 'components/ProIcon/ProIcon';
import { RiTeamLine } from 'react-icons/ri';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { GoCrossReference } from 'react-icons/go';
const icons = {
  DashboardOutlined,
  HomeOutlined,
  ProjectOutlined,
  SourceIcon,
  DeleteOutlined,
  MdPayment,
  TransactionOutlined,
  LoginOutlined,
  ProfileOutlined,
  TeamOutlined,
  MdOutlineLiveHelp,
  TbLogout2,
  LiaShareAltSolid,
  FaRegHandshake,
  RiTeamLine,
  IoPaperPlaneOutline,
  QueryStatsOutlinedIcon,
  GoCrossReference
};

const content = {
  id: 'content',
  title: (
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 600,
        color: 'black'
      }}
    ></Typography>
  ),
  type: 'group',
  children: [
    {
      id: 'Home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'Projects',
      title: 'My Projects',
      type: 'item',
      url: '/my-project',
      icon: icons.ProjectOutlined,
      breadcrumbs: false
    },
    {
      id: 'SharedProjects',
      title: 'Shared with me',
      type: 'item',
      url: '/shared-projects',
      icon: icons.IoPaperPlaneOutline,
      breadcrumbs: false
    },
    {
      id: 'DeletedDocuments',
      title: 'Deleted Documents',
      type: 'item',
      url: '/deleted-documents',
      icon: icons.DeleteOutlined,
      breadcrumbs: false
    },

    {
      id: 'seo-tools',
      title: 'SEO Tools',
      type: 'item',
      url: '/seo-tools',
      icon: icons.QueryStatsOutlinedIcon,
      proIcon: <ProIcon />,
      // proIcon: <FaCrown color="#B38200" style={{ position: 'absolute', top: '-9px', right: '-9px' }} />,
      target: false
    },
    {
      id: 'teammates',
      title: 'Teammates',
      type: 'item',
      url: '/teammates',
      icon: icons.RiTeamLine,
      proIcon: <ProIcon />,
      // proIcon: <FaCrown color="#B38200" style={{ position: 'absolute', top: '-9px', right: '-9px' }} />,
      target: false
    },
    {
      id: 'ambassador-portal',
      title: 'Ambassador',
      type: 'item',
      url: '/ambassador-portal',
      newText: 'NEW',
      icon: icons.LiaShareAltSolid,
      target: false
    },
    // {
    //   id: 'referral',
    //   title: 'Referral',
    //   type: 'item',
    //   url: '/referral',
    //   icon: icons.GoCrossReference,
    //   target: false
    // },
    {
      id: 'Log Out',
      title: 'Log Out',
      type: 'item',
      action: `logout`,
      url: '#',
      icon: icons.TbLogout2,
      target: false,
      onClick: () => {
        const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
        logoutUser(refreshToken);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('googlebtnClicked');
        localStorage.removeItem('modalClosedTime');
        window.location.href = '/auth/signin';
      }
    }
    // {
    //   id: 'subscriptions',
    //   title: 'Subscriptions',
    //   type: 'item',
    //   url: '/subscriptions',
    //   icon: icons.MdPayment,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'transactions',
    //   title: 'Transactions',
    //   type: 'item',
    //   url: '/transactions-history',
    //   icon: icons.TransactionOutlined,
    //   breadcrumbs: false
    // }
  ]
};

export default content;
