import React, { useEffect, useState } from 'react';
import {
  //  Box,
  Typography
} from '@mui/material';

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { getAmbassadorUserActivity } from 'store/actions/Ambassador/AmbassadorAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../Clients.css';
import DateFormatter from 'utils/DateFormatter';
import TableWrapper from 'components/TableWrapper/TableWrapper';
const isAuthenticated = () => {
  const user = localStorage.getItem('token');
  return !!user;
};
const ClientActivity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const userIsAuthenticated = isAuthenticated();
    setLoading(false);

    if (userIsAuthenticated && (user?.ambassador === null || user?.ambassador?.adminApproval !== 'approved')) {
      navigate('/');
    }
  }, [navigate, user?.ambassador]);

  useEffect(() => {
    if (id) {
      dispatch(getAmbassadorUserActivity(id, navigate));
    }
  }, [dispatch, id, navigate]);

  const { activitiesData } = useSelector((state) => state.AmbassadorReducer?.getUserActivity);

  if (loading) {
    return null;
  }

  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ mb: 4, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
              <TableCell className="rowHeaderOne">
                <Typography variant="subtitle11">Action</Typography>
              </TableCell>
              <TableCell align="left" className="rowHeaderOne">
                <Typography variant="subtitle11">Time</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activitiesData && activitiesData?.length === 0 ? (
              <TableRow
                sx={{
                  '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                    borderColor: 'transparent !important'
                  }
                }}
              >
                <TableCell colSpan={4}>
                  <Typography variant="h3" align="center" mt={10}>
                    No User Activity Found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              activitiesData &&
              activitiesData.length > 0 &&
              activitiesData?.map((userActivityList) => {
                return (
                  <TableRow key={userActivityList?._id} sx={{ borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                    <TableCell style={{ padding: '29px' }}>{userActivityList?.action}</TableCell>

                    <TableCell
                      align="left"
                      className="rowHeaderOne"
                      sx={{ color: 'black' }}
                      // onClick={() => navigate(`/userdetail/${ambassadorList?.userId?._id}`)}
                    >
                      <DateFormatter variant="subtitle9" date={userActivityList?.createdAt} />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default ClientActivity;
