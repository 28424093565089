/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { CgClose } from 'react-icons/cg';
import Img from '../../assets/images/common/test.svg';
//import Chk from '../../assets/images/common/check.svg';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { generateOptions } from 'utils/Dropdown';
import { upGradeSubscirption, allSubsPlans, createMoreTokens } from 'store/actions/Subscription/SubscriptionActions';
import { useNavigate } from 'react-router-dom';
import crossButton from 'assets/designs/crossButton.svg';

// eslint-disable-next-line react/prop-types
const UpGradeSubscription = () => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(500);
  const { upGradeSubscirptionModal, subscriptionPlans } = useSelector((state) => state.subscription);
  const navigate = useNavigate();

  const subsplan = async () => {
    dispatch(createMoreTokens({ words: selectedValue }, navigate));
  };
  const options = generateOptions(3000);

  useEffect(() => {
    dispatch(allSubsPlans({ teammate: 0, words: selectedValue }, navigate));
  }, [dispatch, selectedValue, navigate]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    dispatch(allSubsPlans({ teammate: 0, words: newValue }, navigate));
  };

  return (
    <Dialog
      open={upGradeSubscirptionModal}
      onClose={() => {
        dispatch(upGradeSubscirption(false));
      }}
      lg={12}
      sm={12}
      xs={12}
      md={12}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          dispatch(upGradeSubscirption(false));
        }}
        className="crossButton"
      >
        <img src={crossButton} alt="cross" />
      </IconButton>
      <Box sx={{ px: 5, mx: 5 }} lg={12} sm={12} xs={12} md={12}>
        <DialogTitle sx={{ p: 5 }}>
          <Typography align="center">
            <img src={Img} alt="logo" width="37" height="90" />
          </Typography>
          <Typography align="center" variant="h3">
            Buy more token?
          </Typography>

          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography display="flex" alignItems="center">
              <FormControl size="small" sx={{ borderColor: 'transparent', width: '100%' }}>
                <Select fullWidth value={selectedValue} onChange={handleChange} sx={{ borderRadius: '8px' }}>
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>
            <Typography mt={2} variant="h3" sx={{ color: '#545961', width: '100px' }}>
              <span>
                <span>
                  ${subscriptionPlans?.totalPrice} <sup className="supStyle">USD</sup>
                </span>
              </span>
            </Typography>
          </DialogTitle>

          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={subsplan} className="globalButtonSecond">
              Buy Now
            </Button>
          </DialogActions>

          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="outlined"
              className="globalButtonSecond"
              onClick={() => {
                dispatch(upGradeSubscirption(false));
              }}
              sx={{
                bgcolor: '#fff',
                color: '#6631A5',
                border: 'none',
                borderRadius: '8px',
                padding: '8px 25px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#8F7DFD',
                  color: '#fff'
                }
              }}
            >
              <b>Not Right Now</b>
            </Button>
          </DialogActions>
        </DialogTitle>
      </Box>
    </Dialog>
  );
};

export default UpGradeSubscription;
