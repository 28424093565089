import {
  Clarity,
  Plagiarism,
  getReadabilityScore,
  Tone,
  FactCheck,
  SpellingAndGrammmerCheck,
  acceptSuggestion
} from '../../../services/SuggestionService';
import { genieLoaderAction } from '../Authentication/AuthActions';
import { handleErrors } from '../Projects/ProjectsActions';
export const PLAGIARISM_CHECK = '[plagiarism check] editor plagiarism list';
export const TONE_CHECK = '[tone check] editor tone check';
export const CLARITY_CHECK = '[clarity check] editor clarity check';
export const FACT_CHECKER = '[fact check] editor fact check';
export const SPELLING_AND_GRAMMAR_CHECK = '[spellingAndGrammar check] editor spellingAndGrammar check';
export const ACCEPT_SUGGESTION = '[accept suggestion] editor accept suggestion';
export const LOADING = '[loading action] loading';
export const GET_READING_LEVEL_SCORE = '[reading level] editor reading level';

export function spellingAndGrammar(id, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirmation('spelling'));
    SpellingAndGrammmerCheck(id)
      .then((response) => {
        dispatch(getSpellingAndGrammarCheck(response?.data));
        dispatch(loaderConfirmation(''));
      })
      .catch((error) => {
        dispatch(loaderConfirmation(''));

        handleErrors(dispatch, error, navigate);
      });
  };
}

export function plagiarismCheck(id, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirmation('plagiarism'));

    Plagiarism(id)
      .then((response) => {
        dispatch(getPlagiarism(response?.data));
        dispatch(loaderConfirmation(''));
      })
      .catch((error) => {
        dispatch(loaderConfirmation(''));

        handleErrors(dispatch, error, navigate);
      });
  };
}

export function toneCheck(id, content, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirmation('tone'));

    Tone(id, content)
      .then((response) => {
        dispatch(getTone(response?.data));
        dispatch(loaderConfirmation(''));
      })
      .catch((error) => {
        dispatch(loaderConfirmation(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function clarityCheck(id, content, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirmation('clarity'));
    Clarity(id, content)
      .then((response) => {
        dispatch(getClarity(response?.data));
        dispatch(loaderConfirmation(''));
      })
      .catch((error) => {
        dispatch(loaderConfirmation(''));

        handleErrors(dispatch, error, navigate);
      });
  };
}

export function readabilityCheck(id, content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getReadabilityScore(id, content)
      .then((response) => {
        dispatch(getReadingLevel(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function factChecker(id, content, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirmation('fact'));
    FactCheck(id, content)
      .then((response) => {
        dispatch(getFact(response?.data));
        dispatch(loaderConfirmation(''));
      })
      .catch((error) => {
        dispatch(loaderConfirmation(''));

        handleErrors(dispatch, error, navigate);
      });
  };
}

export function acceptSuggestionInd(projectId, suggestionData, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    acceptSuggestion(projectId, suggestionData)
      .then((response) => {
        dispatch(getAcceptSuggestion(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getSpellingAndGrammarCheck(data) {
  return {
    type: SPELLING_AND_GRAMMAR_CHECK,
    payload: data
  };
}

export function loaderConfirmation(data) {
  return {
    type: LOADING,
    payload: data
  };
}

export function getPlagiarism(data) {
  return {
    type: PLAGIARISM_CHECK,
    payload: data
  };
}

export function getTone(data) {
  return {
    type: TONE_CHECK,
    payload: data
  };
}

export function getClarity(data) {
  return {
    type: CLARITY_CHECK,
    payload: data
  };
}

export function getReadingLevel(data) {
  return {
    type: GET_READING_LEVEL_SCORE,
    payload: data
  };
}

export function getFact(data) {
  return {
    type: FACT_CHECKER,
    payload: data
  };
}

export function getAcceptSuggestion(data) {
  return {
    type: ACCEPT_SUGGESTION,
    payload: data
  };
}
