/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Menu, MenuItem, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteProjectsList,
  permanentDeleteProjects,
  restoreProjects,
  restoreAllDeletedProjects
} from 'store/actions/Projects/ProjectsActions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '../../assets/images/common/delete.svg';
import deletelogo from '../../assets/images/common/deletelogo.svg';
import PaginationComp from 'components/Pagination/Pagination';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import './DeletedTable.css';
import CommonDeleteModal from 'components/Modal/CommoneDeleteModal/CommonDeleteModal';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import deletedEmpty from 'assets/designs/deletedEmpty.svg';
import restore from 'assets/designs/restore.svg';
import { Link } from 'react-router-dom';
import DateFormatter from '../../utils/DateFormatter';
import AvatarGroup from '@mui/material/AvatarGroup';
import UserProfileImage from 'pages/Profileimage/UserProfileImage';
import TableWrapper from 'components/TableWrapper/TableWrapper';
import { VscDebugRestart } from 'react-icons/vsc';

const DeletedDocuments = () => {
  const styleForButton = {
    width: '40px',
    height: '40px'
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deltedList, deletedCount } = useSelector((state) => state.projects);
  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorElArray, setAnchorElArray] = useState(Array(deltedList.length).fill(null));
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const { lockPaginationState } = useSelector((state) => state.projects);

  const handleMenuOpen = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  // Function to handle closing a specific menu
  const handleMenuClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };
  useEffect(() => {
    dispatch(DeleteProjectsList(limit, offset, navigate));
  }, [dispatch, navigate, offset, currentPage]);

  useEffect(() => {
    if (lockPaginationState === true && currentPage !== 1) {
      setCurrentPage(1);
      setOffset(0);
    }
  }, [currentPage, lockPaginationState]);

  const handleDelete = async () => {
    dispatch(permanentDeleteProjects(selectedProject?._id));
    setOpen(false);
  };

  const handleRestore = async (projectList, index) => {
    dispatch(restoreProjects(projectList._id));
    handleMenuClose(index);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
  };
  return (
    <>
      <TableWrapper>
        {deltedList && deltedList?.length === 0 ? (
          <Box align="center">
            <img src={deletedEmpty} alt="empty-projects" />
            <Typography className="primaryHeading">No Deleted Document</Typography>
            <Typography className="infoTextGlobal" sx={{ my: 2 }}>
              Any documents you delete will end up here. You’ll have 30 days to restore them before they’re deleted permanently.
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Box className="globalAlignment">
              <Typography className="primaryHeading">Deleted Documents</Typography>
              <Typography className="tableCount" sx={{ mb: 2, fontWeight: 700 }}>
                Total Documents ({deletedCount})
              </Typography>
            </Box>
            <Box className="globalAlignment">
              <Typography sx={{ my: 2, width: '440px !important', textAlign: 'start !important' }} className="infoTextGlobal">
                Any documents you delete will end up here. You’ll have 30 days to restore them before they’re deleted permanently.
              </Typography>
              <Button
                className="globalButtonSecond"
                onClick={() => {
                  dispatch(restoreAllDeletedProjects(navigate));
                }}
                sx={{ height: '33px !important', borderRadius: '24px !important', minWidth: '130px !important' }}
              >
                <VscDebugRestart className="restoreAllIcon" sx={{ color: 'inherit' }} fontSize={20} />{' '}
                <span className="hideHelpMe">Restore All</span>
              </Button>
            </Box>
            <Table>
              {deltedList && deltedList?.length > 0 ? (
                <TableHead>
                  <TableRow sx={{ mb: 4, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                    <TableCell className="headerRow">
                      <Typography variant="subtitle11"> Project Name</Typography>
                    </TableCell>
                    <TableCell align="left" className="headerRow">
                      <Typography variant="subtitle11"> Last Updated</Typography>
                    </TableCell>
                    <TableCell align="left" className="headerRow">
                      <Typography variant="subtitle11"> Eligible People</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle11">Actions</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <></>
              )}
              <TableBody>
                {deltedList && deltedList?.length > 0 ? (
                  deltedList?.map((projectList, index) => (
                    <TableRow key={projectList._id} sx={{ borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                      <TableCell style={{ padding: '29px', width: '40%' }}>
                        {/* <Link
                          className="projectListItems"
                          // to={`/project/${projectList._id}`}
                        > */}
                        <EllipsisTooltip text={projectList.name} cursor="default" variant="subtitle7" maxWidth="385px" />
                        {/* </Link> */}
                      </TableCell>
                      <TableCell align="left" className="headerRow" sx={{ color: 'black' }}>
                        <DateFormatter date={projectList.updatedAt} />
                      </TableCell>
                      <TableCell align="left">
                        {projectList?.people_with_access?.length > 0 ? (
                          <AvatarGroup sx={{ justifyContent: 'start' }} max={3}>
                            {projectList?.people_with_access?.map((ite, index) => (
                              <UserProfileImage
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigate('/teammates');
                                }}
                                key={index}
                                image={ite?.profilePic}
                              />
                            ))}
                          </AvatarGroup>
                        ) : (
                          <Box>{'Not shared'}</Box>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-controls="click-menu"
                          aria-haspopup="true"
                          sx={{
                            '&:hover': {
                              backgroundColor: 'transparent'
                            }
                          }}
                          onClick={(event) => {
                            handleMenuOpen(event, index);
                          }}
                          edge="end"
                          color="inherit"
                        >
                          <MoreHorizIcon style={styleForButton} />
                        </IconButton>
                        <Menu
                          key={index}
                          id={`click-menu-${index}`}
                          anchorEl={anchorElArray[index]}
                          open={Boolean(anchorElArray[index])}
                          onClose={() => handleMenuClose(index)}
                          className="info"
                          PaperProps={{
                            sx: {
                              boxShadow: 1,
                              borderRadius: '8px'
                            }
                          }}
                        >
                          <MenuItem>
                            <Box
                              sx={{
                                backgroundColor: 'transparent',
                                '&:hover': {
                                  backgroundColor: 'transparent'
                                }
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  handleRestore(projectList, index);
                                }}
                                sx={{ display: 'flex', alignItems: 'center', width: '150px' }}
                              >
                                <img src={restore} style={{ width: '20px', height: '20px' }} alt="restore-icon" />
                                <Typography sx={{ ml: 1, color: '#000000' }}>Restore</Typography>
                              </Typography>
                            </Box>
                          </MenuItem>

                          <MenuItem>
                            <Box
                              sx={{
                                backgroundColor: 'transparent',
                                '&:hover': {
                                  backgroundColor: 'transparent'
                                }
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  setSelectedProject(projectList);
                                  setOpen(true);
                                  handleMenuClose(index);
                                }}
                                sx={{ display: 'flex', alignItems: 'center', width: '150px' }}
                              >
                                <img
                                  className="deleteIcon"
                                  src={DeleteIcon}
                                  style={{
                                    width: '20px',
                                    height: '20px'
                                  }}
                                  alt="delete-icon"
                                />
                                <Typography sx={{ color: '#000000', ml: 1 }}>Delete</Typography>
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      '.MuiTableCell-root': {
                        borderBottom: 'none !important',
                        borderColor: 'transparent !important'
                      }
                    }}
                  >
                    <TableCell colSpan={2}>
                      <Box
                        className="logoBox"
                        sx={{
                          mt: 8
                        }}
                      >
                        <img src={deletelogo} alt="Bin Icon" className="deleteLogoImg" />
                        <Typography
                          sx={{
                            mt: 4,
                            fontWeight: 600,
                            fontSize: '24px'
                          }}
                          variant="h6"
                        >
                          There’s nothing to see here
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1
                          }}
                          variant="h7"
                        >
                          Any documents you delete will end up here. You’ll have 30 days to
                        </Typography>
                        <Typography variant="h7">restore them before they’re deleted permanently.</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {deletedCount > 10 && (
              <PaginationComp count={Math.ceil(deletedCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
            )}
            <CommonDeleteModal
              handleDelete={handleDelete}
              open={open}
              setOpen={setOpen}
              title="Delete permanently?"
              description="This item will be deleted permanently. You can’t undo this action."
              cancelText="Cancel"
              deleteText="Delete Permanently"
            />
          </TableContainer>
        )}
      </TableWrapper>
    </>
  );
};

export default DeletedDocuments;
