// import types from menu action types
import { OPEN_ITEM, OPEN_COMPONENT, OPEN_COMPONENT_DRAWER, OPEN_DRAWER } from 'store/actions/Menu/Types';

// initial state
const initialState = {
  openItem: ['content'],
  defaultId: ' ',
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true
};

// ==============================|| SLICE - MENU ||============================== //

export function MenuReducer(state = initialState, action) {
  if (action.type === OPEN_ITEM) {
    return {
      ...state,
      openItem: action.payload
    };
  }
  if (action.type === OPEN_COMPONENT) {
    return {
      ...state,
      openComponent: action.payload
    };
  }
  if (action.type === OPEN_COMPONENT_DRAWER) {
    return {
      ...state,
      componentDrawerOpen: action.payload
    };
  }
  if (action.type === OPEN_DRAWER) {
    return {
      ...state,
      drawerOpen: action.payload
    };
  }
  return state;
}
