import { authApi } from './AxiosInstance';

export function myTransactionLists() {
  return authApi.get('/subscriptionPlans/transaction');
}
export function postSubscription(content) {
  return authApi.post(`/subscriptionPlans/subscribe`, content);
}

export function getCancelSubscription(id) {
  return authApi.get(`users/subscriptions/${id}/cancel`);
}
export function getCancelScheduleSubscription(content) {
  return authApi.post(`users/subscriptions/cancel`, content);
}
export function getSubscriptionAllplans(content) {
  return authApi.post(`/subscriptionPlans/price`, content);
}
export function getUsersSubscriptions(limit, offset) {
  return authApi.get(`/users/subscriptions?limit=${limit}&offset=${offset}`);
}
export function getSubscriptionSuccess(content) {
  return authApi.post(`/subscriptionPlans/status`, { sessionId: content });
}
export function getSubscriptionFailed(content) {
  return authApi.post(`/subscriptionPlans/status`, { sessionId: content });
}
export function getSubscriptionResultCancel(id) {
  return authApi.get(`/users/subscriptions/${id}cancel`);
}
export function getSingleUserSubscriptions() {
  return authApi.get(`/users/subscriptions/current`);
}
export function buyMoreTokens(content) {
  return authApi.post(`/subscriptionPlans/payment`, content);
}
export function restoreSubscription(content) {
  return authApi.post(`/users/subscriptions/restore`, content);
}
