import {
  BECOME_AN_AMBASSADOR,
  GET_AMBASSADOR_URL_CHECK,
  LOADING,
  GET_AMBASSADOR_USERS_LIST,
  GET_AMBASSADOR_USER_ACTIVITY,
  GET_AMBASSADOR_SOCIAL_ASSETS_LIST,
  GENERATE_PROFILE_LINK,
  UPDATE_AMBASSADOR_DATA,
  AMBASSADOR_USERS_GRAPH,
  GET_PROFILE_LINK_ERR
} from 'store/actions/Ambassador/AmbassadorAction';
const initialState = {
  ambassadorData: null,
  showLoading: '',
  getAmassadorId: null,
  getAmbassadorUsersList: [],
  getUserActivity: [],
  socialAssets: [],
  getSocialAssetsCount: 0,
  getProfileLink: {},
  updateUser: {},
  getAmbassadorGraphData: [],
  totalClicks: 0,
  totalClients: 0,
  getProfileLinkErr: null,
  ambUserListcount: 0
};
export function AmbassadorReducer(state = initialState, action) {
  if (action.type === BECOME_AN_AMBASSADOR) {
    return {
      ...state,
      ambassadorData: action.payload
    };
  }
  if (action.type === GET_AMBASSADOR_URL_CHECK) {
    return {
      ...state,
      getAmassadorId: action.payload
    };
  }
  if (action.type === GET_AMBASSADOR_USERS_LIST) {
    return {
      ...state,
      getAmbassadorUsersList: action.payload,
      ambUserListcount: action.count
    };
  }
  if (action.type === LOADING) {
    return {
      ...state,
      showLoading: action.payload
    };
  }
  if (action.type === GET_AMBASSADOR_USER_ACTIVITY) {
    return {
      ...state,
      getUserActivity: action.payload
    };
  }
  if (action.type === GET_AMBASSADOR_SOCIAL_ASSETS_LIST) {
    return {
      ...state,
      socialAssets: action.payload,
      getSocialAssetsCount: action.count
    };
  }
  if (action.type === GENERATE_PROFILE_LINK) {
    return {
      ...state,
      getProfileLink: action.payload
    };
  }
  if (action.type === GET_PROFILE_LINK_ERR) {
    return {
      ...state,
      getProfileLinkErr: action.payload
    };
  }
  if (action.type === UPDATE_AMBASSADOR_DATA) {
    return {
      ...state,
      updateUser: action.payload
    };
  }
  if (action.type === AMBASSADOR_USERS_GRAPH) {
    return {
      ...state,
      getAmbassadorGraphData: action.payload,
      totalClicks: action.totalClicks,
      totalClients: action.totalClient
    };
  }

  return state;
}
