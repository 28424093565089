import {
  GENIE_TOOL,
  TOOLS_MODAL,
  GENIE_TOOL_DETAILS,
  READING_LEVEL_MODAL,
  ADJUST_TONE,
  SELECTED_GENIE_TEXT,
  SHORTEN_DATA,
  TONE_DATA,
  GET_STATUS_CODE,
  REPLACE_TEXT,
  SET_RANGE_SELECTION,
  SET_QUILL_SELECTION
} from '../actions/GenieTools/GenieTools';
const initialState = {
  genieTools: false,
  toolsModal: false,
  getGenieToolDetails: {},
  readingLevel: false,
  adjustTone: false,
  selectedTextWordCount: 0,
  selectedText: '',
  modifiedData: {},
  toneData: {},
  getStatusCode: null,
  replaceTextState: false,
  rangeSelection: {},
  quillSelection: {}
};
export function GenieToolsReducer(state = initialState, action) {
  if (action.type === GENIE_TOOL) {
    return {
      ...state,
      genieTools: action.payload
    };
  }

  if (action.type === TONE_DATA) {
    return {
      ...state,
      toneData: action.payload
    };
  }

  if (action.type === SHORTEN_DATA) {
    return {
      ...state,
      modifiedData: action.payload
    };
  }
  if (action.type === REPLACE_TEXT) {
    return {
      ...state,
      replaceTextState: action.payload
    };
  }
  if (action.type === GET_STATUS_CODE) {
    return {
      ...state,
      getStatusCode: action.payload
    };
  }

  if (action.type === TOOLS_MODAL) {
    return {
      ...state,
      toolsModal: action.payload
    };
  }

  if (action.type === GENIE_TOOL_DETAILS) {
    return {
      ...state,
      getGenieToolDetails: action.payload
    };
  }

  if (action.type === READING_LEVEL_MODAL) {
    return {
      ...state,
      readingLevel: action.payload
    };
  }

  if (action.type === ADJUST_TONE) {
    return {
      ...state,
      adjustTone: action.payload
    };
  }

  if (action.type === SELECTED_GENIE_TEXT) {
    return {
      ...state,
      selectedText: action.payload,
      selectedTextWordCount: action.payload?.trim().split(/\s+/).filter(Boolean).length
    };
  }

  if (action.type === SET_RANGE_SELECTION) {
    return {
      ...state,
      rangeSelection: action.payload
    };
  }

  if (action.type === SET_QUILL_SELECTION) {
    return {
      ...state,
      quillSelection: action.payload
    };
  }

  return state;
}
