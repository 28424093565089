import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import Name from 'menu-items/name';
// import sidebarBg from 'assets/designs/sidebar.svg';
import sidebarLogo from 'assets/designs/sidebarLogo.svg';
import onlyGenie from 'assets/designs/onlyGenie.svg';
import { Box } from '@mui/material';
// import BecomeAnAmbassador from 'menu-items/becomeAnAmbassador';
import PropTypes from 'prop-types';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ open }) => (
  <SimpleBar
    sx={{
      '& .simplebar-content': {}
    }}
  >
    <Name />
    <Navigation />
    {/* {open && <BecomeAnAmbassador />} */}
    <Box sx={{ p: 4, pb: 3 }}>
      <img src={open ? sidebarLogo : onlyGenie} alt="sidebarLogo" style={{ width: '100%' }} />
    </Box>
  </SimpleBar>
);

DrawerContent.propTypes = {
  open: PropTypes.bool
};
export default DrawerContent;
