import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemText, Typography, Box } from '@mui/material';

// project import
import { activeItem, openDrawer } from 'store/actions/Menu/Menu';
// import ProIcon from 'components/ProIcon/ProIcon';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isXSScreen, setIsXSScreen] = useState(false);
  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const user = useSelector((state) => state.auth.user);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }
  const checkScreenSize = () => {
    if (window.innerWidth < 1200) {
      setIsXSScreen(true);
    } else {
      setIsXSScreen(false);
    }
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleCloseSidebar = () => {
    dispatch(openDrawer(false));
  };
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        ref={ref}
        {...props}
        to={item.url}
        target={itemTarget}
        onClick={(e) => {
          if (item.onClick) {
            e.preventDefault();
            item.onClick();
          }
        }}
      />
    ))
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  const itemHandler = (id, uu) => {
    console.log(uu);
    dispatch(activeItem(id));
  };
  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;
  const isSelected = openItem === item.id;
  useEffect(() => {
    if (!pathname.includes('/profile') && pathname.includes(item.url)) {
      dispatch(activeItem(item.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const iconSelectedColor = 'primary.contrastText';

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        justifyContent: 'center',
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        mb: 1.5,
        transition: 'none',
        borderRadius: '8px',
        color: item.title === 'Log Out' ? 'red' : 'black',
        alignItems: 'center',

        '&:hover': {
          bgcolor: item.title === 'Log Out' ? 'red' : '#fff',
          color: item.title === 'Log Out' ? '#fff' : 'black',
          transition: 'none'
        },
        '&.Mui-selected': {
          bgcolor: theme.palette.primary.mainBg,
          color: iconSelectedColor,
          '&:hover': {
            color: theme.palette.text.white,
            bgcolor: theme.palette.primary.mainBg,
            transition: 'none'
          }
        }
      }}
    >
      <Box
        sx={{
          transform: item.title === 'Log Out' ? 'rotate(180deg)' : '',
          display: 'flex',
          p: 0.5
        }}
      >
        {itemIcon}
      </Box>
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              onClick={isXSScreen ? handleCloseSidebar : undefined}
              variant="h6"
              sx={{
                position: 'relative',
                ml: 1.5,
                lineHeight: 'normal',
                py: 0.5

                //  mt: 0.3
              }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}

      {drawerOpen && item.title === 'Teammates'
        ? Object.keys(currentPlanwordsTeammates).length === 0
          ? item.proIcon
          : currentPlanwordsTeammates?.planType !== 'business' && item.proIcon
        : drawerOpen &&
          (user?.ambassador === null || user?.ambassador?.adminApproval !== 'approved') &&
          Object.keys(currentPlanwordsTeammates).length === 0 &&
          item.proIcon}
      {drawerOpen && item.title === 'Ambassador' && user?.ambassador === null && user?.isAmbassadorDeleted !== true ? (
        <Typography sx={{ fontSize: '10px', backgroundColor: '#6631a5', padding: '4px 6px 3px 6px', borderRadius: '17px', color: '#fff' }}>
          {item.newText}
        </Typography>
      ) : (
        ''
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
