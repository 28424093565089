import { authApi } from './AxiosInstance';

export function addComments(id, content) {
  return authApi.post(`/project/${id}/comment`, content);
}
export function getComments(id, content) {
  return authApi.get(`/project/${id}/comment`, content);
}
export function hitReplyComment(id, content) {
  return authApi.post(`/project/${id}/reply-comment`, content);
}
