import { authApi } from './AxiosInstance';

export function SpellingAndGrammmerCheck(id) {
  return authApi.post(`/project/${id}/grammerAndSpellingCheck`);
}

export function Plagiarism(id) {
  return authApi.post(`/project/${id}/plagiarism`);
}

export function Tone(id, toneContent) {
  const contentData = { content: toneContent };
  return authApi.post(`/project/${id}/tone-suggestions`, contentData);
}
export function getReadabilityScore(id, text) {
  const contentData = { selectedText: text };
  return authApi.post(`/project/${id}/get-reading-level`, contentData);
}
export function Clarity(id, clarityContent) {
  const clarity_content_Data = { content: clarityContent };
  return authApi.post(`/project/${id}/clarity-suggestions`, clarity_content_Data);
}

export function FactCheck(id, factContent) {
  const fact_content_Data = { content: factContent };
  return authApi.post(`/project/${id}/fact-check`, fact_content_Data);
}

export function acceptSuggestion(projectId, suggestionData) {
  return authApi.post(`project/${projectId}/accept/suggestion`, suggestionData);
}
