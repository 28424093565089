import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import PaginationComp from 'components/Pagination/Pagination';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import './Clients.css';
import { getAmbassadorUserList } from 'store/actions/Ambassador/AmbassadorAction';
import DateFormatter from 'utils/DateFormatter';
import emptyTeammate from 'assets/designs/emptyTeammate.svg';
import TableWrapper from 'components/TableWrapper/TableWrapper';

const ClientTable = ({ search, setSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const { getAmbassadorUsersList, ambUserListcount } = useSelector((state) => state.AmbassadorReducer);

  useEffect(() => {
    if (search && search.length >= 3) {
      dispatch(getAmbassadorUserList(10, 0, search, navigate));
    } else if (search === '') {
      let newSearch = '';
      dispatch(getAmbassadorUserList(limit, offset, newSearch, navigate));
    }
  }, [dispatch, offset, navigate, search, currentPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    setSearch('');
  };

  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ mb: 4, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
              <TableCell className="rowHeader">
                <Typography variant="subtitle11">Name</Typography>
              </TableCell>
              <TableCell align="left" className="rowHeader">
                <Typography variant="subtitle11">Email</Typography>
              </TableCell>
              <TableCell align="left" className="rowHeader">
                <Typography variant="subtitle11">Subscription</Typography>
              </TableCell>
              <TableCell align="left" className="rowHeader">
                <Typography variant="subtitle11">Role</Typography>
              </TableCell>
              <TableCell align="left" className="rowHeader">
                <Typography variant="subtitle11">Joining Date</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAmbassadorUsersList && getAmbassadorUsersList?.length === 0 ? (
              <TableRow
                sx={{
                  '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                    borderColor: 'transparent !important'
                  }
                }}
              >
                <TableCell colSpan={12}>
                  <Box align="center" mt={5}>
                    <img src={emptyTeammate} alt="empty-projects" />
                    <Typography className="primaryHeading">No Members Found</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              getAmbassadorUsersList &&
              getAmbassadorUsersList?.length > 0 &&
              getAmbassadorUsersList?.map((ambassadorList) => {
                return (
                  <TableRow
                    key={ambassadorList?._id}
                    sx={{ borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px', cursor: 'pointer' }}
                    onClick={() => navigate(`/clients/${ambassadorList?._id}`)}
                  >
                    <TableCell sx={{ padding: '29px', cursor: 'pointer' }} onClick={() => navigate(`/clients/${ambassadorList?._id}`)}>
                      <EllipsisTooltip
                        text={`${ambassadorList?.firstName} ${ambassadorList?.lastName !== undefined ? ambassadorList?.lastName : ''}`}
                        variant="subtitle7"
                        maxWidth="120px"
                      />
                    </TableCell>
                    <TableCell align="left" className="rowHeader" sx={{ color: 'black' }}>
                      {ambassadorList?.email}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="rowHeader"
                      sx={{ color: 'black' }}
                      // onClick={() => navigate(`/clients/${ambassadorList?._id}`)}
                    >
                      {ambassadorList?.subscription === 'pro-monthly' || ambassadorList?.subscription === 'pro-annually'
                        ? 'Pro'
                        : ambassadorList?.subscription === 'business-monthly' || ambassadorList?.subscription === 'business-annually'
                        ? 'Business'
                        : 'Free'}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="rowHeader"
                      sx={{ color: 'black' }}
                      // onClick={() => navigate(`/clients/${ambassadorList?._id}`)}
                    >
                      {ambassadorList?.role}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="rowHeader"
                      sx={{ color: 'black' }}
                      // onClick={() => navigate(`/clients/${ambassadorList?._id}`)}
                    >
                      <DateFormatter variant="subtitle9" date={ambassadorList?.createdAt} />
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        {ambUserListcount > 10 && (
          <PaginationComp count={Math.ceil(ambUserListcount / limit)} currentPage={currentPage} onChange={handlePageChange} />
        )}
      </TableContainer>
    </TableWrapper>
  );
};

ClientTable.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.string
};

export default ClientTable;
