import * as React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
export default function PaginationComp({ count, onChange, currentPage }) {
  return (
    <Stack spacing={2} sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
      <Pagination
        page={currentPage}
        count={count}
        variant="outlined"
        boundaryCount={1}
        // color="#6631A5"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            style={
              item.type === 'page' && item.page === currentPage
                ? {
                    backgroundColor: '#6631A5',
                    color: '#fff',
                    borderColor: '#6631A5'
                  }
                : {}
            }
            onMouseEnter={
              item.type === 'page' && item.page !== currentPage
                ? (e) => {
                    e.currentTarget.style.backgroundColor = '#8f7dfd';
                    e.currentTarget.style.color = '#fff';
                    e.currentTarget.style.borderColor = '#8f7dfd';
                  }
                : null
            }
            onMouseLeave={
              item.type === 'page' && item.page !== currentPage
                ? (e) => {
                    e.currentTarget.style.backgroundColor = '';
                    e.currentTarget.style.color = '';
                    e.currentTarget.style.borderColor = '';
                  }
                : null
            }
          />
        )}
        showFirstButton
        showLastButton
        onChange={onChange}
      />
    </Stack>
  );
}
PaginationComp.propTypes = {
  count: PropTypes.number,
  onChange: PropTypes.func,
  currentPage: PropTypes.number
};
