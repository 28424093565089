import { GET_COUPONS } from 'store/actions/Coupons/CouponActions';

const initialState = {
  getCouponsList: []
};

export function CouponsReducer(state = initialState, action) {
  if (action.type === GET_COUPONS) {
    return {
      ...state,
      getCouponsList: action.payload
    };
  }

  return state;
}
