import {
  alreadyExistUser,
  becomeAmbassador,
  ambassadorUrlCheck,
  ambassadorUserList,
  ambassadorUserActivity,
  ambassadorSocialAssetsList,
  generateProfileLink,
  updateAmbassadorUser,
  ambassadorUsersGraph
} from 'services/AmbassadorService';
import { genieLoaderAction, saveUserDetails, snackbarAction } from '../Authentication/AuthActions';
import { handleErrors, subscriptionPlanModalOpen } from '../Projects/ProjectsActions';
export const BECOME_AN_AMBASSADOR = '[become an ambassador] become an ambassador';
export const ALREADY_EXIST_USER = '[already exist user] already exist user';
export const GET_AMBASSADOR_URL_CHECK = '[get ambassador url] get ambassador url';
export const GET_AMBASSADOR_USERS_LIST = '[get ambassador users list] get ambassador users list';
export const LOADING = '[loading action] loading';
export const GET_AMBASSADOR_USER_ACTIVITY = '[get ambassador user activity] get ambassador user activity';
export const GET_AMBASSADOR_SOCIAL_ASSETS_LIST = '[get ambassador social assets list] get ambassador social assets list';
export const GENERATE_PROFILE_LINK = '[generate profile link] generate profile link';
export const UPDATE_AMBASSADOR_DATA = '[update ambassador data] update ambassador data';
export const AMBASSADOR_USERS_GRAPH = '[ambassador users graph] ambassador users graph';
export const GET_PROFILE_LINK_ERR = '[get profile link err] get profile link err';

export function becomeAnAmbassador(userAmbassadorData, navigate) {
  const formData = new FormData();
  formData.append('id', userAmbassadorData.id);
  formData.append('userName', userAmbassadorData.userName);
  formData.append('facebookLink', userAmbassadorData.facebookLink);
  // formData.append('imageUrl', userAmbassadorData.imageUrl);
  formData.append('instagramLink', userAmbassadorData.instagramLink);
  formData.append('linkedinLink', userAmbassadorData.linkedinLink);
  formData.append('profileLink', userAmbassadorData.profileLink);

  return (dispatch) => {
    dispatch(loaderConfirm('becomeAmbassador'));
    becomeAmbassador(formData)
      .then((response) => {
        dispatch(getBecomeAnAmbassador(response?.data));
        dispatch(loaderConfirm(''));
        dispatch(saveUserDetails(navigate));
        let message = {
          code: response?.status,
          message: 'Request successfully send'
        };
        dispatch(snackbarAction(true, message));

        // navigate('/');
      })
      .catch((error) => {
        dispatch(getBecomeAnAmbassador(error?.response?.data));
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
        dispatch(genieLoaderAction(false));
      });
  };
}

export function alreadyUserExist(username, navigate) {
  return (dispatch) => {
    alreadyExistUser(username)
      .then((response) => {
        dispatch(getAlreadyExistUser(response?.data));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getAmbassadorUrl(username, navigate) {
  return (dispatch) => {
    ambassadorUrlCheck(username)
      .then((response) => {
        dispatch(getAmbassadorUrlCheck(response?.data));
        navigate('/brand/ambassador');
        localStorage.setItem('refferalId', response?.data);
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getAmbassadorUserList(limit, offset, search, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    ambassadorUserList(limit, offset, search)
      .then((response) => {
        dispatch(getAmbassadorUsersList(response?.data));
        dispatch(subscriptionPlanModalOpen(false));

        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getAmbassadorUserActivity(userId, navigate) {
  return (dispatch) => {
    ambassadorUserActivity(userId)
      .then((response) => {
        dispatch(getAmbassadorUserActivities(response?.data));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getAmbassadorSocialAssetsList(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    ambassadorSocialAssetsList(navigate)
      .then((response) => {
        dispatch(getSocialAssetList(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function generateProfileUrl(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    generateProfileLink(navigate)
      .then((response) => {
        dispatch(getProfileLink(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        dispatch(getProfileUrlErr(error?.response.status));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function updateAmbassadorData(ambassadorData, navigate) {
  return (dispatch) => {
    // dispatch(loaderConfirm('becomeAmbassador'));
    updateAmbassadorUser(ambassadorData)
      .then((response) => {
        dispatch(getUpdateAmbassador(response?.data));
        dispatch(loaderConfirm(''));
        let message = {
          code: response?.status,
          message: 'User Update successfully'
        };
        dispatch(snackbarAction(true, message));
        dispatch(saveUserDetails(navigate));
        // navigate('/');
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
        dispatch(genieLoaderAction(false));
      });
  };
}

export function getGraphData(monthYearly, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    ambassadorUsersGraph(monthYearly)
      .then((response) => {
        dispatch(getAmbassadorGraphData(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getBecomeAnAmbassador(data) {
  return {
    type: BECOME_AN_AMBASSADOR,
    payload: data
  };
}
export function getAlreadyExistUser(data) {
  return {
    type: ALREADY_EXIST_USER,
    payload: data
  };
}
export function getAmbassadorUrlCheck(data) {
  return {
    type: GET_AMBASSADOR_URL_CHECK,
    payload: data
  };
}
export function loaderConfirm(data) {
  return {
    type: LOADING,
    payload: data
  };
}
export function getAmbassadorUsersList(data) {
  return {
    type: GET_AMBASSADOR_USERS_LIST,
    payload: data.ambassadorUsers,
    count: data.count
  };
}
export function getAmbassadorUserActivities(data) {
  return {
    type: GET_AMBASSADOR_USER_ACTIVITY,
    payload: data
  };
}
export function getSocialAssetList(data) {
  return {
    type: GET_AMBASSADOR_SOCIAL_ASSETS_LIST,
    payload: data.assets,
    count: data.count
  };
}
export function getProfileLink(data) {
  return {
    type: GENERATE_PROFILE_LINK,
    payload: data
  };
}
export function getProfileUrlErr(data) {
  return {
    type: GET_PROFILE_LINK_ERR,
    payload: data
  };
}
export function getUpdateAmbassador(data) {
  return {
    type: UPDATE_AMBASSADOR_DATA,
    payload: data
  };
}
export function getAmbassadorGraphData(data) {
  return {
    type: AMBASSADOR_USERS_GRAPH,
    payload: data.clientGraph,
    totalClicks: data.totalClicks,
    totalClient: data.totalClient
  };
}
