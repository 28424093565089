import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Menu, MenuItem, Radio } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { Select, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reSendUserInvitation, setTeammateStatus, teamMatesList, updateTeammateRole } from 'store/actions/Teammates/TeammatesActions';
import DateFormatter from 'utils/DateFormatter';
import UserProfileImage from 'pages/Profileimage/UserProfileImage';
import PaginationComp from 'components/Pagination/Pagination';
import './Teammates.css';
import CommonDeleteModal from 'components/Modal/CommoneDeleteModal/CommonDeleteModal';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import TableWrapper from 'components/TableWrapper/TableWrapper';
import emptyTeammate from 'assets/designs/emptyTeammate.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const StyledTableCell = styled(TableCell)(() => ({
  backgroundColor: '#FBFBFA',
  boxShadow: 'unset'
}));

function TeammateTabel() {
  const theme = useTheme();
  const [selectOpen, setSelectOpen] = React.useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teammatesList, teammatesCount } = useSelector((state) => state.teammates);
  const { lockPaginationState } = useSelector((state) => state.projects);
  // const [selectedRoles, setSelectedRoles] = React.useState({});
  const [detailsOfTeamMates, setDetailsOfTeamMates] = React.useState({ id: '', case: '', name: '' });
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [owners, setOwners] = React.useState('All');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterTeammates, setFilterTeammates] = useState([]);
  const [getTeammatesbool, setGetTeammatesbool] = useState(false);
  const opens = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (lockPaginationState === true && currentPage !== 1) {
      setCurrentPage(1);
      setOffset(0);
    }
  }, [currentPage, lockPaginationState]);

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (lockPaginationState === true) {
      dispatch(teamMatesList(10, 0, owners, navigate));
    } else {
      dispatch(teamMatesList(limit, offset, owners, navigate));
    }
    setGetTeammatesbool(false);
  }, [dispatch, navigate, offset, currentPage, lockPaginationState, owners, getTeammatesbool]);

  const handleOpen = (data, caseS) => {
    setOpen(true);
    setDetailsOfTeamMates({ id: data.id, case: caseS, name: data.firstName + ' ' + data.lastName });
  };

  const user = useSelector((state) => state.auth.user);
  const handleRoleChange = (teammateId, value) => {
    dispatch(updateTeammateRole(teammateId, { role: value }, navigate));
    setGetTeammatesbool(true);

    setSelectOpen('');
  };

  useEffect(() => {
    if (owners === 'All') {
      setFilterTeammates(teammatesList);
    } else {
      const filteredTeammates = teammatesList?.filter((teammate) => teammate.role === owners);
      setFilterTeammates(filteredTeammates);
    }
  }, [teammatesList, owners]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const rows = [
    {
      logo: <UserProfileImage image={user?.profilePic} />,
      name: `${user?.firstName} ${user?.lastName ? user?.lastName : ''}`,
      email: `${user?.email}`,
      date: user?.updatedAt && <DateFormatter date={user?.updatedAt} />,
      role: 'viewer'
    }
  ];
  return (
    <TableWrapper>
      {/* {teammatesList && teammatesList?.length === 0 ? (
        <Box align="center">
          <img src={emptyTeammate} alt="empty-projects" />
          <Typography className="primaryHeading">No Members Found</Typography>
          <Typography className="infoTextGlobal" sx={{ my: 2 }}>
            You have not added any teammates yet.
          </Typography>
        </Box>
      ) : ( */}
      <TableContainer>
        <Box className="globalAlignment">
          <Typography className="primaryHeading">My Teammates</Typography>
          <Typography className="tableCount" sx={{ mb: 2, fontWeight: 700 }}>
            Total Members ({owners === 'All' ? teammatesCount : filterTeammates.length})
          </Typography>
        </Box>
        <Table>
          <TableHead sx={{ pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
            <TableRow sx={{ textAlign: 'center' }}>
              <TableCell>
                <Typography variant="subtitle10" className="nameText">
                  Member Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle10">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle10">Last Access</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  id="demo-positioned-button"
                  aria-controls={opens ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={opens ? 'true' : undefined}
                  variant="subtitle11"
                  onClick={handleClick}
                  sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                  Set Role <KeyboardArrowDownIcon />
                </Typography>
                <Menu
                  sx={{ mt: 1.5 }}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={opens}
                  onClose={handleClose}
                  className="info"
                >
                  <MenuItem
                    mt={2}
                    onClick={() => {
                      setOwners('All');
                      setAnchorEl(null);
                    }}
                  >
                    <Radio
                      sx={{
                        color: '#6631A5',
                        p: 0,
                        mr: 1,
                        '&.Mui-checked': {
                          color: '#6631A5'
                        }
                      }}
                      checked={owners === 'All'}
                    />
                    All
                  </MenuItem>
                  <MenuItem
                    mt={2}
                    onClick={() => {
                      setOwners('editor');
                      setAnchorEl(null);
                    }}
                  >
                    <Radio
                      sx={{
                        color: '#6631A5',
                        p: 0,
                        mr: 1,
                        '&.Mui-checked': {
                          color: '#6631A5'
                        }
                      }}
                      checked={owners === 'editor'}
                    />
                    Member
                  </MenuItem>
                  <MenuItem
                    mt={2}
                    onClick={() => {
                      setOwners('viewer');
                      setAnchorEl(null);
                    }}
                  >
                    <Radio
                      sx={{
                        color: '#6631A5',
                        p: 0,
                        mr: 1,
                        '&.Mui-checked': {
                          color: '#6631A5'
                        }
                      }}
                      checked={owners === 'viewer'}
                    />
                    Viewer
                  </MenuItem>

                  <MenuItem
                    mt={2}
                    onChange={() => {
                      setOwners('administrator');
                      setAnchorEl(null);
                    }}
                  >
                    <Radio
                      sx={{
                        color: '#6631A5',
                        p: 0,
                        mr: 1,
                        '&.Mui-checked': {
                          color: '#6631A5'
                        }
                      }}
                      checked={owners === 'administrator'}
                    />
                    Admin
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          </TableHead>
          {filterTeammates?.length > 0 && (
            <TableBody sx={{ pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }} lg={12} sm={12} xs={12} md={12}>
              {rows.length > 0 &&
                rows.map((row, index) => (
                  <TableRow key={row.id || index}>
                    {currentPage === 1 && (
                      <>
                        <StyledTableCell sx={{ width: '28% !important' }}>
                          <Grid
                            sx={{
                              mt: 1,
                              display: 'flex',
                              alignItems: 'center',
                              maxWidth: '350px !important'
                            }}
                            className="tableContent"
                          >
                            {row.logo}
                            {/* <Typography variant="subtitle7"> */}
                            &nbsp; &nbsp;
                            <EllipsisTooltip text={`${row.name}`} variant="subtitle7" maxwidth="290px !important" />
                            {/* {row.name} */}
                            {/* </Typography> */}
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell className="tableContent">
                          <Typography variant="body3"> {row.email}</Typography>
                        </StyledTableCell>
                        <StyledTableCell className="tableContent" sx={{ m: 2 }}>
                          <Typography variant="body3">{row.date}</Typography>
                        </StyledTableCell>
                        <StyledTableCell className="tableContent" sx={{ m: 2, pl: 3 }}>
                          <Typography variant="body3"> Owner</Typography>
                        </StyledTableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          )}

          <TableBody sx={{ pb: 2 }}>
            {filterTeammates && filterTeammates?.length > 0 ? (
              filterTeammates?.map((row) => {
                return (
                  <TableRow key={row.id} sx={{ pb: 2, borderBottom: 2, borderColor: 'grey.300' }}>
                    <StyledTableCell sx={{ width: '28% !important' }}>
                      <Grid
                        sx={{
                          mt: 1,
                          display: 'flex',
                          alignItems: 'center',
                          maxWidth: '350px !important'
                        }}
                        className="tableContent"
                      >
                        <UserProfileImage image={row.status === 'pending' ? '' : row.teammateId?.profilePic} />
                        &nbsp; &nbsp;
                        <EllipsisTooltip
                          text={row.status === 'pending' ? 'Invited' : `${row.teammateId?.firstName} ${row.teammateId?.lastName}`}
                          variant="subtitle7"
                          maxwidth="290px !important"
                        />
                        {/* <span>{row.status === 'pending' ? 'Invited' : row.teammateId?.firstName + ' ' + row.teammateId?.lastName}</span> */}
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell className="tableContent">{row.teammateId?.email}</StyledTableCell>
                    <StyledTableCell className="tableContent" sx={{ m: 2 }}>
                      {row.status === 'pending' ? 'Pending' : <DateFormatter date={row.updatedAt} />}
                    </StyledTableCell>
                    <StyledTableCell className="tableContent" sx={{ m: 2 }}>
                      <Select
                        key={row.teammateId.id}
                        open={selectOpen === row.teammateId.id ? true : false}
                        onOpen={() => setSelectOpen(row.teammateId.id)}
                        onClose={() => setSelectOpen('')}
                        className="actionSelector"
                        value={row.role === 'administrator' ? 'Admin' : row.role === 'editor' ? 'Member' : 'viewer'}
                        onChange={(event) => {
                          handleRoleChange(row.teammateId.id, event.target.value);
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .MuiSelect-select': {
                                paddingLeft: 2
                              },
                              borderRadius: '16px'
                            }
                          }
                        }}
                        sx={{
                          outline: 'none',
                          boxShadow: 'none !important',
                          p: 0,
                          '.MuiOutlinedInput-notchedOutline': { border: 0 }
                        }}
                        renderValue={(value) => value.charAt(0).toUpperCase() + value.slice(1)}
                      >
                        <>
                          <MenuItem
                            sx={{
                              textTransform: 'capitalize',
                              backgroundColor: 'transparent !important ',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                              }
                              // pt: 2,
                              // pb: 1
                            }}
                            value="viewer"
                            onClick={() => {
                              handleRoleChange(row.teammateId.id, 'viewer');
                            }}
                          >
                            <ListItemText sx={{ pl: 0 }} primary="viewer" />

                            {row.role === 'viewer' && (
                              <ListItemIcon style={{ marginLeft: 'auto' }}>
                                <CheckIcon />
                              </ListItemIcon>
                            )}
                          </MenuItem>
                          <MenuItem
                            sx={{
                              textTransform: 'capitalize',
                              backgroundColor: 'transparent !important ',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                              }
                            }}
                            value="administrator"
                            // value="admin"
                            // p={2}
                            onClick={() => {
                              handleRoleChange(row.teammateId.id, 'administrator');
                            }}
                          >
                            <ListItemText primary="admin" />

                            {row.role === 'administrator' && (
                              <ListItemIcon style={{ marginLeft: 'auto' }}>
                                <CheckIcon />
                              </ListItemIcon>
                            )}
                          </MenuItem>
                          <MenuItem
                            sx={{
                              textTransform: 'capitalize',
                              backgroundColor: 'transparent !important ',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                              }
                            }}
                            value="editor"
                            onClick={() => {
                              handleRoleChange(row.teammateId.id, 'editor');
                            }}
                            // p={2}
                          >
                            <ListItemText primary="member" />

                            {row.role === 'editor' && (
                              <ListItemIcon style={{ marginLeft: 'auto' }}>
                                <CheckIcon />
                              </ListItemIcon>
                            )}
                          </MenuItem>

                          <Box
                            sx={{
                              width: 200,
                              // m: 1.9,
                              // border: 1,
                              color: '#CED4DA'
                            }}
                          ></Box>
                          {row.status === 'pending' && (
                            <>
                              <MenuItem
                                onClick={() => {
                                  dispatch(reSendUserInvitation({ teammateId: row.teammateId?.id }, navigate));
                                  setSelectOpen('');
                                }}
                                sx={{ bgcolor: theme.palette.background.white, color: theme.palette.text.black, px: 2 }}
                              >
                                Resend Invitation
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleOpen(row.teammateId, 'cancelled');
                                  setSelectOpen('');
                                }}
                                sx={{ bgcolor: theme.palette.background.white, color: theme.palette.text.black, px: 2 }}
                              >
                                Cancel Invitation
                              </MenuItem>
                            </>
                          )}
                          {row.status !== 'pending' && (
                            <MenuItem
                              onClick={() => {
                                handleOpen(row.teammateId, 'revoked');
                                setSelectOpen('');
                              }}
                              sx={{ bgcolor: theme.palette.background.white, color: theme.palette.text.black, px: 2 }}
                            >
                              Delete Member
                            </MenuItem>
                          )}
                        </>
                      </Select>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={4}>
                  <Box align="center" sx={{ pt: 5 }}>
                    <img src={emptyTeammate} alt="empty-projects" />
                    <Typography className="primaryHeading">No Members Found</Typography>
                    <Typography className="infoTextGlobal" sx={{ my: 2 }}>
                      You have not added any teammates yet.
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {teammatesCount > 10 && (
          <PaginationComp count={Math.ceil(teammatesCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
        )}
      </TableContainer>
      {/* )} */}
      <CommonDeleteModal
        handleDelete={() => {
          dispatch(setTeammateStatus({ status: detailsOfTeamMates.case, userId: [detailsOfTeamMates.id] }, navigate));
          setOpen(false);
        }}
        open={open}
        setOpen={setOpen}
        title={`Are you sure you want to remove? `}
        description={`Removing ${detailsOfTeamMates?.name}? as a teammate means they will lose access to all their saved projects.`}
        cancelText="Not Now"
        deleteText="Yes, Remove"
      />
    </TableWrapper>
  );
}

export default TeammateTabel;
