import { lazy } from 'react';
import Loadable from 'components/Loadable';

import CommonLayout from 'layout/CommonLayout/index';

// render - login
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword/ResetPassword')));
const EmailVerification = Loadable(lazy(() => import('pages/authentication/EmailVerification/EmailVerification')));
const EmailVerifyOTP = Loadable(lazy(() => import('pages/authentication/EmailVerifyOTP/EmailVerifyOTP')));
const TeammateIntation = Loadable(lazy(() => import('pages/teammates/InviteTeamates')));

const CommonRoutes = {
  path: 'auth',
  element: <CommonLayout />,
  children: [
    {
      path: 'reset-password/:id',
      element: <ResetPassword />
    },
    {
      path: 'verify-email/otp',
      element: <EmailVerifyOTP />
    },
    {
      // path: 'verify-email/:id',
      path: 'verify-email',
      element: <EmailVerification />
    },
    {
      path: 'team-mates-invite',
      element: <TeammateIntation />
    }
  ]
};

export default CommonRoutes;
