import { createPost, deleteSite, getUserSites, getWordPressCategories, mediumSiteUrl } from 'services/MediumAndWordPressServices';
import {
  genieLoaderAction
  // snackbarAction
} from '../Authentication/AuthActions';
import { handleErrors } from '../Projects/ProjectsActions';
export const MEDIUM_SITE_URL = '[medium site url] medium site url';
export const GET_USER_SITES = '[get user sites] get user sites';
export const GET_CREATE_POST = '[get create post] get create post';
export const DELETE_SITE = '[delete site] delete site';
export const GET_WORDPRESS_CATEGORIES_LIST = '[get categories] get categories';

export function getUserSite(siteType, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getUserSites(siteType)
      .then((response) => {
        dispatch(userSitesUrl(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function mediumWebsiteUrl(access_token, siteType, setWPressForm, setWordPressSteps, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    mediumSiteUrl(access_token)
      .then((response) => {
        dispatch(getMediumsiteUrl(response?.data));
        dispatch(getUserSite(siteType, navigate));
        dispatch(genieLoaderAction(false));
        setWPressForm(false);
        setWordPressSteps('3');
      })
      .catch((error) => {
        setWPressForm(true);
        setWordPressSteps('1');
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function userCreatePost(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    createPost(content)
      .then((response) => {
        if (response.status === 201) {
          dispatch(getCreatePost(response?.data));
          window.open(response?.data?.postURL, '_blank');
          dispatch(genieLoaderAction(false));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function deleteWebsite(id, siteName, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    deleteSite(id)
      .then((response) => {
        dispatch(getDeleteSite(response?.data));
        dispatch(getUserSite(siteName));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getWordPressCategoriesList(siteId, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getWordPressCategories(siteId)
      .then((response) => {
        dispatch(getCategoriesList(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getMediumsiteUrl(data) {
  return {
    type: MEDIUM_SITE_URL,
    payload: data
  };
}

export function userSitesUrl(data) {
  return {
    type: GET_USER_SITES,
    payload: data
  };
}

export function getCreatePost(data) {
  return {
    type: GET_CREATE_POST,
    payload: data
  };
}

export function getDeleteSite(data) {
  return {
    type: DELETE_SITE,
    payload: data
  };
}

export function getCategoriesList(data) {
  return {
    type: GET_WORDPRESS_CATEGORIES_LIST,
    payload: data
  };
}
