import { myTransactionLists } from 'services/SubscriptionService';
import {
  changeDocTitle,
  getNewTitle,
  getProjectDetails,
  projectDetails,
  projectStatus,
  recentProjects,
  saveProject,
  saveProjectAllData,
  saveProjectOutline,
  saveProjectSubOutline,
  DeleteProject,
  myProjects,
  getNewOneTitle,
  getNewOneOutline,
  getNewAllOutlines,
  getNewOneSubOutline,
  deleteTitle,
  deleteOutline,
  getNewAllSubOutlines,
  deleteSubOutline,
  generateContent,
  getSharedProjects,
  deletePermanentProject,
  getDeleteProjectsList,
  getRestoreProject,
  postSaveFinalContent,
  lockProject,
  restoreAllProjects,
  getSharedProjectOwnersList
} from '../../../services/ProjectService';
import { Logout, genieLoaderAction, loaderAction, openLampLoader, snackbarAction } from '../Authentication/AuthActions';
import { teamMatesList } from '../Teammates/TeammatesActions';
import { setMyTransactionList } from '../Subscription/SubscriptionActions';
export const RECENT_PROJECTS_LIST = '[recent projects] user recent projects list';
export const MY_PROJECTS_LIST = '[my projects] user my projects list';
export const NEW_PROJECTS = '[new project] user new project';
export const PROJECT_STATUS = '[project status] user project status';
export const SAVE_PROJECT_CONTENT = '[save project content] user project content';
export const UPDATE_DOC_TITLE = '[update doc title] user doc title';
export const PROGRESS_BAR_STATE = '[progress bar state] user progress bar state';
export const DELETE_PROJECTS = '[delete doc title] user project id';
export const MY_PROJECT_STATUS = '[update doc status] user project id';
export const SHARED_PROJECTS_LIST = '[shared project status] user project id';
export const PRT_DELETE_PROJECTS = '[ permanent delete doc title] user project id';
export const PRT_DELETE_PROJECTS_List = '[ delete project list doc title] user project id';
export const RESTORE_DELETE_PROJECTS = '[ restore delete project list ] user project id';
export const SUBSCRIPTION_PLAN_MODAL = '[ subscription plan modal ] user project id';
export const CONFIRMATION_LOCK = '[ confirmation lock ] user project id';
export const HANLDELOCKPAGINATION = '[ handle lock ] user project id';
export const HANDLESERVICEUNAVAILABLE = '[ handle service unavailable ] handle error';
export const RESTORE_All_PROJECTS = '[ restore all delted projects ] user';
export const SHARED_PROJECTS_OWNERS_LIST = '[shared project owners list] user project id';
export const SET_OWNERS_ID = '[set owners id] user project id';
export function handleErrors(dispatch, error, navigate) {
  dispatch(genieLoaderAction(false));
  if (error.response?.data?.code === 401) {
    dispatch(Logout(navigate));
  }

  let message = {
    code: error.response?.data.code,
    message: error.response?.data.message
  };
  dispatch(snackbarAction(true, message));
  console.log(error, 'error');
}

export function recentProjectList(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    recentProjects()
      .then((response) => {
        dispatch(setRecentProjectList(response?.data?.projectList));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function lockProjectAction(id, content, pathname, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    lockProject(id, content)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(handlelockState(true));
        dispatch(genieLoaderAction(false));

        let message = {
          code: response?.status,
          message: response?.data[0].projectLock ? 'Project locked successfully' : 'Project unlocked successfully'
        };
        dispatch(snackbarAction(true, message));
        dispatch(getMyProjectList(pathname === '/' ? 5 : 10, 0, '', navigate));

        dispatch(openConfirmationLock(false, '', null));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
        dispatch(openConfirmationLock(false, '', null));
      });
  };
}

export function getMyProjectList(limit, offset, search, navigate) {
  return (dispatch) => {
    // dispatch(genieLoaderAction(true));
    myProjects(limit, offset, search)
      .then((response) => {
        dispatch(setMyProjectList(response?.data));
        let role = '';
        dispatch(teamMatesList(10, 0, role, navigate));
        dispatch(genieLoaderAction(false));
        dispatch(handlelockState(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function myTransactionList(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    myTransactionLists()
      .then((response) => {
        dispatch(setMyTransactionList(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function sharedProjectList(limit, offset, search, owner, navigate) {
  return (dispatch) => {
    getSharedProjects(limit, offset, search, owner)
      .then((response) => {
        dispatch(setsharedProjectList(response?.data));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function sharedProjectOwnersList(navigate) {
  return (dispatch) => {
    getSharedProjectOwnersList()
      .then((response) => {
        dispatch(setsharedProjectOwnersList(response?.data));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function updateOneSubOutline(id, subOutlineId, navigate) {
  return (dispatch) => {
    getNewOneSubOutline(id, subOutlineId)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Single suboutline updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}
export function updateOneOutline(id, outlineId, navigate) {
  return (dispatch) => {
    getNewOneOutline(id, outlineId)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Single outline updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}

export function updateOneTitle(id, titleId, navigate) {
  return (dispatch) => {
    getNewOneTitle(id, titleId)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Title updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}
export function deleteProjectTitle(id, titleId, navigate) {
  return (dispatch) => {
    deleteTitle(id, titleId)
      .then((response) => {
        // dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Title deleted successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function deleteProjectSubOutline(id, outlined, navigate) {
  return (dispatch) => {
    deleteSubOutline(id, outlined)
      .then((response) => {
        // dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Suboutline deleted successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function submitGenerateContent(id, suboutlines, navigate) {
  return (dispatch) => {
    generateContent(id, suboutlines)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Suboutline deleted successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function deleteProjectOutline(id, outlined, navigate) {
  return (dispatch) => {
    deleteOutline(id, outlined)
      .then((response) => {
        // dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Outline deleted successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function changeTitle(title, id, navigate) {
  return (dispatch) => {
    dispatch(loaderAction('changeTitle'));
    changeDocTitle(title, id)
      .then((response) => {
        let message = {
          code: response?.status,
          message: 'Document Title Updated'
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
      })
      .catch((error) => {
        dispatch(loaderAction(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function createNewProject(setStatus, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    dispatch(loaderAction('createDocument'));
    projectDetails()
      .then((response) => {
        dispatch(loaderAction(''));
        if (response?.data[0]?._id) {
          dispatch(setProjectStatus({ isActiveGenie: setStatus }, response?.data[0]?._id, navigate));
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getProject(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    let data = {};
    dispatch({
      type: NEW_PROJECTS,
      payload: data
    });
    let role = '';
    getProjectDetails(id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(genieLoaderAction(false));
        dispatch(teamMatesList(10, 0, role, navigate));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function saveGenieContent(content, id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    dispatch(loaderAction('saveContent'));
    postSaveFinalContent(content, id)
      .then((response) => {
        dispatch(getProject(id, navigate));
        dispatch(genieLoaderAction(false));
        let message = {
          code: response?.status,
          message: 'Content saved successfully'
        };
        dispatch(snackbarAction(true, message));
        dispatch(loaderAction(''));
        // navigate('/');
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
        dispatch(loaderAction(''));
      });
  };
}
export function setProjectAllData(id, content, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, 'Content'));
    saveProjectAllData(id, content)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function updateNewAllOutlines(id, navigate) {
  return (dispatch) => {
    getNewAllOutlines(id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Outlines updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}

export function updateNewAllSubOutlines(id, outlineId, navigate) {
  return (dispatch) => {
    getNewAllSubOutlines(id, outlineId)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Suboutlines updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}
export function updateNewTitle(id, navigate) {
  return (dispatch) => {
    getNewTitle(id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        let message = {
          code: response?.status,
          message: 'Titles updated successfully'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        } else if (error.response?.status === 429) {
          // if (error.response?.data?.code === 400) {
          dispatch(getServiceUnavaiable(error.response));
        } else {
          dispatch(getServiceUnavaiable(error.response));
        }
        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        console.log(error, 'error');
      });
  };
}

export function setProjectStatus(status, id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    projectStatus(status, id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        navigate(`/project/${response?.data[0]?._id}`);
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function quitGenieData(status, id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    projectStatus(status, id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function setProjectOutline(content, id, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, 'Outlines'));
    saveProjectOutline(content, id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openLampLoader(false));

        handleErrors(dispatch, error, navigate);
      });
  };
}

export function setProjectSubOutline(outlines, id, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, 'Sub-Outlines'));
    saveProjectSubOutline(outlines, id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        dispatch(openLampLoader(false));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function DeleteProjects(id, pathname, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    DeleteProject(id)
      .then((response) => {
        dispatch(getMyProjectList(pathname === '/' ? 5 : 10, 0, '', navigate));
        dispatch(handlelockState(true));
        dispatch(recentProjectList(navigate));
        let message = {
          code: response?.status,
          message: 'Deleted'
        };
        dispatch(snackbarAction(true, message));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        dispatch(updateProjectStatus('unsucessfull'));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function DeleteProjectsList(limit, offset, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getDeleteProjectsList(limit, offset)
      .then((response) => {
        dispatch(getDeleteProjectlist(response.data));
        dispatch(handlelockState(false));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function permanentDeleteProjects(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    deletePermanentProject(id)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(DeleteProjectsList(10, 0, navigate));
        dispatch(handlelockState(true));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function restoreProjects(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getRestoreProject(id)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(DeleteProjectsList(10, 0, navigate));
        dispatch(handlelockState(true));
        dispatch(genieLoaderAction(false));
        let message = {
          code: 204,
          message: 'Moved to your Projects'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(updateProjectStatus('unsucessfull'));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function saveProjectContent(content, id, navigate) {
  return (dispatch) => {
    dispatch(openLampLoader(true, 'Titles'));
    // dispatch(loaderAction('questions'));
    saveProject(content, id)
      .then((response) => {
        dispatch(getCreatedProject(response?.data));
        dispatch(confirmProjectSave(response?.data));
        dispatch(openLampLoader(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
        dispatch(openLampLoader(false));
      });
  };
}

export function restoreAllDeletedProjects(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    restoreAllProjects()
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch(DeleteProjectsList(10, 0, navigate));
        dispatch(genieLoaderAction(false));
        let message = {
          code: 204,
          message: 'Moved to your Projects'
        };
        dispatch(snackbarAction(true, message));
      })
      .catch((error) => {
        dispatch(updateProjectStatus('unsucessfull'));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function confirmProjectSave(data) {
  return {
    type: SAVE_PROJECT_CONTENT,
    payload: data
  };
}

export function openConfirmationLock(value, id, lockState) {
  return {
    type: CONFIRMATION_LOCK,
    payload: value,
    id: id,
    lockState: lockState
  };
}

export function handlelockState(lockPagination) {
  return {
    type: HANLDELOCKPAGINATION,
    payload: lockPagination
  };
}

export function setRecentProjectList(data) {
  return {
    type: RECENT_PROJECTS_LIST,
    payload: data
  };
}
export function setMyProjectList(data) {
  return {
    type: MY_PROJECTS_LIST,
    payload: data.results,
    count: data.count
  };
}
export function setsharedProjectList(data) {
  return {
    type: SHARED_PROJECTS_LIST,
    payload: data.projectList,
    count: data.count
  };
}
export function setsharedProjectOwnersList(data) {
  return {
    type: SHARED_PROJECTS_OWNERS_LIST,
    payload: data
  };
}
export function subscriptionPlanModalOpen(data, type) {
  const rr = type !== undefined ? type : '';
  return {
    type: SUBSCRIPTION_PLAN_MODAL,
    payload: data,
    modalType: rr
  };
}
export function updateProjectStatus(data) {
  return {
    type: MY_PROJECT_STATUS,
    payload: data
  };
}
export function getCreatedProject(data) {
  return {
    type: NEW_PROJECTS,
    payload: data
  };
}

export function getDeleteProjectlist(data) {
  return {
    type: PRT_DELETE_PROJECTS_List,
    payload: data.projectList,
    count: data.count
  };
}
export function getServiceUnavaiable(data) {
  return {
    type: HANDLESERVICEUNAVAILABLE,
    payload: data
  };
}

export function setOwnersId(data) {
  return {
    type: SET_OWNERS_ID,
    payload: data
  };
}
