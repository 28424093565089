import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Grid, Tooltip, Box } from '@mui/material';
import PropTypes from 'prop-types';
function UserProfileImage({ image, styles, title, width }) {
  const iconStyle = {
    width: '40px',
    height: '40px'
  };
  return (
    <Grid item>
      <Tooltip title={title} placement="right">
        <Box className="faUserCircleBox">
          {image ? (
            <img
              src={image}
              alt="Uploaded"
              width={styles?.width ? styles?.width : '40px'}
              height={styles?.width ? styles?.width : '40px'}
              style={{ borderRadius: '50%', cursor: 'pointer', width: width }}
              className="userCircleImg"
            />
          ) : (
            <FaUserCircle style={styles?.width ? styles : iconStyle} className="faUserCircleIcon" />
          )}
        </Box>
      </Tooltip>
    </Grid>
  );
}
UserProfileImage.propTypes = {
  image: PropTypes.string,
  styles: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.string
};
export default UserProfileImage;
