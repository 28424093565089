import React, {
  useState
  //  useEffect
} from 'react';
import { Grid, Typography, Box, Tab, Button, Dialog, IconButton, FormHelperText, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Formik } from 'formik';
// import SocialAssets from 'pages/SocialAssets/SocialAssets';
import ProfileLinkForm from './ProfileLinkForm';
import AmbassadorDashboard from './AmbassadorDashboard';
import './AmbassadorPortal.css';
import BrandKit from './BrandKit';
import editIcon from '../../assets/images/ambassadorpage/editIcon.svg';
import copyIcon from '../../assets/images/ambassadorpage/copyIcon.svg';
import crossButton from 'assets/designs/crossButton.svg';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import * as Yup from 'yup';
import { ThemeProvider } from '@mui/material/styles';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import { updateAmbassadorData } from 'store/actions/Ambassador/AmbassadorAction';
import { snackbarAction } from 'store/actions/Authentication/AuthActions';
import thankyouImg from 'assets/designs/thankyou.svg';
const AmbassadorPortal = () => {
  const [value, setValue] = useState('1');
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCopy = () => {
    const input = document.getElementById('customUrlInput');
    if (input) {
      input.select();
      document.execCommand('copy');
    }
    let message = {
      code: 204,
      message: 'Copied to clipboard'
    };
    dispatch(snackbarAction(true, message));
  };

  return (
    <>
      <Grid sx={{ pl: { xs: 0, md: 3, sm: 3, lg: 3 }, pt: 12 }} container>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          {user?.ambassador === null ? (
            <Box className="globalShadow" sx={{ p: 6 }}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h3" className="primaryColor">
                  Apply to be an Ambassador today!
                </Typography>
                <Typography className="descText" mt={2}>
                  Welcome to the WriterGenie Ambassadors program! Join us in empowering bloggers with cutting-edge AI as you represent our
                  <br />
                  platform, engage our community, and help writers achieve their best. Let's shape the future of blogging together!
                </Typography>
              </Box>
              <ProfileLinkForm />
            </Box>
          ) : (
            (user?.ambassador?.adminApproval === 'pending' || user?.ambassador?.adminApproval === 'disapproved') && (
              <Box className="globalShadow" sx={{ p: { lg: 5, md: 5, sm: 5, xs: 2 } }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h3" className="primaryColor">
                    {user?.ambassador?.adminApproval === 'pending' || user?.ambassador?.adminApproval === 'disapproved'
                      ? 'Thanks for your submission! Check your email for approval.'
                      : 'Thank you for joining!'}
                  </Typography>
                </Box>
                <Grid container md={12} lg={12}>
                  <Grid item lg={1} md={1} sm={1}></Grid>
                  <Grid
                    item
                    md={10}
                    xs={12}
                    sm={10}
                    lg={10}
                    className={user?.ambassador?.adminApproval === 'disapproved' ? 'imageText' : 'thankyouImage'}
                    align="center"
                    mt={4}
                    sx={{ height: '50vh' }}
                  >
                    <Typography sx={{ textAlign: 'center', px: { xs: 1 } }}>
                      {user?.ambassador?.adminApproval === 'disapproved' ? (
                        'YOUR APPLICATION IS DISAPPROVED'
                      ) : (
                        <img className="thankyouImg" src={thankyouImg} alt="thankyou" />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )
          )}
          {user?.ambassador?.adminApproval === 'approved' && (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h3" className="primaryColor">
                  Ambassador Portal
                </Typography>
              </Box>

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} className="TabContext">
                  <Box className="TabContextBox">
                    <TabList onChange={handleChange} className="TabContextList">
                      <Tab label="Dashboard" value="1" className={value === '1' ? 'activeTabContext' : ''} />
                      <Tab label="Brand Kit" value="2" className={value === '2' ? 'activeTabContext' : ''} />
                      {/* <Tab label="Social Assets" value="3" className={value === '3' ? 'activeTabContext' : ''} /> */}
                    </TabList>
                    <Box className="customInputBox" sx={{ mt: { xs: 2 } }}>
                      <Typography sx={{ width: '100%', mr: 2 }}>Custom URL:</Typography>
                      <Box className="emailEditor customInputBox">
                        <input
                          type="text"
                          name="name"
                          placeholder="Custom Url"
                          readOnly
                          value={user?.ambassador?.adminApproval === 'approved' && user?.ambassador?.profileLink}
                          className="customUrlInput"
                          id="customUrlInput"
                        />
                        {user.ambassador.edited ? (
                          <Box sx={{ cursor: 'pointer', mr: 2 }} onClick={() => setOpen(true)}>
                            <img src={editIcon} alt="" />
                          </Box>
                        ) : (
                          <Box sx={{ cursor: 'pointer', mr: 2 }} onClick={handleCopy}>
                            <img src={copyIcon} alt="" />
                            {/* <img src={copyIcon} alt="" /> */}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <TabPanel sx={{ padding: '12px 0px 12px 0px !important', mt: 1 }} value="1">
                    <AmbassadorDashboard />
                  </TabPanel>
                  <TabPanel sx={{ padding: '12px 0px 12px 0px !important', mt: 3 }} value="2">
                    <BrandKit />
                  </TabPanel>
                </TabContext>
              </Box>
              <Dialog
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="customized-dialog-title"
                open={open}
                onClose={() => setOpen(false)}
                sx={{ '& .MuiDialog-paper': { borderRadius: '16px', maxWidth: '520px !important' } }}
              >
                <Box p={4}>
                  <IconButton aria-label="close" onClick={() => setOpen(false)} className="crossButton">
                    <img src={crossButton} alt="cross" />
                  </IconButton>
                  <Box>
                    <Box align="center">
                      <Box className="logo-container">
                        <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
                      </Box>
                      <Typography align="center" className="docHeadingText" my={3}>
                        <span style={{ color: 'red', fontWeight: '600' }}>Reminder:</span> Single Edit Allowed for Your Link
                      </Typography>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          profile: user.ambassador.userName || '',

                          submit: null
                        }}
                        validationSchema={Yup.object().shape({
                          profile: Yup.string().matches(
                            /^(?=.*[a-zA-Z0-9_])[a-zA-Z0-9_]*$/,
                            'Must contain only letters, numbers, and underscores'
                          )
                        })}
                        onSubmit={async (values) => {
                          const userAmbassadorData = {
                            id: user?.id,
                            userName: values.profile,
                            profileLink: `${user?.ambassador?.baseLink}${values.profile}`,
                            edited: false
                          };
                          dispatch(updateAmbassadorData(userAmbassadorData, navigate));
                          setOpen(false);
                        }}
                      >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="profile-signup" align="left">
                                    Profile Url
                                  </InputLabel>
                                  <Box className="profileInputs">
                                    <Grid md={8}>
                                      <ThemeProvider theme={TextFieldDesign}>
                                        <OutlinedInput
                                          id="profile-login"
                                          type="text"
                                          value={user?.ambassador?.baseLink}
                                          fullWidth
                                          readonly
                                        />
                                      </ThemeProvider>
                                    </Grid>
                                    <Grid md={4}>
                                      <ThemeProvider theme={TextFieldDesign}>
                                        <OutlinedInput
                                          id="profile-login"
                                          type="text"
                                          className="inputProfile"
                                          value={values.profile}
                                          name="profile"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder="Enter Profile Url"
                                          fullWidth
                                          error={Boolean(touched.profile && errors.profile)}
                                        />
                                      </ThemeProvider>
                                      {touched.profile && errors.profile && (
                                        <FormHelperText error id="helper-text-profile-signup">
                                          {errors.profile}
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  </Box>
                                </Stack>
                              </Grid>

                              {errors.submit && (
                                <Grid item xs={12}>
                                  <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                              )}
                              {/* <Grid container spacing={2} mt={6}> */}
                              <Grid item lg={6} md={6} xl={6} xs={12}>
                                <Button
                                  fullWidth
                                  sx={{ px: 5, borderRadius: '8px !important', minWidth: '100%' }}
                                  className="globalButtons"
                                  onClick={() => setOpen(false)}
                                >
                                  Cancel
                                </Button>
                              </Grid>

                              <Grid item lg={6} md={6} xl={6} xs={12}>
                                <Button fullWidth type="submit" sx={{ px: 5 }} className="globalButtonSecond">
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                            {/* </Grid> */}
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </Box>
              </Dialog>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AmbassadorPortal;
