import axios from 'axios';
import { refreshTokenApi } from './AuthService';
export const apiUrl = process.env.REACT_APP_API_URL;
export const authApi = axios.create({
  baseURL: apiUrl
});
let refresh = false;
authApi.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  async (error) => {
    if (error.response?.status === 401 && !refresh) {
      refresh = true;
      const response = await refreshTokenApi({ refreshToken: JSON.parse(localStorage.getItem('refreshToken')) });

      if (response.status === 200) {
        const newToken = response.data['token'];
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        refresh = false;
        return axios(error.config);
      }
    }

    refresh = false;
    return Promise.reject(error);
  }
);
