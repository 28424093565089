import { authApi } from './AxiosInstance';

export function shareDocuments(id, content) {
  return authApi.post(`/project/${id}/share-documents`, content);
}
export function sharedDocumentPermission(id, content) {
  return authApi.post(`/project/${id}/permission`, content);
}
export function transferOwner(id, content) {
  return authApi.post(`/project/${id}/transfer-owner-invitation`, content);
}
export function transferOwnerDetails(id) {
  return authApi.post(`/project/owner-invite/${id}`);
}
export function transferOwnerEmail(id, content) {
  return authApi.post(`/project/transfer-owner/${id}`, content);
}
