import { getSingleUserSubscriptions } from 'services/SubscriptionService';
import {
  forgotPasswordEmail,
  hitVerifyEmail,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  userDetails,
  createNewPassword,
  refreshTokenApi,
  logoutUser,
  confirmPasswordUser,
  // updatePassword,
  postProfilePic,
  updateUserDetails,
  socialLogin,
  deleteProfilePic,
  verificationEmail
} from '../../../services/AuthService';
import moment from 'moment';
import { subscriptionPlanModalOpen } from '../Subscription/SubscriptionActions';
import { handleErrors } from '../Projects/ProjectsActions';
export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const LOGIN_USER_ACTION = '[login action] login user';
export const SNACBAR_ACTION = '[snacbar action] snacbar user';
export const VERIFY_EMAIL = '[verifyemail action] verifyemail';
export const RESET_PASSWORD = '[reset password action] reset password';
export const FORGOT_PASSWORD = '[forgot password action] forgot password';
export const VERIFY_EMAIL_FAILED_ACTION = '[verifyemail action failed] verifyemail failed ';
export const REFRESH_TOKEN_CONFIRMED_ACTION = '[refresh token action] refresh token';
export const LOADING = '[loading action] loading';
export const CONFIRM_PASSWORD = '[confirm password action] confirm password';
export const UPDATE_PASSWORD = '[update password action] update password';
export const GENIE_LOADER_ACTION = '[genie loader action] genie loader';
export const UPDATE_USER_NAME = '[UPDATE_USER_NAME] user name';
export const LAMP_LOADER_MODAL = '[LAMP_LOADER_MODAL] lamp loader modal';
export const GET_VERIFICATION_EMAIL = '[get verification email] verification email';
const register_type = localStorage.getItem('register_type');
export const BLACK_FRIDAY_MODAL = '[BLACK_FRIDAY_MODAL] black friday modal';

export function sendVerificationEmail(userCredential, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm('verificationEmail'));
    verificationEmail(userCredential)
      .then((response) => {
        getVerificationEmail(response?.data);

        navigate('/auth/verify-email/otp', {
          state: {
            firstName: userCredential.firstName,
            lastName: userCredential.lastName,
            email: userCredential.email,
            password: userCredential.password,
            register_type: userCredential.register_type
          }
        });
        dispatch(loaderConfirm(''));
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
        dispatch(genieLoaderAction(false));
      });
  };
}

export function signupAction(userCreadentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm('register'));
    signUp(userCreadentials)
      .then((response) => {
        // if exists clear register_type from localstorage

        const data = {
          expiresIn: moment(response?.data?.tokens?.access?.expires).valueOf(),
          idToken: response?.data?.tokens?.access?.token,
          refreshToken: response?.data?.tokens?.refresh.token
        };
        const currentTime = Date.now();
        const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
        const getMilliseconds = expiresInMilliseconds - 60000;

        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, getMilliseconds, navigate);

        // saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, 10000);

        dispatch(confirmedSignupAction(response.data));
        dispatch(saveUserDetails(navigate));
        dispatch(loaderConfirm(''));
        getSingleUserSubscriptions().then((res) => {
          if (Object.keys(res?.data.subs).length === 0) {
            if (register_type === 'pro' || register_type === 'business') {
              dispatch(subscriptionPlanModalOpen(true, true));
            }
          }
        });
        navigate('/auth/verify-email');
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function createProfilePic(content, navigate) {
  const formData = new FormData();
  formData.append('profilePic', content);
  return (dispatch) => {
    // postProfilePic({ profilePic: content })
    postProfilePic(formData)
      .then((response) => {
        // dispatch(loginSaveAction(response?.data?.user));
        let message = {
          code: 200,
          message: response?.data?.message
        };
        dispatch(saveUserDetails(navigate));
        dispatch(snackbarAction(true, message));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function updateUserName(content, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    updateUserDetails(content)
      .then((response) => {
        dispatch(setMyUserName(response?.data));
        dispatch(saveUserDetails(navigate));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}
export function removeProfilePic(navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    deleteProfilePic()
      .then((response) => {
        let message = {
          code: 200,
          message: response?.data?.message
        };
        dispatch(saveUserDetails(navigate));
        dispatch(snackbarAction(true, message));
        dispatch(genieLoaderAction(false));
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function Logout(navigate) {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
    logoutUser(refreshToken);
    localStorage.removeItem('token');

    localStorage.removeItem('');
    localStorage.removeItem('register_type');
    localStorage.removeItem('modalClosedTime');
  }
  navigate('/auth/signin');
  return {
    type: LOGOUT_ACTION
  };
}

export function loaderAction(value) {
  return (dispatch) => {
    dispatch(loaderConfirm(value));
  };
}

export function userConfirmPaasword(passsword, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm(true));
    confirmPasswordUser(passsword)
      .then((response) => {
        dispatch(saveConfirmPassword(response?.data && 'Confirm'));
        dispatch(loaderConfirm(''));
        message = response?.data;
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function saveUserDetails(navigate) {
  return (dispatch) => {
    userDetails()
      .then((response) => {
        dispatch(loginSaveAction(response?.data?.user));
        // if (response?.data?.user?.ambassador === null) {
        //   sessionStorage.setItem('ambassador', null);
        // }
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        console.error(error);
      });
  };
}

export function verifyEmail(id, navigate) {
  return (dispatch) => {
    hitVerifyEmail(id)
      .then((response) => {
        dispatch(verifyEmailConfirm(response.data));
      })
      .catch((error) => {
        dispatch(verifyEmailFailed(error.response?.data));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function refreshTokenHit(id, navigate) {
  return (dispatch) => {
    refreshTokenApi(id)
      .then((response) => {
        const data = {
          expiresIn: moment(response?.data?.access?.expires).valueOf(),
          idToken: response?.data?.access?.token,
          refreshToken: response?.data?.refresh.token
        };
        const currentTime = Date.now();
        const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
        const getMilliseconds = expiresInMilliseconds - 60000;

        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, getMilliseconds, navigate);
        dispatch(confirmRefreshToken(response.data));
      })
      .catch((error) => {
        if (error.response?.data?.code === 401) {
          dispatch(Logout(navigate));
        }
        console.error(error);
      });
  };
}

export function forgotPassword(id, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    // dispatch(loaderConfirm('forgotPassword'));
    forgotPasswordEmail(id)
      .then((response) => {
        if (response?.status === 200) {
          let message = {
            code: response?.status,
            message: response?.data.message
          };
          dispatch(genieLoaderAction(false));
          // dispatch(loaderConfirm(''));
          dispatch(snackbarAction(true, message));
          dispatch(verifyForgotPassword(response.data));
          navigate('/auth/login');
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function resetPassword(password, id, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm(true));
    createNewPassword(password, id)
      .then((response) => {
        dispatch(verifyResetPassword(response.data));
        let message = {
          code: response?.status,
          message: 'Password Changed Successfully'
        };
        dispatch(loaderConfirm(''));
        dispatch(snackbarAction(true, message));
        navigate('/auth/signin');
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function loginAction(userCreadentials, navigate) {
  return (dispatch) => {
    dispatch(loaderConfirm('login'));
    login(userCreadentials)
      .then((response) => {
        const data = {
          expiresIn: moment(response?.data?.tokens?.access?.expires).valueOf(),
          idToken: response?.data?.tokens?.access?.token,
          refreshToken: response?.data?.tokens?.refresh.token
        };
        const currentTime = Date.now();
        const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
        const getMilliseconds = expiresInMilliseconds - 60000;

        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, getMilliseconds, navigate);
        dispatch(loginConfirmedAction(data));
        dispatch(loginSaveAction(response.data.user));
        dispatch(saveUserDetails(navigate));
        // let message = {
        //   code: 200,
        //   message: 'Login Success'
        // };
        dispatch(loaderConfirm(''));
        getSingleUserSubscriptions().then((res) => {
          if (Object.keys(res?.data.subs).length === 0) {
            if (register_type === 'pro' || register_type === 'business') {
              dispatch(subscriptionPlanModalOpen(true, true));
            }
          }
        });
        // dispatch(snackbarAction(true, message));
        navigate('/');
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        dispatch(genieLoaderAction(false));

        let message = {
          code: error.response?.data.code,
          message: error.response?.data.message
        };
        dispatch(snackbarAction(true, message));
        // handleErrors(dispatch, error, navigate);
      });
  };
}

export function socialLoginAction(postData, navigate) {
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    // dispatch(loaderConfirm('login'));
    socialLogin(postData)
      .then((response) => {
        const data = {
          expiresIn: moment(response?.data?.tokens?.access?.expires).valueOf(),
          idToken: response?.data?.tokens?.access?.token,
          refreshToken: response?.data?.tokens?.refresh.token
        };
        const currentTime = Date.now();
        const expiresInMilliseconds = parseInt(data.expiresIn, 10) - parseInt(currentTime, 10);
        const getMilliseconds = expiresInMilliseconds - 60000;

        saveTokenInLocalStorage(response.data);
        runLogoutTimer(dispatch, getMilliseconds, navigate);
        dispatch(loginConfirmedAction(data));
        dispatch(loginSaveAction(response.data.user));
        dispatch(saveUserDetails(navigate));
        dispatch(genieLoaderAction(false));
        navigate('/');
        getSingleUserSubscriptions().then((res) => {
          if (Object.keys(res?.data.subs).length === 0) {
            if (register_type === 'pro' || register_type === 'business') {
              dispatch(subscriptionPlanModalOpen(true, true));
            }
          }
        });
      })
      .catch((error) => {
        dispatch(loaderConfirm(''));
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getVerificationEmail(data) {
  return {
    type: GET_VERIFICATION_EMAIL,
    payload: data
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data
  };
}

export function blackFirdayModal(data) {
  return {
    type: BLACK_FRIDAY_MODAL,
    payload: data
  };
}
export function saveConfirmPassword(data) {
  return {
    type: CONFIRM_PASSWORD,
    payload: data
  };
}
export function saveUpdatePassword(data) {
  return {
    type: UPDATE_PASSWORD,
    payload: data
  };
}

export function confirmRefreshToken(data) {
  return {
    type: REFRESH_TOKEN_CONFIRMED_ACTION,
    payload: data
  };
}

export function loaderConfirm(data) {
  return {
    type: LOADING,
    payload: data
  };
}

export function verifyResetPassword(data) {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
}
export function genieLoaderAction(data) {
  return {
    type: GENIE_LOADER_ACTION,
    payload: data
  };
}
export function verifyForgotPassword(data) {
  return {
    type: FORGOT_PASSWORD,
    payload: data
  };
}
export function openLampLoader(data, value) {
  return {
    type: LAMP_LOADER_MODAL,
    payload: data,
    contentType: value
  };
}
export function verifyEmailFailed(data) {
  return {
    type: VERIFY_EMAIL_FAILED_ACTION,
    payload: data
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data
  };
}
export function loginSaveAction(data) {
  return {
    type: LOGIN_USER_ACTION,
    payload: data
  };
}
export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message
  };
}

export function verifyEmailConfirm() {
  return {
    type: VERIFY_EMAIL,
    payload: true
  };
}

export function snackbarAction(value, message) {
  return {
    type: SNACBAR_ACTION,
    payload: message,
    open: value
  };
}
export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status
  };
}
export function setMyUserName(data) {
  return {
    type: UPDATE_USER_NAME,
    payload: data
  };
}
