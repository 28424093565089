import React from 'react';
import { Grid } from '@mui/material';
import './SearchHistory.css';
import SearchHistoryTable from './SearchHistoryTable';
function SearchHistory() {
  return (
    <Grid container className="sdfsfgsdfgsfgkfgdskjfgjkgdjs" lg={12} md={12} sx={{ padding: '0px !important' }}>
      {/* <Grid item className="searchHistoryImg">
        <Typography className="searchHistoryText">Search History</Typography>
      </Grid> */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <SearchHistoryTable />
      </Grid>
    </Grid>
  );
}

export default SearchHistory;
