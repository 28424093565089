/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useReducer,
  //  useRef,
  useState
} from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  OutlinedInput,
  FormControl,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  Chip,
  InputAdornment,
  FormHelperText,
  IconButton,
  Dialog,
  DialogTitle
} from '@mui/material';
import * as Yup from 'yup';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import MdModal from 'components/Modal/Modal';
// import { strengthColor, strengthIndicator } from 'utils/password-strength';
import { Logout, createProfilePic, genieLoaderAction, loaderConfirm, snackbarAction } from 'store/actions/Authentication/AuthActions';
import { useNavigate } from 'react-router-dom';
import { confirmPasswordUser, updatePassword } from '../../services/AuthService';
// import { Widget } from '@uploadcare/react-widget';
// import { removeProfilePic } from '../../store/actions/Authentication/AuthActions';
import { saveUserDetails } from 'store/actions/Authentication/AuthActions';
import { reStartSubscription, subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { usersSubslist, schedulePlanCancel } from 'store/actions/Subscription/SubscriptionActions';
import moment from 'moment';
import { updateUserName } from 'store/actions/Authentication/AuthActions';
import logoGradient from '../../assets/images/common/genieOn1.svg';
import crossButton from 'assets/designs/crossButton.svg';
import prlogo from '../../assets/images/common/prlogo.svg';
import './Profile.css';
import DragAndDropImage from './DragAndDropImage';
// import { updateProfilePic } from 'store/actions/Authentication/AuthActions';
import plusSign from '../../assets/designs/plus.svg';
import TextFieldDesign from 'components/GloabalTextFieldDesign/GloabalTextFieldDesign';
import { ThemeProvider } from '@mui/material/styles';
const names = ['English', 'Spanish', 'French', 'German'];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Profile = () => {
  const [key, rerender] = useReducer((v) => !v);
  const [showPassword, setShowPassword] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [updatedFirstName, setUpdatedFirstName] = useState('');
  const [updatedLastName, setUpdatedLastName] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { showLoading, user, checkConfirmPassword } = useSelector((state) => state.auth);
  const [confirmPassword, setConfirmPaaword] = useState(false);
  const placeholder = 'Select Language';
  const [isEditing, setIsEditing] = useState({ name: false, email: false });
  // const [level, setLevel] = useState();
  const [open, setOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState([]);
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const [newPhoto, setNewphoto] = useState('');
  const [nPhoto, setNphoto] = useState(newPhoto);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgModal, setImgModal] = useState(false);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  console.log(key);
  const handleDelete = async () => {
    dispatch(schedulePlanCancel({ type: 'Subscription', subscriptionId: currentPlanwordsTeammates._id }, navigate));

    setOpen(false);
  };
  useEffect(() => {
    dispatch(usersSubslist(10, 0, navigate));
  }, [dispatch, navigate]);

  const handleCancel = () => {
    setOpen(true);
  };

  const handleUpgrade = () => {
    dispatch(subscriptionPlanModalOpen(true));
  };

  const postProfilePic = async (uploadedImageUrl) => {
    dispatch(createProfilePic(uploadedImageUrl, navigate));
    setOpenImgModal(false);
  };

  // const deleteProfilePhoto = async () => {
  //   dispatch(removeProfilePic(navigate));
  // };

  useEffect(() => {
    if (user) {
      setUpdatedEmail(user?.email);
      setUpdatedFirstName(user?.firstName);
      setUpdatedLastName(user?.lastName);
      setUserLanguage(user?.languages?.length > 0 ? user?.languages : ['English']);
      setNewphoto(user?.profilePic);
      setNphoto(user?.profilePic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleFirstNameChange = (event) => {
    if (/^\s/.test(event.target.value)) {
      return;
    }

    setUpdatedFirstName(event.target.value);
    setNameErr(false);
  };

  const handleLastNameChange = (event) => {
    if (/^\s/.test(event.target.value)) {
      return;
    }
    setUpdatedLastName(event.target.value);
    setLastNameErr(false);
  };
  useEffect(() => {
    saveUserDetails(navigate);
  }, [navigate]);

  useEffect(() => {
    if (checkConfirmPassword === 'Confirm') {
      setConfirmPaaword(true);
    } else {
      setConfirmPaaword(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkConfirmPassword]);

  const handleChange = (event) => {
    const { value } = event.target;
    const newValue = typeof value === 'string' ? value.split(',') : value;

    if (newValue.length === 0) {
      let message = {
        code: 204,
        message: "Can't remove the last language"
      };
      dispatch(snackbarAction(true, message));
      return;
    }

    setUserLanguage(newValue);
    updateUserLanguages(newValue, navigate);
    setSelectOpen(false);
  };

  const updateUserLanguages = (newValue) => {
    const languagesArray = Array.isArray(newValue) ? newValue : [newValue];
    dispatch(updateUserName({ languages: languagesArray }, navigate));
  };

  const handleClickShowPasswordOld = () => {
    setShowPasswordOld(!showPasswordOld);
  };
  const handleMouseDownPasswordOld = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  // const changePassword = (value) => {
  //   const temp = strengthIndicator(value);
  //   setLevel(strengthColor(temp));
  // };

  // useEffect(() => {
  //   changePassword('');
  // }, []);

  const handleNameChange = (event) => {
    if (event.target.name === 'name') {
      // setUpdatedName(event.target.value);
    } else {
      setUpdatedEmail(event.target.value);
    }
  };
  const handleEditClick = (name) => {
    if (name === 'name') {
      setIsEditing({ name: true, email: false });
    } else {
      setIsEditing({ email: true, name: false });
    }
  };

  const editEmail = (fieldName) => {
    if (isEditing[fieldName]) {
      if (fieldName === 'name') {
        const newName = `${updatedFirstName} ${updatedLastName}`;
        if (newName !== user?.firstName + ' ' + user?.lastName) {
          handleBlur(fieldName);
        } else {
          setIsEditing({ ...isEditing, [fieldName]: false });
        }
      } else {
        if (updatedEmail !== user?.email) {
          handleBlur(fieldName);
        } else {
          setIsEditing({ ...isEditing, [fieldName]: false });
        }
      }
    } else {
      handleEditClick(fieldName);
    }
  };

  const handleNameErr = (value, setErr) => {
    if (value === '') {
      if (/^\s/.test(value)) {
        return;
      }
      setErr(true);
    } else {
      setErr(false);
    }
  };

  const handleBlur = (fieldName) => {
    if (fieldName === 'name') {
      if (updatedFirstName === '') {
        handleNameErr(updatedFirstName, setNameErr);
      } else if (updatedLastName === '') {
        handleNameErr(updatedLastName, setLastNameErr);
      } else {
        updateUser(`${updatedFirstName} ${updatedFirstName}`, fieldName);
      }
    } else {
      updateUser(updatedEmail, fieldName);
    }
  };

  const updateUser = (valuesObj) => {
    valuesObj = {
      firstName: updatedFirstName,
      lastName: updatedLastName
    };
    dispatch(updateUserName(valuesObj), navigate);
    setIsEditing({ ...isEditing, ['name']: false });
  };

  const handleRemove = (value) => {
    // Check if there's only one language left and it matches the one being removed
    if (userLanguage.length === 1 && userLanguage[0] === value) {
      let message = {
        code: 204,
        message: "Can't remove the last language"
      };
      dispatch(snackbarAction(true, message));

      return; // Don't remove the last language
    }

    const updatedLanguages = userLanguage.filter((item) => item !== value);

    setUserLanguage(updatedLanguages);
    updateUserLanguages(updatedLanguages, navigate);
  };

  const handleClose = () => {
    setUpdatePasswordModal(false);
  };

  // const uploadPhoto = (fileInfo) => {
  //   dispatch(genieLoaderAction(true));
  //   setNewphoto(fileInfo.cdnUrl);
  //   postProfilePic(fileInfo.cdnUrl);
  //   rerender();
  // };

  // const handleUploadClick = () => {
  //   fileInputRef.current.click();
  // };

  const handleUploadImage = (image) => {
    dispatch(genieLoaderAction(true));
    setNewphoto(image);
    postProfilePic(image);
    rerender();
  };
  console.log(confirmPassword);
  return (
    <>
      <MdModal
        open={updatePasswordModal}
        title={<Typography variant="h13">Update Password</Typography>}
        subTitle={
          <Typography variant="p" sx={{ fontSize: '15px' }}>
            To update password, enter your old password first.
          </Typography>
        }
        handleClose={handleClose}
        closeButton={true}
        borderRadius="16px"
      >
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: ''
          }}
          validationSchema={Yup.object().shape({
            oldPassword: Yup.string().max(255).required('Old Password is required'),
            newPassword: Yup.string().min(8, 'Password must be at least 8 characters').required('New Password is required'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
              .required('Confirm Password is required')
          })}
          // onSubmit={async (values, { setFieldValue }) => {
          onSubmit={async (
            values
            // { setFieldValue }
          ) => {
            dispatch(loaderConfirm('reset'));
            const password = {
              oldPassword: values.oldPassword
            };
            let passswords = {
              newPassword: values.newPassword,
              confirmPassword: values.confirmPassword
            };

            confirmPasswordUser(password, navigate)
              .then((res) => {
                if (res.status == '204') {
                  updatePassword(passswords, navigate)
                    .then((res) => {
                      if (res) {
                        // setConfirmPaaword(false);
                        setUpdatePasswordModal(false);
                        let message = {
                          code: 201,
                          message: 'Password Updated Successfully'
                        };
                        dispatch(snackbarAction(true, message));
                        dispatch(loaderConfirm(''));
                      }
                    })
                    .catch((error) => {
                      dispatch(loaderConfirm(''));
                      let message = {
                        code: error.response?.data.code,
                        message: error.response?.data?.message
                      };
                      dispatch(snackbarAction(true, message));
                      if (error.response?.data?.code === 401) {
                        dispatch(Logout(navigate));
                      }
                    });
                }
              })
              .catch((error) => {
                dispatch(loaderConfirm(''));
                let message = {
                  code: error.response?.data.code,
                  message: error.response?.data?.message
                };
                dispatch(snackbarAction(true, message));
                if (error.response?.data?.code === 401) {
                  dispatch(Logout(navigate));
                }
              });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} mt={4}>
                  <Stack spacing={1}>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                        id="email-login"
                        type={showPasswordOld ? 'text' : 'password'}
                        value={values.oldPassword}
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Old Password"
                        autocomplete="off"
                        inputProps={{}}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordOld}
                              onMouseDown={handleMouseDownPasswordOld}
                              edge="end"
                              size="large"
                            >
                              {showPasswordOld ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </ThemeProvider>
                    {touched.oldPassword && errors.oldPassword && (
                      <FormHelperText error id="helper-text-email-signup">
                        {errors.oldPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0 } }}>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        id="newPassword-signup"
                        type={showPassword ? 'text' : 'password'}
                        value={values.newPassword}
                        name="newPassword"
                        onBlur={handleBlur}
                        autocomplete="off"
                        onChange={(e) => {
                          handleChange(e);
                          // changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="New Password"
                      />
                    </ThemeProvider>
                    {touched.newPassword && errors.newPassword && (
                      <FormHelperText error id="helper-text-newPassword-signup">
                        {errors.newPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0 } }}>
                    <ThemeProvider theme={TextFieldDesign}>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        id="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        autocomplete="off"
                        onBlur={handleBlur}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        inputProps={{}}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                              size="large"
                            >
                              {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </ThemeProvider>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <FormHelperText error id="helper-text-confirmPassword">
                        {errors.confirmPassword}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12} lg={12} sm={12} md={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: { xs: 3, md: 1, lg: 1, sm: 1 }, pb: 2 }}>
                  <Button fullWidth className="globalButtonSecond" sx={{ textTransform: 'none !important' }} type="submit">
                    {showLoading !== 'reset' ? 'Change Password' : <CircularProgress size={25} />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </MdModal>
      <Dialog
        open={imgModal}
        onClose={() => {
          setImgModal(false);
        }}
      >
        <img src={user?.profilePic} alt={'profile img'} width="100%" />
      </Dialog>
      <Box sx={{ p: 4 }} align="center">
        <Box sx={{ width: '100px', height: '100px', position: 'relative' }}>
          <Box
            className="userAvatar"
            onClick={() => {
              newPhoto ? setImgModal(true) : setImgModal(false);
            }}
            sx={{ backgroundImage: `url(${newPhoto ? nPhoto : prlogo})` }}
          ></Box>

          <img
            src={plusSign}
            alt={'profile'}
            onClick={() => {
              setOpenImgModal(true), setImgModal(false);
            }}
            className="uploadImgIcon"
            style={{ borderRadius: '50%', cursor: 'pointer' }}
          />
        </Box>
      </Box>
      <Box className="globalShadow" p={4}>
        <Box className="globalAlignment accountInfoBox">
          <Typography className="primaryHeading fontSize28">Account Info</Typography>
        </Box>
        <Box sx={{ mt: 2, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
          <Grid container spacing={2} mt={2} className="globalAlignment editNameContainer" sx={{ alignItems: 'flex-end !important' }}>
            <Grid item lg={7} xs={isEditing.name ? 12 : 12} sm={7} md={7}>
              <Typography
                align="left"
                className="commonEllipsisClass"
                sx={{
                  maxWidth: '450px'
                }}
              >
                {isEditing.name ? (
                  <Box display="flex">
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                      <Typography mb={1} className="secondaryHeading">
                        First Name
                      </Typography>
                      <input
                        type="text"
                        name="name"
                        className="emailEditor"
                        placeholder="First Name"
                        value={updatedFirstName}
                        onChange={handleFirstNameChange}
                        autoFocus
                        style={{
                          padding: 0,
                          paddingLeft: '16px',
                          background: 'rgba(112,112,112,0.1)'
                          // marginRight: '10px'
                        }}
                      />
                      {nameErr && <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>First name is required</Typography>}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography mb={1} className="secondaryHeading">
                        Last Name
                      </Typography>

                      <input
                        type="text"
                        name="lastName"
                        className="emailEditor"
                        // lastNameInput
                        placeholder="Last Name"
                        value={updatedLastName}
                        onChange={handleLastNameChange}
                        style={{
                          padding: 0,
                          paddingLeft: '16px',
                          background: 'rgba(112,112,112,0.1)'
                        }}
                      />
                      {lastNameErr && <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>Last name is required</Typography>}
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex">
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography mb={1} className="secondaryHeading">
                        First Name
                      </Typography>
                      <input
                        style={{
                          padding: 0,
                          background: 'none',
                          paddingLeft: '0px'
                        }}
                        type="text"
                        className="emailEditor"
                        value={updatedFirstName}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography mb={1} ml={1} className="secondaryHeading">
                        Last Name
                      </Typography>

                      {updatedLastName && (
                        <input
                          type="text"
                          style={{
                            padding: 0,
                            background: 'none',
                            paddingLeft: '0px'
                          }}
                          className="emailEditor lastNameInput"
                          value={updatedLastName}
                        />
                      )}
                    </Box>
                    {/* <Typography variant="h8">First Name</Typography>
                    <Typography variant="h9">{updatedFirstName + ' ' + (updatedLastName ? updatedLastName : '')}</Typography> */}
                  </Box>
                )}
              </Typography>
            </Grid>

            <Grid item lg={5} xs={12} sm={12} md={5} className={isEditing.name ? 'editingNameBtns' : 'editChangeBtn'}>
              {isEditing.name && (
                // <Grid item align="center" lg={2.7} xs={12} sm={2} md={2.7}>
                <Button
                  className="globalButtons editingCancelBtn"
                  onClick={() => {
                    setIsEditing({ name: false });
                    setUpdatedFirstName(user?.firstName);
                    setUpdatedLastName(user?.lastName);
                  }}
                  sx={{
                    borderRadius: '24px !important',
                    width: { xs: '100%', sm: '180px', md: '180px', lg: '180px' }
                  }}
                  // fullWidth
                >
                  Cancel
                </Button>
              )}
              <Button
                sx={{
                  borderRadius: '24px !important',
                  width: { xs: '100%', sm: '180px', md: '180px', lg: '180px' }
                }}
                className="globalButtonSecond"
                onClick={() => {
                  editEmail('name');
                }}
                variant="contained"
                // fullWidth
              >
                {showLoading !== 'editName' ? isEditing.name ? 'Save Changes' : 'Change Name' : <CircularProgress size={25} />}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box xs={12} sx={{ mt: { xs: 2, md: 5, sm: 5, lg: 5 }, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
          <Typography className="secondaryHeading">Email Address</Typography>
          <Grid container spacing={2} xs={12} mt={2} className="globalAlignment">
            <Grid item lg={5}>
              <Typography align="left" className="tableCount" sx={{ fontWeight: '400 !important' }}>
                {isEditing.email ? (
                  <input type="text" name="email" className="emailEditor" value={updatedEmail} onChange={handleNameChange} autoFocus />
                ) : (
                  <Typography className="tableCount" sx={{ fontWeight: '400 !important' }}>
                    {updatedEmail}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box xs={12} sx={{ mt: { xs: 2, md: 5, sm: 5, lg: 5 }, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
          <FormControl sx={{ mt: 1, width: '100%' }}>
            <Typography className="secondaryHeading" mb={2}>
              Selected Language
            </Typography>

            <Box
              className="globalAlignment"
              sx={{ display: { xs: 'block !important', md: 'flex !important', sm: 'flex !important', lg: 'flex !important' } }}
            >
              <Box sx={{ display: { sx: 'block', md: 'flex', sm: 'flex', lg: 'flex' }, gap: 2, flexWrap: 'wrap' }}>
                {userLanguage.length > 0 &&
                  userLanguage?.map((value) => (
                    <Chip
                      className="badegs"
                      sx={{
                        margin: { xs: '10px', md: 'block', sm: 'block', lg: 'block' }
                      }}
                      key={value}
                      label={value}
                      deleteIcon={<CloseIcon sx={{ color: '#6631A5 !important', fontSize: '20px' }} />}
                      mt={3}
                      onDelete={() => handleRemove(value)}
                    />
                  ))}
              </Box>
              <Select
                className="languageSelector responsiveLanguageSelector"
                mt={2}
                defaultValue="English"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={userLanguage}
                open={selectOpen}
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
                onChange={handleChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                sx={{ width: { xs: '270px', md: '300px', lg: '300px', sm: '300px' } }}
              >
                <MenuItem disabled value="">
                  {placeholder}
                </MenuItem>

                {names.map((name) => (
                  <MenuItem
                    sx={{
                      backgroundColor: userLanguage.length > 0 && userLanguage.indexOf(name) > -1 ? '#efeaf6 !important' : 'initial',
                      m: '10px',
                      borderRadius: '6px',
                      p: 0,
                      border: '1px solid #00000000'
                    }}
                    key={name}
                    value={name}
                  >
                    <ListItemText sx={{ ml: 2, fontWeight: '500' }} primary={name} />
                    <Checkbox
                      sx={{ color: '#6631A5 !important', p: '5px !important' }}
                      checked={userLanguage.length > 0 && userLanguage.indexOf(name) > -1}
                    />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormControl>
        </Box>
        <Box sx={{ mt: { xs: 2, md: 5, sm: 5, lg: 5 }, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
          <Typography className="secondaryHeading"></Typography>
          <Grid container mt={1} className="globalAlignment">
            <Grid item lg={7.6} xs={12} sm={7.6} md={7.6}>
              {currentPlanwordsTeammates?.status === 'active' || currentPlanwordsTeammates?.status === 'cancelled' ? (
                <>
                  <Typography className="tableCount" mb={1}>
                    Billing
                  </Typography>
                  <Typography mb={1} className="tableCount primaryColor">
                    <b>WriterGenie</b>{' '}
                    <b>
                      {currentPlanwordsTeammates?.subscriptionPlanId?.name === 'pro-annually' ||
                      currentPlanwordsTeammates?.subscriptionPlanId?.name === 'pro-monthly'
                        ? 'Pro'
                        : currentPlanwordsTeammates?.subscriptionPlanId?.name === 'business-annually' ||
                          currentPlanwordsTeammates?.subscriptionPlanId?.name === 'business-monthly'
                        ? 'Business'
                        : 'Free'}
                    </b>
                  </Typography>
                  {currentPlanwordsTeammates?.status === 'active' ? (
                    <Typography className="tableCount" sx={{ fontWeight: '400 !important' }}>
                      <>
                        Renewal Date: <b>{moment(currentPlanwordsTeammates?.endDate).format('MM/DD/YYYY')}</b>
                      </>
                    </Typography>
                  ) : currentPlanwordsTeammates?.status === 'cancelled' ? (
                    <Typography className="tableCount" sx={{ fontWeight: '400 !important' }}>
                      <>
                        Valid Till: <b>{moment(currentPlanwordsTeammates?.endDate).format('MM/DD/YYYY')}</b>
                      </>
                    </Typography>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <Typography variant="body1">
                  <Typography variant="body1">
                    <b>WriterGenie</b> <b>Free</b> <br /> Free Forever <br />
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid
              item
              lg={2.6}
              xs={12}
              sm={4}
              md={3}
              sx={{ mt: { xs: 4, md: 0, sm: 0, lg: 0 }, textAlign: { xs: 'center', sm: 'center', md: 'end', lg: 'end' } }}
            >
              {currentPlanwordsTeammates?.status === 'active' ? (
                <Button
                  fullWidth
                  sx={{ borderRadius: '24px !important' }}
                  className="globalButtonSecond"
                  onClick={handleCancel}
                  variant="contained"
                >
                  Cancel Subscription
                </Button>
              ) : currentPlanwordsTeammates?.status === 'cancelled' ? (
                <Button
                  sx={{ borderRadius: '24px !important' }}
                  className="globalButtonSecond"
                  onClick={() => {
                    dispatch(reStartSubscription({ subscriptionId: currentPlanwordsTeammates?._id }, navigate));
                  }}
                  fullWidth
                  variant="contained"
                >
                  Restart Subscription
                </Button>
              ) : (
                <Button
                  fullWidth
                  sx={{ borderRadius: '24px !important' }}
                  className="globalButtonSecond"
                  onClick={handleUpgrade}
                  variant="contained"
                >
                  Upgrade to Pro
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Dialog
          maxWidth="xs"
          fullWidth={true}
          aria-labelledby="customized-dialog-title"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Box p={3}>
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpen(false);
                }}
                className="crossButton"
              >
                <img src={crossButton} alt="cross" />
              </IconButton>
            </DialogTitle>
            <Box>
              <Box align="center">
                <Box className="logo-container">
                  <img src={logoGradient} style={{ width: '60px' }} className="logo" alt="cross" />
                </Box>
                <Typography align="center" my={3} className="areYouSureText">
                  Are you sure?
                </Typography>
                <Typography sx={{ my: 3 }} align="center" className="infoTextGlobal">
                  Your account access will remain active until the completion of your current billing period.
                </Typography>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        setOpen(false);
                      }}
                      sx={{ borderRadius: '8px !important', width: { xs: '100%', sm: '100% !important', md: '180px', lg: '180px' } }}
                      className="globalButtons"
                    >
                      Not Now
                    </Button>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <Button
                      onClick={handleDelete}
                      fullWidth
                      sx={{
                        width: { xs: '100%', sm: '100% !important', md: '180px', lg: '180px' }
                      }}
                      className="globalButtonSecond"
                    >
                      Cancel Subscription
                    </Button>
                  </Grid>
                </Grid>
                {/* <Box mt={5}>
                  <Box className="btnBox" sx={{ mt: 2 }}>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      sx={{ borderRadius: '8px !important' }}
                      className="globalButtons"
                    >
                      Not Now
                    </Button>
                    <Button onClick={handleDelete} className="globalButtonSecond">
                      Cancel Subscription
                    </Button>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Dialog>
        {/* Security */}
        <Box sx={{ mt: { xs: 2, md: 5, sm: 5, lg: 5 }, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
          <Typography className="secondaryHeading">Security</Typography>
          <Grid container mt={1} className="globalAlignment">
            <Grid item lg={7.6} xs={12} sm={7.6} md={7.6}>
              <Typography className="secondaryHeading">#########</Typography>
            </Grid>
            <Grid item lg={2.1} sm={1.2} md={2.4}></Grid>
            <Grid
              item
              lg={2.3}
              xs={12}
              sm={3.2}
              md={2}
              sx={{ mt: { xs: 4, md: 0, sm: 0, lg: 0 }, textAlign: { xs: 'center', sm: 'center', md: 'end', lg: 'end' } }}
            >
              <Button
                sx={{ borderRadius: '24px !important', textTransform: 'none !important' }}
                className="globalButtonSecond"
                onClick={() => setUpdatePasswordModal(true)}
                fullWidth
              >
                {showLoading !== 'Update' ? 'Change Password' : <CircularProgress size={25} />}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography className="settingText">Settings</Typography>
          <Button
            sx={{
              borderRadius: '24px !important',
              fontSize: '14px',
              mt: { xs: 1 },
              width: { xs: '100%', sm: '180px', md: '180px', lg: '180px' }
            }}
            className="globalButtonSecond"
            onClick={() => navigate('/medium')}
            variant="contained"
          >
            Connect Platform
          </Button>
        </Box>
      </Box>
      {openImgModal && <DragAndDropImage open={openImgModal} setOpen={setOpenImgModal} handleUploadImage={handleUploadImage} />}
    </>
  );
};

export default Profile;
