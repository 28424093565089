import axios from 'axios';
import { loginConfirmedAction, Logout, refreshTokenHit } from '../store/actions/Authentication/AuthActions';
import { apiUrl, authApi } from './AxiosInstance';

export function signUp(userCredential) {
  return axios.post(`${apiUrl}/auth/register`, userCredential);
}
export function forgotPasswordEmail(email) {
  return axios.post(`${apiUrl}/auth/forgot-password`, email);
}
export function userDetails() {
  return authApi.get('/users/load/user');
}
export function postProfilePic(content) {
  return authApi.post('/users/profile-pic', content);
}
export function deleteProfilePic() {
  return authApi.delete('/users/profile-pic');
}
export function updateUserDetails(values) {
  return authApi.patch(`/users`, values);
}
export function updatePassword(passsword) {
  return authApi.post('/auth/change-password', passsword);
}
export function confirmPasswordUser(passsword) {
  return authApi.post('/auth/confirm-password', passsword);
}

export function verificationEmail(userCredential) {
  return authApi.post(`/auth/send-verification-email`, userCredential);
}

export function hitVerifyEmail(id) {
  return authApi.post(`/auth/verify-email?token=${id}`);
}
export function createNewPassword(password, token) {
  return axios.post(`${apiUrl}/auth/reset-password?token=${token}`, password);
}

export function logoutUser(refreshtoken) {
  const postData = {
    refreshToken: refreshtoken
  };

  return authApi.post('/auth/logout', postData);
}

export function login(userCredential) {
  return axios.post(`${apiUrl}/auth/login`, userCredential);
}
export function refreshTokenApi(refreshToken) {
  return axios.post(`${apiUrl}/auth/refresh-tokens`, refreshToken);
}
export function socialLogin(postData) {
  return axios.post(`${apiUrl}/auth/socialLogin`, postData);
}
export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(new Date().getTime() + tokenDetails.expiresIn * 1000);
  localStorage.setItem('token', JSON.stringify(tokenDetails?.tokens?.access?.token || tokenDetails?.access?.token));
  localStorage.setItem('expires', JSON.stringify(tokenDetails?.tokens?.access?.expires || tokenDetails?.access?.expires));
  localStorage.setItem('refreshToken', JSON.stringify(tokenDetails?.tokens?.refresh?.token || tokenDetails?.refresh?.token));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(refreshTokenHit({ refreshToken: JSON.parse(localStorage.getItem('refreshToken')) }, navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('token');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();
  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
