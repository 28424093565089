import { blackFirdayModal, genieLoaderAction } from '../Authentication/AuthActions';
import { getCoupons } from 'services/CouponService';
export const GET_COUPONS = '[get coupons] editor getCoupons ';
import moment from 'moment';
import { getSingleUserSubscriptions } from 'services/SubscriptionService';
import { handleErrors } from '../Projects/ProjectsActions';

export function getCoupon(navigate) {
  const register_type = localStorage.getItem('register_type');
  return (dispatch) => {
    dispatch(genieLoaderAction(true));
    getCoupons()
      .then((response) => {
        dispatch(getMyCoupons(response?.data));
        dispatch(genieLoaderAction(false));
        if (response?.data?.length > 0) {
          const time = localStorage.getItem('modalClosedTime');
          if ((time === null || time === undefined) && register_type === null) {
            getSingleUserSubscriptions().then((res) => {
              if (Object.keys(res?.data.subs).length === 0) {
                dispatch(blackFirdayModal(true));
                return;
              }
            });
          }
          const modalClosedTime = moment(time);
          const currentClosedTime = moment();
          const timeDifference = currentClosedTime.diff(modalClosedTime, 'hours');
          if (timeDifference >= 12) {
            getSingleUserSubscriptions().then((res) => {
              if (Object.keys(res?.data.subs).length === 0) {
                dispatch(blackFirdayModal(true));
              }
            });
          } else {
            console.log('Time difference is less than 12 hours');
          }
        }
      })
      .catch((error) => {
        handleErrors(dispatch, error, navigate);
      });
  };
}

export function getMyCoupons(data) {
  return {
    type: GET_COUPONS,
    payload: data
  };
}
