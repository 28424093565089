/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getMyProjectList, openConfirmationLock } from 'store/actions/Projects/ProjectsActions';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle
} from '@mui/material';
import emptyProjects from 'assets/designs/emptyProjects.svg';
import { DeleteProjects } from '../../store/actions/Projects/ProjectsActions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

// import { CgClose } from 'react-icons/cg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DateFormatter from '../../utils/DateFormatter';

import logoGradient from '../../assets/images/common/genieOn1.svg';
import DeleteIcon from '../../assets/images/common/delete.svg';
import { teamMatesModal } from 'store/actions/Teammates/TeammatesActions';
// import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import UserProfileImage from 'pages/Profileimage/UserProfileImage';
import PaginationComp from 'components/Pagination/Pagination';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { subscriptionPlanModalOpen } from 'store/actions/Subscription/SubscriptionActions';
import { subscriptionValidation } from 'utils/SubscriptionValidation';
import EllipsisTooltip from 'components/ElliplisTooltip/ElliplisTooltip';
import './MyProject.css';
import TableWrapper from 'components/TableWrapper/TableWrapper';
import { useLocation } from 'react-router-dom';
// log
// import { FaUserCircle } from 'react-icons/fa';
const MyProjects = ({ recentButtons }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myProjectList, projectCount } = useSelector((state) => state.projects);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [getProjectId, setGetProjectId] = useState(null);
  const [anchorElArray, setAnchorElArray] = useState(Array(myProjectList.length).fill(null));
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const limit = 10;
  const { currentPlanwordsTeammates } = useSelector((state) => state.subscription);
  const { lockPaginationState } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);

  const location = useLocation();
  const { pathname } = location;
  const styleForButton = {
    width: '40px',
    height: '40px'
  };

  const handleMenuOpen = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  // Function to handle closing a specific menu
  const handleMenuClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  useEffect(() => {
    if (lockPaginationState === true && currentPage !== 1) {
      setCurrentPage(1);
      setOffset(0);
    }
  }, [currentPage, lockPaginationState]);

  useEffect(() => {
    if (search && search.length >= 3) {
      dispatch(getMyProjectList(pathname === '/' ? 5 : 10, 0, search, navigate));
    } else {
      let newSearch = '';
      dispatch(getMyProjectList(pathname === '/' ? 5 : 10, offset, newSearch, navigate));
    }
  }, [dispatch, offset, navigate, search, currentPage]);

  const handleDelete = async (index) => {
    // setAnchorEl(null);
    dispatch(DeleteProjects(getProjectId, pathname, navigate));
    handleMenuClose(index);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    setSearch('');
  };

  return (
    <>
      <TableWrapper>
        {myProjectList && myProjectList?.length === 0 ? (
          <Box align="center">
            <img src={emptyProjects} alt="empty-projects" />
            <Typography className="primaryHeading">My Projects</Typography>
            <Typography className="infoTextGlobal" sx={{ my: 2 }}>
              You have not created any projects. This section will contain the list of your created projects.
            </Typography>
          </Box>
        ) : (
          <TableContainer>
            <Box className="globalAlignment">
              <Typography className="primaryHeading">My Projects</Typography>
              {pathname === '/my-project' && (
                <Typography className="tableCount" sx={{ mb: 2, fontWeight: 700 }}>
                  Total Projects ({projectCount})
                </Typography>
              )}
            </Box>
            <Table>
              <TableHead>
                <TableRow sx={{ mb: 4, pb: 2, borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                  <TableCell className="headerRow">
                    <Typography variant="subtitle11"> Project Name</Typography>
                  </TableCell>
                  <TableCell align="center" className="headerRow">
                    <Typography variant="subtitle11"> Last Updated</Typography>
                  </TableCell>
                  <TableCell align="center" className="headerRow">
                    <Typography variant="subtitle11"> Eligible People</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle11">Actions</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myProjectList &&
                  myProjectList?.length !== 0 &&
                  myProjectList?.map((projectList, index) => {
                    return (
                      <TableRow key={projectList._id} sx={{ borderBottom: 2, borderColor: 'grey.300', borderWidth: '1px' }}>
                        <TableCell style={{ padding: '29px', width: '28%' }}>
                          {/* <Link className="projectListItems" to={`/project/${projectList._id}`} sx={{ width: 'unset !important' }}> */}
                          <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(`/project/${projectList._id}`)}>
                            <EllipsisTooltip text={projectList.name} variant="subtitle7" maxWidth="350px" />
                          </Typography>
                          {/* </Link> */}
                        </TableCell>
                        <TableCell align="center" className="headerRow" sx={{ color: 'black' }}>
                          <DateFormatter date={projectList.updatedAt} />
                        </TableCell>
                        <TableCell align="center">
                          {projectList?.people_with_access?.length > 0 ? (
                            <AvatarGroup sx={{ justifyContent: 'center', cursor: 'pointer' }} max={3}>
                              {projectList?.people_with_access?.map((ite, index) => (
                                <UserProfileImage
                                  // sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate('/teammates');
                                  }}
                                  key={index}
                                  image={ite?.profilePic}
                                  title={`${ite?.firstName} ${ite?.lastName}`}
                                />
                              ))}
                            </AvatarGroup>
                          ) : (
                            <Box>{'Not shared'}</Box>
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            aria-controls="click-menu"
                            aria-haspopup="true"
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent'
                              }
                            }}
                            onClick={(event) => {
                              // handleMenuOpen(event, index);
                              handleMenuOpen(event, projectList._id);

                              setGetProjectId(projectList._id);
                            }}
                            edge="end"
                            color="inherit"
                          >
                            <MoreHorizIcon style={styleForButton} />
                          </IconButton>

                          {/* <Menu
                            key={index}
                            id={`click-menu-${index}`}
                            anchorEl={anchorElArray[index]}
                            open={Boolean(anchorElArray[index])}
                            onClose={() => handleMenuClose(index)}
                            className="info"
                            PaperProps={{
                              sx: {
                                boxShadow: 1,
                                borderRadius: '16px'
                              }
                            }}
                          > */}
                          <Menu
                            key={index}
                            id={`click-menu-${projectList._id}`}
                            anchorEl={anchorElArray[projectList._id]}
                            open={Boolean(anchorElArray[projectList._id])}
                            onClose={() => handleMenuClose(projectList._id)}
                            className="info"
                            PaperProps={{
                              sx: {
                                boxShadow: 1,
                                borderRadius: '16px'
                              }
                            }}
                          >
                            <MenuItem onClick={() => handleMenuClose(projectList._id)} sx={{ mt: 1 }}>
                              <Box
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  }
                                }}
                              >
                                <Typography
                                  onClick={() => {
                                    if (!subscriptionValidation(currentPlanwordsTeammates, user, true)) {
                                      dispatch(subscriptionPlanModalOpen(true));
                                    } else {
                                      dispatch(teamMatesModal(true));
                                    }
                                  }}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <ControlPointIcon sx={{ color: 'rgb(102, 49, 165)' }} />
                                  <Typography sx={{ ml: 1, color: '#000000' }}>Invite Teammates</Typography>
                                </Typography>
                              </Box>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                dispatch(openConfirmationLock(true, getProjectId, !projectList?.projectLock));
                                handleMenuClose(projectList._id);
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  }
                                }}
                              >
                                <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  {projectList?.projectLock ? (
                                    <LockOpenOutlinedIcon sx={{ fontSize: '25px', cursor: 'pointer', color: 'rgb(112,112,112)' }} />
                                  ) : (
                                    <LockOutlinedIcon sx={{ fontSize: '25px', cursor: 'pointer', color: 'rgb(112,112,112)' }} />
                                  )}

                                  <Typography sx={{ color: '#000000', ml: 1 }}>{projectList?.projectLock ? 'Unlock' : 'Lock'}</Typography>
                                </Typography>
                              </Box>
                            </MenuItem>

                            <MenuItem onClick={() => handleDelete(projectList._id)} sx={{ mb: 1 }}>
                              <Box
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'transparent'
                                  }
                                }}
                              >
                                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    className="deleteIcon"
                                    src={DeleteIcon}
                                    style={{
                                      width: '20px',
                                      height: '20px'
                                    }}
                                    alt="delete-icon"
                                  />
                                  <Typography sx={{ color: '#000000', ml: 1 }}>Delete</Typography>
                                </Typography>
                              </Box>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {recentButtons == 'My Projects' && projectCount > 0 && (
                <Button
                  className="globalButtonSecond"
                  onClick={() => navigate('/my-project')}
                  sx={{ borderRadius: '24px !important', display: 'flex', mt: 6, width: { xs: '190px !important' } }}
                  type="button"
                >
                  View All Projects
                </Button>
              )}
            </Table>
            {pathname === '/my-project' && projectCount > 10 && (
              <PaginationComp count={Math.ceil(projectCount / limit)} currentPage={currentPage} onChange={handlePageChange} />
            )}

            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <DialogTitle>
                <Box align="center">
                  <img style={{ width: '60px' }} src={logoGradient} alt="cross" />
                </Box>
              </DialogTitle>

              <DialogTitle>
                <Typography variant="h5" align="center" fontWeight="500">
                  Are you sure you want to
                  <br /> delete?
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
              </DialogContent>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Typography variant="subtitle3" fontWeight="500" align="center">
                    This action cannot be undone.
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="dialogBtns">
                <>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="globalButtons"
                  >
                    Cancel
                  </Button>
                  <Button className="globalButtonSecond">are you sure</Button>
                </>
              </DialogActions>
            </Dialog>
          </TableContainer>
        )}
      </TableWrapper>
    </>
  );
};

MyProjects.propTypes = {
  // counts: PropTypes.number,
  recentButtons: PropTypes.string
};

export default MyProjects;
