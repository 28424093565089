import { authApi } from './AxiosInstance';

export function seoToolSearch(content) {
  return authApi.post(`/seoTools`, content);
}

export function seoToolsLists(limit, offset) {
  return authApi.get(`/seoTools?limit=${limit}&offset=${offset}`);
}

export function seoToolsDetails(id) {
  return authApi.get(`/seoTools/${id}`);
}

export function seoToolsUpdate(id, updateData) {
  return authApi.patch(`/seoTools/${id}`, updateData);
}

export function seoToolsDelete(id, isDeleted) {
  return authApi.patch(`/seoTools/${id}`, isDeleted);
}

export function seoToolsSave(id, saveData) {
  return authApi.patch(`/seoTools/${id}`, saveData);
}

export function seoToolCreateDocument(saveData) {
  return authApi.post(`/seoTools/create-document`, saveData);
}

export function seoToolResearch(searchContent) {
  return authApi.post(`/seoTools/research`, searchContent);
}

export function seoToolsLocation(getLocation) {
  let local = {
    location: getLocation
  };
  return authApi.post(`/seoTools/seo-location`, local);
}
