import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line react/prop-types
const DateFormatter = ({ date }) => {
  const location = useLocation();
  const { pathname } = location;
  const localTimeZoneOffset = moment().utcOffset();

  const formattedDate =
    pathname === '/teammates'
      ? moment.utc(date).utcOffset(localTimeZoneOffset).format('MMMM Do, h:mma')
      : moment.utc(date).utcOffset(localTimeZoneOffset).format('MM-DD-YYYY');

  const style = {
    color: 'rgb(42, 48, 59)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
  };

  return <span style={style}>{formattedDate}</span>;
};

export default DateFormatter;
